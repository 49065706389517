export default class Helpers {
    static findJerseyImageModule(team) {
        const formattedTeamName = this.formatTextForAssetNames(team);
        try {
            return require(`../assets/teams/jerseys/${formattedTeamName}-jersey.png`);
        } catch (e) {
            return require(`../assets/teams/jerseys/default-jersey-2.png`);
        }
    }

    static findLeagueImageModule(league) {
        const name = typeof league === "object" ? league.leagueName : league
        const reformattedLeagueName = this.formatTextForAssetNames(name);
        try {
            return require(`@/assets/leagues/logos/${reformattedLeagueName}-logo-colour.png`)
        } catch (e) {
            return require(`@/assets/default-badge.png`)
        }
    }
    static findTeamImageModule(teamName) {
        const reformattedTeamName = this.formatTextForAssetNames(teamName);
        try {
            return require(`@/assets/teams/badges/${reformattedTeamName}-logo-icon.png`)
        } catch (e) {
            return require(`@/assets/default-badge.png`)
        }
    }

    static formatTextForAssetNames(text) {
        const lowerCase = text.toLowerCase();
        return lowerCase.replaceAll(' ', '-');
    }
}
