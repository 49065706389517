<template>
  <div class="d-flex flex-column align-items-center mt-1" v-on:click="onUpcomingLeagueFixtureContainerClicked()">
    <div class="d-flex flex-row col-12 align-items-center pt-2 upcoming-fixtures-container">
      <div class="d-flex flex-column text-center justify-content-center"
           v-bind:class="`view-games-container-${this.upcomingFixturesCssState} primary-bg-colour primary-text primary-border-colour`">
        {{ translationsFormatter('upcoming_fixtures_view_games_title') }}
      </div>
      <div class="d-flex flex-row text-center align-items-center upcoming-fixtures-league-logo secondary-bg-colour">
        <img :src="Helpers.findLeagueImageModule(inactiveRound.leagueName)">
        <div class="text-center col-11 upcoming-fixture-league-text">
          {{ inactiveRound.leagueName.charAt(0).toUpperCase() + inactiveRound.leagueName.slice(1) }}
        </div>
      </div>
      <div class="d-flex flex-row "
           v-bind:class="`upcoming-fixtures-countdown-container-${this.upcomingFixturesCssState} primary-bg-colour primary-text primary-border-colour`">
        <img class="clock-icon" src="../../assets/clock-icon.png">
        <div class="d-flex align-items-center justify-content-end text-center fixture-time-countdown">
          {{ -inactiveRound.timeUntilRoundOpens.days }}d:{{ inactiveRound.timeUntilRoundOpens.hours }}h
        </div>
      </div>
    </div>
    <Transition name="slide">
      <div v-if="isUpcomingFixtureListExpanded" class="py-2 col-12 upcoming-fixtures-list primary-bg-colour">
        <div v-for="(match) in inactiveRound.matches" :key="match.id" class="d-flex flex-row my-2">
          <div class="d-flex flex-row justify-content-start align-items-center text-center home-team-container">
            <img :src="Helpers.findTeamImageModule(match.homeTeam.homeTeamName)">
            <div>{{ reformatTeamName(match.homeTeam.homeTeamName) }}</div>
          </div>
          <div class="d-flex flex-row justify-content-center align-items-center versus-container text-center">vs</div>
          <div class="d-flex flex-row justify-content-start align-items-center text-center away-team-container">
            <img :src="Helpers.findTeamImageModule(match.awayTeam.awayTeamName)">
            <div>{{ reformatTeamName(match.awayTeam.awayTeamName) }}</div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
  <div>

  </div>
</template>

<script>
export default {
  name: "UpcomingFixture",
  props: ['inactiveRound'],
  inheritAttrs: false,
  data() {
    return {
      upcomingFixturesCssState: 'default',
      isUpcomingFixtureListExpanded: false
    }
  },
  methods: {
    reformatTeamName(teamName) {
      let reformattedName = teamName.charAt(0).toUpperCase() + teamName.slice(1)
      let splitWords = reformattedName.split('-');
      if (splitWords[1] === undefined) {
        return splitWords[0]
      } else return splitWords[0] + ' ' + splitWords[1].charAt(0).toUpperCase() + splitWords[1].slice(1);
    },
    onUpcomingLeagueFixtureContainerClicked() {
      this.isUpcomingFixtureListExpanded = !this.isUpcomingFixtureListExpanded;
      if (this.upcomingFixturesCssState !== 'dropdown') {
        this.upcomingFixturesCssState = 'dropdown';
      } else this.upcomingFixturesCssState = 'default';
    },
  },
}
</script>

<style scoped>

.view-games-container-default {
  position: relative;
  height: 30px;
  width: 30%;
  border-radius: 20px 20px 20px 20px;
  z-index: 2;
  color: white;
  font-size: 10px;
  -webkit-transition: border-radius 1.8s;
  transition: border-radius 1.8s;
}

.view-games-container-dropdown {
  position: relative;
  height: 30px;
  width: 30%;
  border-radius: 20px 20px 20px 0px;
  z-index: 2;
  color: white;
  font-size: 10px;
}


.upcoming-fixtures-league-logo {
  position: relative;
  color: white;
  height: 30px;
  width: 100%;
  margin-left: -20px;
  border-radius: 0 15px 15px 0;
  z-index: 1;
}

.upcoming-fixtures-league-logo img {
  position: absolute;
  height: 70%;
  padding-left: 12%;
}

.upcoming-fixtures-countdown-container-default img, .upcoming-fixtures-countdown-container-dropdown img {
  margin-left: -6%;
  margin-top: 5%;
  height: 80%;
}

.upcoming-fixture-league-text {
  position: absolute;
  font-size: 10.5px;
  padding-left: 25%;
}

.upcoming-fixtures-countdown-container-default {
  height: 30px;
  width: 30%;
  margin-left: -20px;
  z-index: 0;
  border-radius: 0 15px 15px 0px;
  padding-left: 7%;
  border-style: solid;
  border-width: medium;
  -webkit-transition: border-radius 1.8s;
  transition: border-radius 1.8s;
}

.upcoming-fixtures-countdown-container-dropdown {
  height: 30px;
  width: 30%;
  margin-left: -20px;
  z-index: 0;
  border-radius: 0 15px 0px 0px;
  padding-left: 7%;
  border-style: solid;
  border-width: medium;
}

.fixture-time-countdown {
  font-size: 8px;
  padding-left: 5%;
}

.upcoming-fixtures-list {
  color: white;
  border-radius: 0 0 20px 20px;
  list-style-type: none;
  transform-origin: top;
  transition: transform .35s ease-in-out;
  overflow: hidden;
}

.slide-enter-from {
  transform: scaleY(0);
}

.slide-leave-to {
  transform: scaleY(0);
}

.home-team-container, .away-team-container {
  font-size: 10px;
  width: 40%;
  height: 25px;
}

.home-team-container {
  margin-left: 15px;
  border-radius: 20px 20px 20px 20px;
}

.home-team-container img, .away-team-container img {
  height: 20px;
  margin-left: 5%;
  margin-right: 5%;
}

.versus-container {
  height: 25px;
  width: 10%;
  border-radius: 20px 20px 20px 20px;
}

.away-team-container {
  margin-right: 15px;
  border-radius: 20px 20px 20px 20px;
}


</style>
