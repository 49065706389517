<template>
  <div class="main-container">
    <div class="fixed-container">
      <p class="header-one">iConnect Digital Services PLC Fantasy Sports Service</p>
      <p class="header-two">Terms & Conditions</p>
      <div class="below-headers">Partner Entity: iConnect Digital Services PLC PLC, 306 Nigist Towers, Kirkos Sub City,
        Addis Ababa,
        Ethiopia
      </div>
    </div>
    <div class="m-5">
      <div class="m-1">
        1 Duration & Participation
      </div>
      <div class="m-2">
        1.1 The Fantasy Sports Service (Fantasy Sports) with Final Prize (henceforth called “Prize”) is a programme of
        games of skill that require some skill and knowledge of professional sports information as well its rules. This
        is courtesy of iConnect Digital Services PLC (henceforth called the “ORGANIZER”). The Service commences from
        Monday 21st November 2022, 10:00:00 AM Ethiopian Time [GMT +3] and shall continue on an ongoing basis in 365
        days cycles periods (Prizes Duration).
      </div>
      <div class="m-2">
        1.2 The Fantasy Sports Service offers users access to the performance of real-life athletes across multiple
        sporting matches and sports games which they can play via internet access. The Service costs 2 birr/day,
        excluding mobile data fees charged by Ethio Telecom. All users will have access to the game portal, all menus
        and all categories and unlimited access to all content.
      </div>
      <div class="m-2">
        1.3 Each fantasy line-up game must include real-life athletes, so that each entry into a Fantasy Sports event
        shall be made up of players drawn from a minimum of one real-life sporting match. We strictly prohibit the
        winning of any Fantasy Sports event based solely on the single performance of an individual athlete. The Service
        will automatically prevent You from saving a line-up that is in violation of any of these restrictions.
      </div>
      <div class="m-2">
        1.4 There will be no trading of players, no extra moves and no replacing injured or benched players. Each
        Fantasy Sports event requires selecting one striker, two midfielders, one defensive unit (consisting of players
        listed as defensive players by the stats provider in a specific team) and a goalkeeper. The player pool consists
        of players that the Statistics Provider has statistics for in that particular Sports and determines to be
        eligible for the matches included in the Fantasy Sports event.
      </div>
      <div class="m-2">
        1.5 Any players left off any squad by the Statistics Provider (“missing” players) will only be added once the
        Statistics Provider recognizes and/or adds the new player and we shall not be liable in any way for such player
        not being featured in any Fantasy Sports event.
      </div>
      <div class="m-2">
        1.6 Each Fantasy Sports event specifies a date after which entries may not be added, edited, or cancelled
      </div>
      <div class="m-2">
        1.7 Throughout the course of the Programme, interested users who subscribe to the Fantasy Sports Service will
        earn points based on the total points scored across each entrant’s squad (scoring summarized below) during the
        duration of the Fantasy Sports event. If a match that is part of a Fantasy Sports event is rescheduled outside
        of the originally scheduled beginning and end of the Fantasy Sports event, players in those matches will earn
        zero points.
      </div>
      <div class="m-2">
        1.8 Users can edit their line-up at any point up to the start of a Fantasy Sports event by clicking on the edit
        option on the entry view. HOWEVER, once a Fantasy Sports event starts, your line-up will no longer be editable
      </div>
      <div class="m-2">
        1.9 An Ethio Telecom subscriber can enter into Fantasy Sports events as long as their subscription to the
        content is active.
      </div>
      <div class="m-2">
        1.10 To subscribe, an Ethio Telecom subscriber can join the Fantasy Sports Service via SMS by sending OK to 9401
        (peer to peer charge of 0.20 birr/MO applies) or via web by going through the subscription and confirmation
        steps when they discover the service banner advertisement online; this process is considered as the user’s
        request to join the service. Upon subscription and successful charge, or within the free period, the user will
        receive the following introductory messages:
        <div class="m-1">
          <div>i. Welcome SMS, legal (T&C's)</div>
          <div>ii. Credentials :-Username which is the customer’s MSISDN, password and url that allows users to play for
            3 days free period as well as charges info, points, Prizes info, etc
          </div>
        </div>
      </div>
      <div class="m-2">
        1.11 Subscribers to the Fantasy Sports Service will enjoy a 3-day FREE TRIAL period at no charge after which the
        user will be converted from the 4th day onward to the PREMIUM period and be charged a DAILY FEE. Each user can
        have the free trial period only once, meaning that if a user unsubscribes from the service after the end of the
        trial period and then registers again to the service he/she will not have an additional 3 days’ free trial
        period. Similarly, if a user unsubscribes from the service within the free trial period (for example on day 2)
        and then registers again to the service he/she will receive the content that corresponds to the remaining days
        of his/her free trial.
      </div>
      <div class="m-2">
        1.12 Participation in this PROGRAMME and the Final Prize are subject to these rules, which will be interpreted
        by iConnect Digital Services PLC at their discretion, whose decision regarding any dispute will be final and
        binding. The ORGANIZER reserves the right to amend these rules at any time during the Prize PERIOD. The
        ORGANIZER will not be required to provide any reasons for any changes and no customer will have any claim
        whatsoever against the ORGANIZER, associated companies and their advertising and PROGRAMME agencies. All
        information, rules or conditions relating to this PROGRAMME, advertising material in any media at any time
        before or during the PROGRAMME period, will form part of the terms and conditions of this Project.
      </div>
      <div class="m-2">
        1.13 The ORGANIZER shall be entitled, in their entire discretion, to reject any subscription request for any
        reason whatsoever and will not be obliged to correspond with any person about the PROGRAMME.
      </div>
    </div>
    <div class="m-5">
      <div class="m-1">
        2 Eligibility
      </div>
      <div class="m-2">
        2.1 The PROGRAMME is open to residents of Ethiopia that are subscribers of Ethio Telecom, (prepaid only), who
        are over 18 years old, excluding all employees, representatives and agents of the ORGANIZER, their respective
        parents and/or affiliated companies, and those with whom any of the foregoing are domiciled. The person that
        owns the SIM card or has express authorization from the SIM card’s owner to use it is considered the
        PARTICIPANT, regardless of the actual user of the phone.
      </div>
    </div>
    <div class="m-5">
      <div class="m-1">
        3 Programme Period & Prize
      </div>
      <div class="m-2">
        3.1 The PROGRAMME is comprised of a final period of play (Prize PERIOD), which is at the end of every 365-day
        period.
      </div>
      <div class="m-2">
        <div>3.2 At the end of the Prize PERIOD, the Final Prize will be awarded to the winner based on the following
          mechanism:
        </div>
        <div class="m-1">
          MOST/HIGHEST POINTS DRAW: An electronic draw will be held for the Final Prizes in which the participant with
          the Highest Points within the Draw Period will automatically be selected from all eligible entries. Each
          eligible PARTICIPANT will be allotted a number of entries equal to the number of points s/he has accumulated
          by the end of the PRIZE PERIOD and the participant who attains first the Most Points will be awarded the
          prize.
        </div>
        <div class="m-1">
          <div>POINTS:</div>
          <div>Points in the Fantasy Sports Service will be awarded based on the following scheme:</div>
          <table class="table table-bordered mt-2">
            <thead>
            <tr class="table-header secondary-bg-colour primary-text">
              <th scope="col">Action</th>
              <th scope="col">Points Earned</th>
            </tr>
            </thead>
            <tbody class="table-body primary-bg-colour">
            <tr>
              <th scope="row">Minutes Played</th>
              <td>Up to 60 minutes played, 2 Points</td>
            </tr>
            <tr>
              <th scope="row">Goal scored by forward</th>
              <td>4 points</td>
            </tr>
            <tr>
              <th scope="row">Goal scored by midfielder</th>
              <td>5 points</td>
            </tr>
            <tr>
              <th scope="row">Goal scored by defender</th>
              <td>6 points</td>
            </tr>
            <tr>
              <th scope="row">Assist</th>
              <td>3 points</td>
            </tr>
            <tr>
              <th scope="row">Shot on target</th>
              <td>1 point</td>
            </tr>
            <tr>
              <th scope="row">Penalty kick missed/saved</th>
              <td>-2 points</td>
            </tr>
            <tr>
              <th scope="row">Pass intercepted</th>
              <td>1 point</td>
            </tr>
            <tr>
              <th scope="row">Winning tackle</th>
              <td>1 point</td>
            </tr>
            <tr>
              <th scope="row">Clean sheet (Defenders)</th>
              <td>4 points</td>
            </tr>
            <tr>
              <th scope="row">Clean sheet (Midfielders)</th>
              <td>1 point</td>
            </tr>
            <tr>
              <th scope="row">Goal against (Defenders)</th>
              <td>-1 point</td>
            </tr>
            <tr>
              <th scope="row">Own goal</th>
              <td>-2 points</td>
            </tr>
            <tr>
              <th scope="row">Yellow card</th>
              <td>-1 point</td>
            </tr>
            <tr>
              <th scope="row">Red card</th>
              <td>-3 points</td>
            </tr>
            <tr>
              <th scope="row">Save (Goalkeeper)</th>
              <td>1 point</td>
            </tr>
            <tr>
              <th scope="row">Penalty kick save (Goalkeeper)</th>
              <td>5 points</td>
            </tr>
            <tr>
              <th scope="row">Clean sheet (Goalkeeper)</th>
              <td>4 points</td>
            </tr>
            <tr>
              <th scope="row">Goal against (Goalkeeper)</th>
              <td>-1 point</td>
            </tr>
            </tbody>
          </table>

          <div class="m-2">
            3.3 For the Final Prize PERIOD, a Prize is available, as defined in the table below:
          </div>

          <table class="table table-bordered mt-2">
            <thead>
            <tr class="table-header secondary-bg-colour primary-text">
              <th scope="col">Prize Type</th>
              <th scope="col">Units</th>
              <th scope="col">Frequency</th>
              <th scope="col">Assignment</th>
            </tr>
            </thead>
            <tbody class="table-body primary-bg-colour">
            <tr>
              <th scope="row">FANTASY SPORTS: iPhone 13 Pro phone</th>
              <td>1</td>
              <td>Final Prize</td>
              <td>Random Draw</td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
      <div class="m-2">
        3.4 Fantasy Sports Subscribers are eligible to participate in the FINAL Prize with all points they have
        accumulated within the Prize PERIOD.
      </div>
      <div class="m-2">
        3.5 The Final Prize must be accepted as awarded and may not be transferred or exchanged. The Prize will be
        delivered within 30 days from the end of the PROGRAMME PERIOD, at a time and place that will be communicated
        within 10 days after the end of the PROGRAMME PERIOD.
      </div>
      <div class="m-2">
        3.6 Any expense not explicitly indicated as part of the Prize, including but not limited to insurance, travel
        expenses, accommodation, license fees or taxes, are the responsibility of the winner.
      </div>
      <div class="m-2">
        3.7 The ORGANIZER reserves the right to withhold the Prize until satisfied that the claim is valid.
      </div>
      <div class="m-2">
        3.8 The ORGANIZER reserves the right to terminate the PROGRAMME at any time without prior notice.
      </div>
    </div>
    <div class="m-5">
      <div class="m-1">
        4 Game & Points
      </div>
      <div class="m-2">
        4.1 The Web Subscription and confirmation actions by a customer to the Fantasy Sports Service, subscribes the
        customer to the service. In response to the SUBSCRIPTION, the user will receive one welcome SMS and the CONTENT
        SMS of the day with the URL and credentials which leads to the Fantasy Sports portal after the customer is
        successfully charged or if the user is within the free period. Users are not charged when they receive CONTENT
        SMS with the URL leading to the Game Portal during the FREE TRIAL period.
      </div>
      <div class="m-2">
        4.2 While users are in the PREMIUM Period, the ORGANIZER will attempt to charge users and deliver the content
        SMS with the URL leading to the Games Portal after the PARTICIPANT has been charged the DAILY FEE of ETB 2/day
        on the 1st day of subscription and upon every 7 successful charges.
      </div>
      <div class="m-2">
        4.3 The Fantasy Sports Service continues beyond the duration of this Prize programme. It will continue as an
        ongoing service in 365 days cycles
      </div>
    </div>
    <div class="m-5">
      <div class="m-1">
        5 Cost & Subscription
      </div>
      <div class="m-2">
        5.1 Every successful charge while the user is in the PREMIUM PERIOD, is charged at ETB 2/DAY inclusive of all
        taxes, while Service is FREE in the FREE TRIAL period. However, customers are charged 0.2 ETB for every SMS sent
        to the service shortcode 9401. This is the peer to peer tariff charged by Ethio Telecom on all MOs sent to short
        codes.
      </div>
      <div class="m-2">
        5.2 In case a Subscriber is in the PREMIUM PERIOD and does not have sufficient credit to be charged at least
        once within the programme period, he/she will NOT be eligible to join the Prize draw
      </div>
      <div class="m-2">
        5.3 PARTICIPANTS in both the FREE and PREMIUM Periods can terminate their subscription by sending “STOP” to the
        PROGRAMME’s short code 9401, and it is charged at 0.20 ETB for the MO.
      </div>
    </div>
    <div class="m-5">
      <div class="m-1">
        6 Additional capabilities
      </div>
      <div class="m-2">
        6.1 All PARTICIPANTS can get more information about the PROGRAMME by sending “INFO” to the PROGRAMME’s short
        code 9401 at the charge specified in 5.1.
      </div>
      <div class="m-2">
        6.2 All PARTICIPANTS can get to know their points in the Language lessons by sending “POINTS” to the PROGRAMME’s
        short code 9401 at the charge specified in 5.1.
      </div>
    </div>
    <div class="m-5">
      <div class="m-1">
        7 Winner selection, notification and Prize forfeiture
      </div>
      <div class="m-2">
        7.1 At the end of the 365-day period, a winner will be selected (as specified in section 3).
      </div>
      <div class="m-2">
        7.2 For the Final Prize, one winner and 10 runners-up will be selected via Most Points Draw.
      </div>
      <div class="m-2">
        7.3 The winner will be contacted by the ORGANIZER on the mobile phone number he/she has used to participate in
        the PROGRAMME. Three (3) consecutive voice call attempts will be made by the ORGANIZER to contact the mobile
        number of the winner. If the winner cannot be contacted during this period, he/she is disqualified and forfeits
        the Prize and the ORGANIZER will attempt to contact the next runner up.
      </div>
      <div class="m-2">
        7.4 The winner is required to provide proof of eligibility and sign a Prize acceptance form (as requested by the
        ORGANIZER) before Prizes can be collected. If the winner fails to provide sufficient proof within fifteen (15)
        business days, he/she is disqualified and forfeits the Prize and the next runner up is contacted.
      </div>
      <div class="m-2">
        7.5 If the winner has any outstanding debts towards the ORGANIZER, these need to be settled within three (3)
        days of being contacted by the ORGANIZER. If outstanding debts are not settled within that period, the winner
        forfeits the Prize.
      </div>
      <div class="m-2">
        7.6 If the winner and all runners-up forfeit the Prize, the Prize is deemed unclaimed and remains at the
        disposal of the ORGANIZER.
      </div>
      <div class="m-2">
        7.7 By accepting a Prize, a winner consents to the use of his/her first name, initial of last name, city of
        residence, photograph and likeness in any advertising material produced by the ORGANIZER. Such use does not
        entitle the winner to any further compensation.
      </div>
      <div class="m-2">
        7.8 The Prize Winner may be required to be available for media interviews.
      </div>
    </div>
    <div class="m-5">
      <div class="m-1">
        8 Abuse and disqualification
      </div>
      <div class="m-2">
        8.1 The ORGANIZER may refuse to award any prize if any irregularities or fraudulent activities are detected.
      </div>
    </div>
    <div class="m-5">
      <div class="m-1">
        9 Organizer’s responsibilities
      </div>
      <div class="m-2">
        9.1 The ORGANIZER’s responsibility is strictly limited to giving the loyalty Prize. The ORGANIZER cannot be held
        responsible for any delay or impossibility of sending, receiving and/or billing SMS messages because of a
        technical problem. All the SMS messages will be considered received by the ORGANIZER when they are registered in
        the PROGRAMME’s IT system and not when they are sent by the PARTICIPANT. All the SMS messages will be considered
        received by the PARTICIPANT at the moment they were sent from the PROGRAMME’s IT system. PARTICIPANTS recognize
        and accept that the journal of the informatics system of the PROGRAMME’s IT System is the proof of the date,
        hour, minute and second of their receiving/sending each SMS message.
      </div>
    </div>
    <div class="m-5">
      <div class="m-1">
        10 Personal Data Protection
      </div>
      <div class="m-2">
        10.1 By participating in the PROGRAMME, the PARTICIPANTS expressly agree that personal data which they supply
        shall be processed by the ORGANIZER for the purposes of executing the PROGRAMME and also for the purpose of
        publicizing the PROGRAMME and the ORGANIZER. It is hereby specified that the ORGANIZER guarantees that
        publicity, entailing the sharing with third parties of personal data supplied as a result of participation in
        the PROGRAMME, (including through television, the press, leaflets and other means and formats of communication)
        shall be made only with respect to the winner of the Prize and will only indicate the given names and surname of
        the winner, their location or place of residence, image and voice (contained in photographic records, audio,
        video and other types of records, made with the purposes of publicity connected to the PROGRAMME). The winning
        PARTICIPANT gives, through their participation in the PROGRAMME, express consent for the use of this data in
        formats herein defined. Following the termination of the PROGRAMME, the personal data will be destroyed.
      </div>
      <div class="m-2">
        10.2 In accordance with local laws and regulations, the ORGANIZER respects the privacy of PARTICIPANTS of this
        PROGRAMME, by which it is committed to the use of technology in a transparent form and in strict observance of
        the rights, freedom and guarantees of the citizens and by the reservation of the intimacy of their private and
        family life. The personal data collected within the scope of this PROGRAMME will be treated as private and
        confidential. It shall not be disclosed to other individuals or organizations, except in the event of
        legislation to the contrary or where such is authorized by the PARTICIPANTS themselves. The data will be stored
        on servers with access controlled on a need-to-know basis and limited by passwords.
      </div>
      <div class="m-2">
        10.3 All rights, title and interest in and to the Service including, but not limited to, any games, titles,
        computer code, themes, objects, stories, dialogue, concepts, artwork, animations, sounds, musical compositions,
        audio-visual effects, methods of operation, moral rights, documentation are owned by the Organizer and its
        Partners. We reserve all rights, including, but not limited to, all intellectual property rights or other
        proprietary rights, in connection with the Service. The Organizer and its Partner, has licensed, or otherwise
        has rights to use, or provide all of the content that appears in the Service.
      </div>
    </div>
    <div class="m-5">
      <div class="m-1">
        11 Governing Law & Amendments
      </div>
      <div class="m-2">
        11.1 This PROGRAMME is organized under and governed by the laws and statutes of the Federal Democratic Republic
        of Ethiopia.
      </div>
      <div class="m-2">
        11.2 These Terms & Conditions are valid during the PROGRAMME PERIOD until amended or suspended by the ORGANIZER.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EthiotelEnglishTerms",
}
</script>

<style scoped>
.fixed-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #9d9d9d;
  width: 100%;
}

.header-one {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.header-two {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.below-headers {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: small;
  width: 80%;
}
</style>
