<template>
  <div v-if="mustRoundDisplay(activeRound)" class="d-flex flex-column align-items-center pb-1">
    <div
        :class="`${activeRound.isUpcomingRoundListExpanded && !activeRound.hasFullTeam ? 'enter-round-container-expanded primary-bg-colour' : 'enter-round-container secondary-bg-colour'}`">
      <div class="d-flex flex-row justify-content-evenly" @click="() => onRoundClicked(activeRound)"
           :class="`kickoff-time-container ${activeRound.isUpcomingRoundListExpanded && !activeRound.hasFullTeam  ? 'primary-bg-colour' : 'secondary-bg-colour'}`">
        <div class="whistle-icon-div"><img src="../../assets/whistle-icon.png"></div>
        <div class="kickoff-time-text">
          {{ -activeRound.timeUntilRoundClose.days }}d:{{ activeRound.timeUntilRoundClose.hours }}h
        </div>
      </div>
      <div v-if="!activeRound.isUpcomingRoundListExpanded && !activeRound.hasFullTeam "
           class="d-flex flex-column justify-content-center align-items-center pb-1 win-data-container primary-bg-colour"
           @click="() => onRoundClicked(activeRound)">
        <img class="win-data-img" :src="require(`../../brand/${this.brandConfig.brand}/assets/win-data-icon.png`)">
      </div>
      <div v-if="activeRound.hasFullTeam"
           class="d-flex flex-column justify-content-center align-items-center pb-1 win-data-container primary-bg-colour"
           @click="() => onRoundClicked(activeRound)">
        <img class="win-data-img" :src="require(`../../brand/${this.brandConfig.brand}/assets/win-data-icon.png`)">
      </div>
      <div
          :class="`league-title-icon-container ${activeRound.isUpcomingRoundListExpanded && !activeRound.hasFullTeam ? 'justify-content-center primary-bg-colour' : 'justify-content-start secondary-bg-colour'}`"
          @click="() => onRoundClicked(activeRound)">
        <img class="league-title-icon" :src="Helpers.findLeagueImageModule(activeRound)">
        <div class="league-round-text">{{ activeRound.leagueName }}</div>
      </div>
      <div v-if="!activeRound.hasEntries && !activeRound.isUpcomingRoundListExpanded && !activeRound.hasFullTeam"
           class="play-button col-2 pl-1"
           @click="() => {this.playButtonClicked(activeRound)}">
        {{ translationsFormatter('upcoming_rounds_play_button') }}
      </div>
    </div>
    <Transition name="slide">
      <div class="pb-2 matchList col-12 primary-bg-colour"
           v-if="activeRound.isUpcomingRoundListExpanded && !activeRound.hasFullTeam">
        <div v-if="activeRound.isUpcomingRoundListExpanded" class="d-flex flex-column text-center pb-1"
             v-for="(match) in activeRound.matches" :key="match.id">
          <div class="d-flex flex-row justify-content-around pt-1 pb-1">
            <div
                class="d-flex flex-row justify-content-start align-items-center text-center home-team-container secondary-bg-colour">
              <img :src="Helpers.findTeamImageModule(match.homeTeam.homeTeamName)">
              <div>{{ match.homeTeam.homeTeamName }}</div>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center text-center versus-container">
              vs
            </div>
            <div
                class="d-flex flex-row justify-content-start align-items-center text-center secondary-bg-colour away-team-container">
              <img :src="Helpers.findTeamImageModule(match.awayTeam.awayTeamName)">
              <div>{{ match.awayTeam.awayTeamName }}</div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column align-items-center dropdown-play-button my-2"
             v-if="!activeRound.hasEntries && activeRound.isUpcomingRoundListExpanded">
          <button v-on:click="() => playButtonClicked(activeRound)">
            {{ translationsFormatter('active_round_play_button_label') }}
          </button>
        </div>
      </div>
    </Transition>
    <div class="d-flex flex-row justify-content-center align-items-center col-10 field-container"
         v-if="activeRound.hasEntries && !activeRound.hasFullTeam">
      <img v-if="this.hasPlayerEntered('forward')" class="entered-play-icon" src="../../assets/player-icon.png">
      <img v-if="!this.hasPlayerEntered('forward')" class="plus-icon" src="../../assets/plus-icon.png"
           @click="() => playerPlusIconClicked('forward')">
      <img v-if="this.hasPlayerEntered('midfielder1')" class="entered-play-icon" src="../../assets/player-icon.png">
      <img v-if="!this.hasPlayerEntered('midfielder1')" class="plus-icon" src="../../assets/plus-icon.png"
           @click="() => playerPlusIconClicked('Midfielder')">
      <img v-if="this.hasPlayerEntered('midfielder2')" class="entered-play-icon" src="../../assets/player-icon.png">
      <img v-if="!this.hasPlayerEntered('midfielder2')" class="plus-icon" src="../../assets/plus-icon.png"
           @click="() => playerPlusIconClicked('Midfielder')">
      <img v-if="this.hasPlayerEntered('defence')" class="entered-play-icon" src="../../assets/player-icon.png">
      <img v-if="!this.hasPlayerEntered('defence')" class="plus-icon" src="../../assets/plus-icon.png"
           @click="() => playerPlusIconClicked('Defence')">
      <img v-if="this.hasPlayerEntered('goalkeeper')" class="entered-play-icon" src="../../assets/player-icon.png">
      <img v-if="!this.hasPlayerEntered('goalkeeper')" class="plus-icon" src="../../assets/plus-icon.png"
           @click="() => playerPlusIconClicked('Goalkeeper')">
    </div>
    <div class="d-flex flex-column align-items-center col-11 dropdown-play-button"
         v-if="activeRound.hasEntries && !activeRound.hasFullTeam">
      <button v-on:click="() => playButtonClicked(activeRound)">
        {{ translationsFormatter('active_round_play_button_label') }}
      </button>
    </div>
    <div class="d-flex flex-row justify-content-center align-items-center col-10 field-container-finished-team"
         v-if="activeRound.hasFullTeam">
      <img class="entered-play-icon" src="../../assets/player-icon.png">
      <img class="entered-play-icon" src="../../assets/player-icon.png">
      <img class="entered-play-icon" src="../../assets/player-icon.png">
      <img class="entered-play-icon" src="../../assets/player-icon.png">
      <img class="entered-play-icon" src="../../assets/player-icon.png">
    </div>
    <div class="dropdown-edit-team-button" v-if="activeRound.hasFullTeam"
         v-on:click="() => {this.playButtonClicked(activeRound)}">
      {{ translationsFormatter('active_round_edit_team_button_label') }}
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {setSelectedRound} from "@/functions/roundSelection";

export default {
  name: "ActiveRound",
  inheritAttrs: false,
  props: ['activeRound'],
  data() {
    return {
      incompleteTeam: false,
      completedTeam: false,
      showPlayButton: true,
      selectedRound: this.activeRound,
      activeRoundImgLeftMargin: undefined,
      activeRoundImgTopMargin: undefined,
    }
  },
  beforeMount() {
    this.setBrandImgMargin();
    this.hidePlayButton()
  },
  computed: {
    ...mapState(['brandConfig', 'roundSelected'])
  },
  methods: {
    ...mapMutations(['setSelectedRound', 'setActiveRoundsExpandedOff', 'setSelectedPositionInRound']),
    setBrandImgMargin() {
      if (this.brandConfig.brand === 'vc-za-en') {
        this.activeRoundImgLeftMargin = '7px';
        this.activeRoundImgTopMargin = '-2px';
      } else {
        this.activeRoundImgLeftMargin = '1px';
        this.activeRoundImgTopMargin = '5px';
      }
    },
    mustRoundDisplay(round) {
      if (this.$route.path === '/my-rounds') {
        return !!round.hasFullTeam;
      } else {
        return !round.hasFullTeam;
      }
    },
    playerPlusIconClicked(position) {
      this.setSelectedPositionInRound(position)
      this.$router.push('/team-selector');
    },
    hasPlayerEntered(position) {
      return this.activeRound.selections.hasOwnProperty(position)
    },
    hidePlayButton() {
      if (this.incompleteTeam === true || this.completedTeam === true) {
        this.showPlayButton = false
      }
    },
    playButtonClicked(selectedRound) {
      setSelectedRound(selectedRound)
      this.setSelectedRound(selectedRound);
      this.$router.push(`/team-builder`);
    },
    onRoundClicked(activeRound) {
      if (!activeRound.hasFullTeam) {
        if (!activeRound.isUpcomingRoundListExpanded) {
          this.setActiveRoundsExpandedOff();
          activeRound.isUpcomingRoundListExpanded = !activeRound.isUpcomingRoundListExpanded;
        } else this.setActiveRoundsExpandedOff();
      }
    },
  },
  watch: {
    $route(to, from) {
      if (this.$route.path === '/my-rounds') {
        this.routePath = '/my-rounds'
      }
    }
  }
}
</script>

<style scoped>

.kickoff-time-container {
  position: absolute;
  color: white;
  z-index: 2;
  font-size: 10px;
  width: 65px;
  border-radius: 10px 10px 0 0;
  top: -14px;
  height: 15px;
}

.kickoff-time-text {
  padding-top: 3%;
  margin-left: -10%;
  font-size: 8px;
}

.whistle-icon-div img {
  width: 16px;
}

.enter-round-container {
  display: flex;
  width: 100%;
  position: relative;
  border-radius: 15px 15px 15px 15px;
  margin-top: 15px;
  -webkit-transition: background .5s, border-radius 1s;
  transition: background .5s, border-radius 1s;
}

.enter-round-container-expanded {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  border-radius: 15px 15px 0px 0px;
  margin-top: 15px;
}


.field-container {
  background-image: url("../../assets/grey-field.png");
  background-size: cover;
  background-position: center;
  height: 60px;
  border-radius: 0 0 15px 15px;
}

.field-container-finished-team {
  background-image: url("../../assets/field-small.png");
  background-size: cover;
  background-position: center;
  height: 60px;
  border-radius: 0 0 15px 15px;
}

.entered-play-icon {
  width: 40px;
  margin: 2%;
}

@media screen and (max-width: 300px) {
  .entered-play-icon {
    width: 30px;
  }
}

.plus-icon {
  width: 40px;
  margin: 2%;
  padding-bottom: 7px;
}

.kickoff-time-container {
  position: absolute;
  color: white;
  z-index: 2;
  font-size: 10px;
  width: 65px;
  border-radius: 10px 10px 0 0;
  top: -14px;
  left: 40%;
  height: 15px;
}

.kickoff-time-text {
  padding-top: 3%;
  margin-left: -10%;
  font-size: 8px;
}

.whistle-icon-div img {
  width: 16px;
}

.win-data-container {
  height: 60px;
  border-radius: 15px;
  z-index: 2;
  top: 0;
}

.win-data-img {
  margin-left: v-bind('activeRoundImgLeftMargin');
  margin-top: v-bind('activeRoundImgTopMargin');
  width: 80px;
}

.league-title-icon-container {
  display: flex;
  align-items: center;
  width: 80%;
  position: relative;
  color: white;
  height: 60px;
  border-radius: 0 15px 15px 0;
  z-index: 1;
  padding-left: 10px
}

.league-title-icon {
  height: 70%;
}

.league-round-text {
  padding-left: 10px;
  font-size: 12.5px;
  line-height: 1.2;
}

.play-button {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #019601;
  color: white;;
  writing-mode: vertical-lr;
  margin-left: -20px;
  z-index: 0;
  border-radius: 15px 0 0 15px;
  -webkit-transform: rotate(-180deg);
  padding-right: 10px;
}

.upcoming-fixtures-league-logo img {
  position: absolute;
  height: 70%;
  padding-left: 12%;
}

.upcoming-fixtures-countdown-container img {
  margin-left: 5%;
  margin-top: 5%;
  height: 80%;
}

.matchList {
  color: white;
  border-radius: 0 0 15px 15px;
  list-style-type: none;
  transform-origin: top;
  transition: transform .35s ease-in-out;
  overflow: hidden;
}

.slide-enter-from {
  transform: scaleY(0);
}

.slide-leave-to {
  transform: scaleY(0);
}

.home-team-container, .away-team-container {
  font-size: 10px;
  width: 40%;
}

.home-team-container {
  z-index: 0;
  margin-left: 15px;
  border-radius: 20px 20px 20px 20px;
}

.home-team-container img {
  height: 20px;
  width: 20px;
  margin-left: 5%;
  margin-right: 5%;
}

.versus-container {
  height: 25px;
  width: 10%;
  border-radius: 20px 20px 20px 20px;
}

.away-team-container {
  margin-right: 15px;
  border-radius: 20px 20px 20px 20px;
}

.away-team-container img {
  height: 20px;
  width: 20px;
  margin-left: 5%;
  margin-right: 5%;
}

.dropdown-play-button button {
  color: white;
  background-color: #019601;
  height: 30px;
  width: 260px;
  border: 1px solid #01a601;
  border-radius: 8px;
}

.dropdown-edit-team-button {
  width: 70%;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  background-color: #019601;
  height: 30px;
  border: 1px solid #01a601;
}

</style>
