export const styles = {
    backgrounds: [
        { className: "primary-bg-colour", colour: "#ADC32A" },
        { className: "secondary-bg-colour", colour: "#2D343B" },
        { className: "navbar-bg-colour", colour: "#2D343B" }
    ],
    text: [
        { className: "primary-text", colour: "#FFFFFF" },
        { className: "secondary-text", colour: "#ADC32A" }
    ],
    borders: [
        { className: "primary-border-colour", colour: "#ADC32A" },
    ]
};
