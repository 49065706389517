import store from "../store/index";

export function translationsFormatter(string, placeholders) {
    const brand = store.state.brandConfig.brand;
    const brandConfigTranslations = require(`../brand/${brand}/translations/index.js`);

    return fillPlaceHolders(brandConfigTranslations.translations, string, placeholders);
}

function fillPlaceHolders(translations, string, placeholders) {
    let formattedString = translations[string];
    if (placeholders) placeholders.forEach((placeholder, index) => formattedString = formattedString.replaceAll(`{${index}}`, `${placeholder}`))
    return formattedString;
}
