import store from "@/store";

export default function (msisdn) {
    let noPlus;
    let noZero;
    switch (store.state.brandConfig.brand) {
        case "vc-za-en":
            noPlus = msisdn.replace(/[+]/g, "")
            noZero = noPlus.replace(/^[0]/, "27")
            return {
                msisdn: noZero.replace(/\s/g, ''),
                length: 11
            }
        case "mtn-ci-fr" :
            noPlus = msisdn.replace(/[+]/g, "");
            noZero = noPlus.replace(/^[0]/, "225");
            noZero.replace(/\s/g, '');
            const formattedMsisdn = [noZero.slice(0, 3), "0", noZero.slice(3)].join('');
            return {
                msisdn: formattedMsisdn,
                length: 13
            }
        case "mtn-cmr-fr" :
            noPlus = msisdn.replace(/[+]/g, "");
            noZero = noPlus.replace(/^[0]/, "237");
            return {
                msisdn: noZero.replace(/\s/g, ''),
                length: 12
            }
        case "mtn-cg-fr" :
            noPlus = msisdn.replace(/[+]/g, "");
            noZero = noPlus.replace(/^[0]/, "243");
            return {
                msisdn: noZero.replace(/\s/g, ''),
                length: 12
            }
        case "mtn-nga-en" :
            noPlus = msisdn.replace(/[+]/g, "");
            noZero = noPlus.replace(/^[0]/, "234");
            return {
                msisdn: noZero.replace(/\s/g, ''),
                length: 13
            }
        case "ethiotel-et-en" :
            noPlus = msisdn.replace(/[+]/g, "");
            noZero = noPlus.replace(/^[0]/, "251");
            return {
                msisdn: noZero.replace(/\s/g, ''),
                length: 12
            }
        case "ethiotel-et-amh" :
            noPlus = msisdn.replace(/[+]/g, "");
            noZero = noPlus.replace(/^[0]/, "251");
            return {
                msisdn: noZero.replace(/\s/g, ''),
                length: 12
            }
        case "mtn-zam-en" :
            noPlus = msisdn.replace(/[+]/g, "");
            noZero = noPlus.replace(/^[0]/, "260");
            return {
                msisdn: noZero.replace(/\s/g, ''),
                length: 12
            }
        case "air-nga-en" :
            noPlus = msisdn.replace(/[+]/g, "");
            noZero = noPlus.replace(/^[0]/, "234");
            return {
                msisdn: noZero.replace(/\s/g, ''),
                length: 13
            }
    }
}
