<template>
  <div class="main-container">
    <div class="d-flex flex-column col-12">
      <div class="m-2">
        <div class="m-1">
          Q: What is the “FANTASY SPORTS” service?
        </div>
        <div class="m-1">
          A: “Fantasy Sports” is a fun game portal that allows you to put your knowledge of football and
          football players to excellent use. Start by creating your dream team each day featuring your
          choice of football players from across multiple leagues. Then, based on the players&#39;
          performance in real life, your fantasy team earns points. The more points you earn, the higher
          on the leaderboard you are. At the end of the service period, you enter a draw for the final
          prize.
        </div>
      </div>
      <div class="m-2">
        <div class="m-1">
          Q: Who is the “FANTASY SPORTS” service for?
        </div>
        <div class="m-1">
          A: “FANTASY SPORTS” is available to all mobile customers of Ethio Telecom.
        </div>
      </div>
      <div class="m-2">
        <div class="m-1">
          Q: How can I participate in the “FANTASY SPORTS” service?
        </div>
        <div class="m-1">
          A: There are three ways to subscribe to “FANTASY SPORTS”. You can simply click on the mobile
          advertising banner for the service when you see it online; or you can subscribe directly through
          this portal by clicking on the “Subscribe” button on the homepage. Lastly, you can subscribe by
          sending OK to 9401.
        </div>
      </div>
      <div class="m-2">
        <div class="m-1">
          Q: How much does the service “FANTASY SPORTS” cost?
        </div>
        <div class="m-1">
          A: The daily subscription to the “FANTASY SPORTS” service costs 2 birr/day, automatically
          renewable.
          Welcome offer: 3 free days for each new subscriber to the service.
        </div>
      </div>
      <div class="m-2">
        <div class="m-1">
          Q: How many points can I earn?
        </div>
        <div class="m-1 col-12">
          A: FANTASY SPORTS (Portal/App)
          <table class="table table-bordered mt-2">
            <thead>
            <tr class="table-header secondary-bg-colour primary-text">
              <th scope="col">Action</th>
              <th scope="col">Points Earned</th>
            </tr>
            </thead>
            <tbody class="table-body primary-bg-colour">
            <tr>
              <th scope="row">Points upon charge</th>
              <td>10 Points upon every successful charge for the billing tariff</td>
            </tr>
            </tbody>
          </table>

          <table class="table table-bordered mt-2">
            <thead>
            <tr class="table-header secondary-bg-colour primary-text">
              <th scope="col">Action</th>
              <th scope="col">Points Earned</th>
            </tr>
            </thead>
            <tbody class="table-body primary-bg-colour">
            <tr>
              <th scope="row">Minutes Played</th>
              <td>Up to 60 minutes played, 2 Points</td>
            </tr>
            <tr>
              <th scope="row">Goal scored by forward</th>
              <td>4 points</td>
            </tr>
            <tr>
              <th scope="row">Goal scored by midfielder</th>
              <td>5 points</td>
            </tr>
            <tr>
              <th scope="row">Goal scored by defender</th>
              <td>6 points</td>
            </tr>
            <tr>
              <th scope="row">Assist</th>
              <td>3 points</td>
            </tr>
            <tr>
              <th scope="row">Shot on target</th>
              <td>1 point</td>
            </tr>
            <tr>
              <th scope="row">Penalty kick missed/saved</th>
              <td>-2 points</td>
            </tr>
            <tr>
              <th scope="row">Pass intercepted</th>
              <td>1 point</td>
            </tr>
            <tr>
              <th scope="row">Winning tackle</th>
              <td>1 point</td>
            </tr>
            <tr>
              <th scope="row">Clean sheet (Defenders)</th>
              <td>4 points</td>
            </tr>
            <tr>
              <th scope="row">Clean sheet (Midfielders)</th>
              <td>1 point</td>
            </tr>
            <tr>
              <th scope="row">Goal against (Defenders)</th>
              <td>-1 point</td>
            </tr>
            <tr>
              <th scope="row">Own goal</th>
              <td>-2 points</td>
            </tr>
            <tr>
              <th scope="row">Yellow card</th>
              <td>-1 point</td>
            </tr>
            <tr>
              <th scope="row">Red card</th>
              <td>-3 points</td>
            </tr>
            <tr>
              <th scope="row">Save (Goalkeeper)</th>
              <td>1 point</td>
            </tr>
            <tr>
              <th scope="row">Penalty kick save (Goalkeeper)</th>
              <td>5 points</td>
            </tr>
            <tr>
              <th scope="row">Clean sheet (Goalkeeper)</th>
              <td>4 points</td>
            </tr>
            <tr>
              <th scope="row">Goal against (Goalkeeper)</th>
              <td>-1 point</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="m-2">
        <div class="m-1">
          Q: What are the prizes?
        </div>
        <div class="m-1 col-12">
          A: Premium Service
          <table class="table table-bordered mt-2">
            <thead>
            <tr class="table-header secondary-bg-colour primary-text">
              <th scope="col">Prize Type</th>
              <th scope="col">Units</th>
              <th scope="col">Frequency</th>
              <th scope="col">Assignment</th>
            </tr>
            </thead>
            <tbody class="table-body primary-bg-colour">
            <tr>
              <th scope="row">FANTASY SPORTS: iPhone 13 Pro phone</th>
              <td>1</td>
              <td>Final Prize</td>
              <td>Random Draw</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="m-2">
        <div class="m-1">
          Q: Can I edit my Fantasy Team after I have selected the players?
        </div>
        <div class="m-1">
          A: Yes. You can edit your line-up at any point up to the start of a Fantasy Sports event.
          Remember, once an event begins, you cannot edit your line-up anymore.
        </div>
      </div>
      <div class="m-2">
        <div class="m-1">
          Q: Do I need to activate my data connection to access the “FANTASY SPORTS” portal?
        </div>
        <div class="m-1">
          A: Yes, to access the game portal “FANTASY SPORTS”, you must activate your data connection
          or have access to a Wi-Fi connection.
        </div>
      </div>
      <div class="m-2">
        <div class="m-1">
          Q: How can I get my credentials back to access the “FANTASY SPORTS” portal?
        </div>
        <div class="m-1">
          A: Every time you enter your phone number to log in, you will receive an SMS with your
          password.
        </div>
      </div>
      <div class="m-2">
        <div class="m-1">
          Q: How can I play in the portal?
        </div>
        <div class="m-1">
          A: You will receive an SMS with the link to the portal. Tap on the link and you can start playing.
        </div>
      </div>
      <div class="m-2">
        <div class="m-1">
          Q: How can I unsubscribe from the “FANTASY SPORTS” service?
        </div>
        <div class="m-1">
          A: To unsubscribe from the “FANTASY SPORTS” service, send the word “STOP” to the
          shortcode 9401.
        </div>
      </div>
      <div class="m-2">
        <div class="m-1">
          Q: What if I need further information with regards to the campaign?
        </div>
        <div class="m-1">
          A: For any further information you may need, you can visit http://sp-tcs.upp.st/.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: "EthiotelEnglishFAQs",
  computed: {
    ...mapState(['brandConfig']),
  },
}
</script>

<style scoped>
.main-container {
  background-color: #e8e8e8;
  height: 100%;
}

table {
  font-size: 10px;
}
</style>
