<template>
  <div class="container-fluid">
    <div class="d-flex flex-row align-items-center pb-2">
      <img class="pt-3 col-10 header-navbar-logo"
           :src="require(`../../brand/${brandConfig.brand}/assets/main-fantasy-header.png`)">
      <div v-if="this.brandConfig.brand === 'ethiotel-et-amh' || this.brandConfig.brand === 'ethiotel-et-en'"
           class="d-flex justify-content-center align-items-center border-1 primary-bg-colour language">
        <span @click="onLanguageClicked" class="secondary-text"
              v-if="this.brandConfig.brand === 'ethiotel-et-amh' || this.brandConfig.brand === 'ethiotel-et-en'">{{
            getLanguage()
          }}</span>
      </div>
    </div>
    <div class="d-flex flex-column text-center justify-content-center primary-bg-colour primary-text header-container">
      {{ translationsFormatter('login_header') }}
    </div>
    <div
        class="d-flex flex-column text-center justify-content-center align-items-center secondary-bg-colour primary-text main-container">
      <img class="fantasy-logo" :src="require(`../../brand/${brandConfig.brand}/assets/fantasy-logo-icon.png`)">
      <div class="pb-1 ">{{ translationsFormatter('login_main_fantas') }}</div>
    </div>
    <div class="pt-2 background-behind">
      <br>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center text-center msisdn-input-container">
      <div class="col-10 pt-4 login-info-text">{{ translationsFormatter('login_info') }}</div>
      <div class="col-10 pt-4 pb-4 login-info-text">{{ translationsFormatter('login_info_text_second') }}</div>
      <input v-on:keyup.enter="startPlayingButtonClicked" class="col-10 text-center msisdn-input-box" type="tel"
             v-model="userMsisdn" :placeholder="translationsFormatter('login_input_placeholder')"
             @change="validateMsisdnInput($event)">
      <div v-if="!isMsisdnInputValid" class="d-flex justify-content-center fw-bold text-red my-1">
        {{ translationsFormatter('login_info_incorrect_msisdn') }}
      </div>
      <div v-if="!isMsisdnLengthCorrect" class="d-flex justify-content-center fw-bold text-red my-1">
        {{ translationsFormatter('login_info_incorrect_msisdn_length') }}
      </div>
      <button @click="startPlayingButtonClicked"
              class="col-10 mt-3 mb-3 text-center primary-bg-colour primary-text login-button">
        {{ translationsFormatter('login_button') }}
      </button>

      <div v-if="brandConfig.brand !== 'ethiotel-et-en' || brandConfig.brand !== 'ethiotel-et-amh'"
           class="col-10 pt-3 pb-4 login-info-text">{{
          translationsFormatter('login_below_button_text')
        }}
      </div>
      <div v-if="brandConfig.brand !== 'ethiotel-et-en' || brandConfig.brand !== 'ethiotel-et-amh'"
           class="col-10 pt-2 pb-5 login-info-text">{{
          translationsFormatter('login_terms_conditions_text_one')
        }} <u><b
            class="terms-conditions"
            @click="onTnCsClicked">{{ translationsFormatter('login_terms_conditions_text_two') }}</b></u>
        {{ translationsFormatter('login_terms_conditions_text_three') }}
      </div>


      <div v-if="brandConfig.brand === 'ethiotel-et-en'" class="d-flex flex-column col-10 login-info-text">
        <div class="d-flex flex-row justify-content-around">
          <div>{{ translationsFormatter('login_price_data') }}</div>
          <div>{{ translationsFormatter('login_trial_period') }}</div>
        </div>
        <div class="mt-3">{{ translationsFormatter('login_below_button') }}</div>
        <div class="mt-3">
          {{ translationsFormatter('login_subscribe_link_one') }}
          <a href="https://ethiofantasy.com/">https://ethiofantasy.com</a>
          {{ translationsFormatter('login_subscribe_link_two') }}
          {{ translationsFormatter('login_unsubscribe_link') }}
        </div>
        <div class="mt-3">For additional help, please call 0907673220.</div>
        <div class="d-flex flex-row justify-content-around terms-conditions-faq-text mt-3">
          <div @click="onTnCsClicked" class="underline-text">{{ translationsFormatter('login_terms_conditions') }}</div>
          <div @click="onFAQsClicked" class="underline-text">{{ translationsFormatter('login_faq') }}</div>
        </div>
      </div>


      <div class="d-flex flex-column  col-10 text-center login-info-text"
           v-if="brandConfig.brand === 'ethiotel-et-amh'">
        <div class="d-flex flex-row justify-content-around">
          <div>{{ translationsFormatter('login_below_button') }}</div>
          <div>{{ translationsFormatter('login_below_button_2') }}</div>
        </div>
        <div class="mt-3">{{ translationsFormatter('login_below_button_3') }}</div>

        <div class="mt-3">
          {{ translationsFormatter('login_below_button_4.1') }}
          <a href="https://ethiofantasy.com/">https://ethiofantasy.com</a>
          {{ translationsFormatter('login_below_button_4.2') }}
        </div>
        <div class="mt-3"> ለበለጠ መረጃ ወደ 0907673220 ይደውሉ።</div>
        <div class="mt-3">{{ translationsFormatter('login_below_button_5') }}</div>
        <div class="d-flex flex-row justify-content-around terms-conditions-faq-text mt-3">
          <div @click="onTnCsClicked" class="underline-text">{{ translationsFormatter('login_below_button_6') }}</div>
          <div @click="onFAQsClicked" class="underline-text">{{ translationsFormatter('login_below_button_7') }}</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import LoginService from "@/services/loginService";
import formatMsisdn from "@/functions/formatMsisdn";
import {setNewBrandConfigLanguage} from "@/functions/setBrandConfig";
import UserDataService from "@/services/userDataService";

export default {
  name: "LoginPage",
  data() {
    return {
      userMsisdn: undefined,
      isMsisdnInputValid: true,
      isMsisdnLengthCorrect: true,

    }
  },
  computed: {
    ...mapState(['brandConfig'])
  },
  methods: {
    ...mapMutations(['setTermsAndConditionsOpened', 'setFrequentlyAskedQuestionsOpened', 'setUserMsisdn', 'setUser', 'setUserStats']),
    validateMsisdnInput() {
      this.containsOnlyNumbers()
    },
    containsOnlyNumbers() {
      let number = /^\d+$/.test(this.userMsisdn);
      this.isMsisdnInputValid = number !== false;
    },
    onTnCsClicked() {
      this.setTermsAndConditionsOpened(true);
      this.$router.push('/terms-and-conditions');
    },
    onFAQsClicked() {
      this.setFrequentlyAskedQuestionsOpened(true);
      this.$router.push('/frequently-asked-questions');
    },
    async startPlayingButtonClicked() {
      if (this.brandConfig.brand === 'ais-tai-tha') return this.aisLoginValidation();
      await this.requestOTP();
    },
    async aisLoginValidation() {
      const user = await LoginService.getUserViaProductId(this.brandConfig.productId, this.userMsisdn);
      if (user.statusName === 'Active') {
        this.isMsisdnInputValid = true;
        await LoginService.trackAisUserLogin(this.brandConfig.productId, this.userMsisdn);
        const userStats = (await UserDataService.getUserStats(this.userMsisdn)).data;
        this.setUser(user);
        this.setUserStats(userStats);
        this.$router.push(`/profile`);
      } else {
        this.isMsisdnInputValid = false;
      }
    },
    async requestOTP() {
      if (!this.isMsisdnInputValid) return;
      let msisdnObject = formatMsisdn(this.userMsisdn);
      this.userMsisdn = msisdnObject.msisdn;
      this.setUserMsisdn(this.userMsisdn);

      if (msisdnObject.msisdn.length === msisdnObject.length) {
        await LoginService.sendOTP(this.userMsisdn, this.brandConfig.revenuePartnerId)
        this.routeToSubmitOTP();
      } else {
        this.isMsisdnLengthCorrect = false
      }
    },
    onLanguageClicked() {
      setNewBrandConfigLanguage(this.brandConfig.brand)
      if (this.brandConfig.brand === 'ethiotel-et-en') this.language = 'AMH'
      if (this.brandConfig.brand === 'ethiotel-et-amh') this.language = 'EN'
    },
    routeToSubmitOTP() {
      this.$router.push('/otp');
    },
    getLanguage() {
      if (this.brandConfig.brand.includes('en')) return 'EN'
      if (this.brandConfig.brand.includes('amh')) return 'AMH'
    }
  },
  beforeMount() {
    if (this.brandConfig.brand === 'ethiotel-et-amh') this.language = 'AMH'
    if (this.brandConfig.brand === 'ethiotel-et-en') this.language = 'EN'
  },
}
</script>

<style scoped>
.language {
  padding: 5px;
  margin-top: 15px;
}

.text-red {
  color: red;
  font-size: 0.8rem;
}


.header-container {
  font-weight: 500;
  height: 40px;
  font-size: 14px;
}

.main-container {
  font-weight: 500;
  height: 200px;
  font-size: 28px;
  border-radius: 0 0 18px 18px;
  z-index: 1;
}

.fantasy-logo {
  height: 120px;
}

.background-behind {
  background-color: #e8e8e8;
  margin-top: -15px;
  z-index: -1;
  position: relative;
}

.msisdn-input-container {
  background-color: #e8e8e8;
  padding-bottom: 50px;
}

.login-info-text {
  font-weight: 500;
  font-size: 14px;
}

.msisdn-input-box {
  height: 45px;
  border-radius: 10px;
  border: 1px solid #b9b9b9;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

::placeholder {
  font-size: 13px;
  font-weight: 700;
}

.login-button {
  height: 45px;
  border-radius: 10px;
  border: 0;
  font-size: 13px;
  font-weight: 600;
}

.terms-conditions-faq-text {
  font-size: 12px;
}

.underline-text {
  text-decoration: underline;
}

</style>
