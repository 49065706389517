<template>
  <div class="container-fluid d-flex flex-column">
    <h4 class="d-flex mt-3">
      Operator Fantasy Games Terms and Conditions
    </h4>
    <div class="d-flex mt-3">
      The terms of this agreement (“Terms and Conditions”) govern the relationship between you
      and Operator and its affiliates (hereinafter “Operator” or “Us” or “We”) regarding your use of Operator
      Soccer’s Fantasy games Service and related features (the “Service”). Use of the Service is
      also governed by Operator’s Privacy Policy which is incorporated herein by reference.
      Your Acceptance of these Terms and Conditions
    </div>
    <div class="d-flex mt-3">
      Operator and its appointed partners are the provider of the Fantasy games Service (the
      “Service”), in its various formats accessible via mobile devices. Please read these Terms and
      Conditions and the Privacy Policy carefully before using the Service or submitting
      information through the Service. By using the Service or submitting information through
      the Service, you are agreeing to these Terms and Conditions. If you do not agree, please do
      not use this Service or submit any information through the Service. We reserve the right, at
      any time, to modify and update these Terms and Conditions from time to time by posting
      such updated Terms and Conditions on the Service portal. Any such updates shall apply to
      you and your use of the Service and by continuing to use the Service, you shall be deemed
      to have accepted such changes.
    </div>
    <div class="d-flex mt-3">
      <b>General</b>
    </div>
    <div class="d-flex px-2">
      1. As a user of the Service, you will have the option to play on a once off basis or subscribe,
      with either scenario making you a Paying User (“Subscribed”), and doing so accordingly
      binds you to additional terms of use as referred to in these Terms and Conditions. As a
      Subscribed user, you will be asked to submit personal information to us. We are
      committed to ensuring responsible practices in the collection, use and disclosure of your
      personal information in accordance with applicable law. The terms and conditions of the
      Privacy Policy are incorporated by reference into and form a binding part of these Terms
      and Conditions. If you do not agree with our Terms and Conditions please do not provide
      us with any personal information, and please do not use the Service.
    </div>
    <div class="d-flex px-2">
      2. By being Subscribed, you agree to the following additional rules:
    </div>
    <div class="d-flex px-2">
      ● Only one subscription is permitted per mobile number. Operator and non-Operator (where they
      are able to do so) users within the Republic of may subscribe.
    </div>
    <div class="d-flex px-2">
      ● You acknowledge that the Service has certain content and features which are locked
      and unavailable for access unless you become Subscribed.
    </div>
    <div class="d-flex px-2">
      ● You give us consent to provide you with various messages about / based on your
      interaction with the Service. These messages will be delivered to you in the form of text
      messages sent to your mobile device using the mobile number identifying you as a user
      of the Service.
    </div>
    <div class="d-flex px-2">
      3. These messages include, but are not limited to:
    </div>
    <div class="d-flex px-2">
      ● periodic messages to encourage, or remind you to browse or access the Service;
    </div>
    <div class="d-flex px-2">
      ● messages inviting you to share, voluntarily, data about yourself or your preferences;
    </div>
    <div class="d-flex px-2">
      ● messages inviting you to try alternate versions of the Service based on Operator’s
      knowledge of the device you are using to access the Service;
    </div>
    <div class="d-flex px-2">
      ● confirmation messages relating to your subscribing for, or cancellation of, the Service;
    </div>
    <div class="d-flex px-2">
      ● messages inviting you to rate the Service’s quality and your opinion of your experience
      of the Service as measured by the metric scale provided;
    </div>
    <div class="d-flex px-2">
      ● messages with Service content, including, but not limited to, confirmation of weekly
      results and suggested picks for the upcoming game week, and;
    </div>
    <div class="d-flex px-2">
      ● messages relating to the earning, notification and servicing of rewards.
    </div>
    <div class="d-flex mt-3">
      4. You may opt out of the messages above except for the system messages which are
      mandatory in order for us to correctly administer your use of the Service. To opt out
      of the messages, you are advised to make use of the functionality on the Service settings
      to cancel messages, or alternatively, to send an opt-out message of STOP OK to 32708.
    </div>
    <div class="d-flex mt-3">
      5. You accept that the Service’s default language is English.
    </div>
    <div class="d-flex mt-3">
      6. By subscribing, you agree that you will be opted into receiving the messages in clause 3)
      iv) above, which you may cancel at any time via the Service menu.
    </div>
    <div class="d-flex mt-3">
      7. Subscribed users may cancel the Service in entirety at any time via the Service menu.
      When cancelling the Service, you accept that your old profile and preferences will be
      retained for at least a 6 (six) month period, for the purpose of audit trail, and in order to
      facilitate a convenient experience
    </div>
    <div class="d-flex mt-3">
      8. You acknowledge that your subscription information will be retained for use to allow you
      access to future iterations and new features of the Service, and that you will be engaged
      to continue using the Service as applicable for other soccer competitions intended to be
      covered by the Service.
    </div>
    <div class="d-flex mt-3">
      <b>Provisions regarding Subscriptions</b>
    </div>
    <div class="d-flex mt-3">
      1. Subscribed users are given exclusive access to the Service on the basis of paying for a One
      Off or subscribing to a Recurring Billing Pass, either access is at N100 a day with a Free day
      access. You acknowledge and accept these services require payment on a once off or auto
      renewal subscription basis.
    </div>
    <div class="d-flex mt-3">
      2. One Off Users are billed on an ad-hoc basis and access to the Service is provided for a 24
      hour period commencing from the time at which payment was successfully processed.
      Subscription based users are billed daily. Subscription and usage is voluntary and occurs
      via the Service menu. This charge is subject to change at Operator’s discretion. Any changes
      therein will be communicated to subscribed users with reasonable notice and you will be
      afforded the option to cancel.
    </div>
    <div class="d-flex mt-3">
      3. Payment for access to the Service thereof may not be reimbursed after purchase should
      your performance in the game lead to you being unsuccessful in the random draws that
      will be executed. There is no refund should you choose to cancel the Service. We reserve
      the right to amend the pricing at any time, and any such changes will be communicated to
      you with at least 2 (two) week’s notice via applicable means, including the sending of a
      standard text message to your device to inform you of any applicable change.
    </div>
    <div class="d-flex mt-3">
      4. You acknowledge that the Service covers only the teams, players and tournaments as it
      has the applicable rights to cover at that point in time.
    </div>
    <div class="d-flex mt-3">
      5. Unless otherwise specified, the Service only covers official domestic and international
      soccer competitions, which may include the relevant Sports, cup and continental
      competitions as arranged by the relevant governing football authorities for the games
      which the Service covers, and / or has the appropriate rights to cover. You acknowledge
      that the composition and structure of these tournaments is outside of Operator and its
      appointed partner’s control and you indemnify us from any inconvenience caused by this.
    </div>
    <div class="d-flex mt-3">
      6. You acknowledge that any subscriptions in error will be billed. For your protection, we ask
      you to confirm all purchases.
    </div>
    <div class="d-flex mt-3">
      7. You acknowledge that we reserve the right to offer the first day of access free of charge
      to you on a discretionary and promotional basis, and this can change at our discretion at
      any time.
    </div>
    <div class="d-flex mt-3">
      <b>Rewards</b>
    </div>
    <div class="d-flex mt-3">
      1. The Promotion will have a duration of 90 days. The start date will be on 10th November
      2022 local time and the end date will be on 8th February 2022 (the Promotional Period).
    </div>
    <div class="d-flex mt-3">
      2. The Organizer will reserve the right to shift the start and end date by one or more weeks.
    </div>
    <div class="d-flex mt-3">
      3. The Organizer will reserve the right to extend the Promotion Duration by updating the Terms and Conditions.
    </div>
    <div class="d-flex mt-3">
      4. To be eligible for rewards from the service, you acknowledge that you need to have been
      successfully billed for the service.
    </div>
    <div class="d-flex mt-3">
      5. You acknowledge, understand and agree that your subscription to the Service does not
      guarantee any prizes from the Service. We shall not have any liability for such loss or
      damage in the course of using the Service.
    </div>
    <div class="d-flex mt-3">
      6. Winners are based on Random draws which will be executed every 1 or 2 months.
    </div>
    <div class="d-flex mt-3">
      7. We may offer “free” game play for entertainment purposes, without the possibilities of
      rewards being awarded. This will be clearly indicated at the outset in such an event.
    </div>
    <div class="d-flex mt-3">
      8. Points scored are based on the statistics from the data feed provided by our third party
      statistics provider (“Statistics Provider”). Although we use our best endeavours to ensure
      a good reputable Service, we are solely reliant on the information provided by the
      Statistics Provider and we are in no way responsible for faulty data received from the
      Statistics Provider.
    </div>
    <div class="d-flex mt-3">
      9. You hereby indemnify us against any and all claims, arising directly or indirectly due to
      faulty data received from our Statistics Provider.
    </div>
    <div class="d-flex mt-3">
      10. We wait for a certain period to elapse, to confirm the winner(s) of a Fantasy games event
      after the final game in the Fantasy games event is completed so that the Statistics
      Provider can correct any major errors in its system first.
    </div>
    <div class="d-flex mt-3">
      11. Note that once prizes are awarded to the winners, scoring results will not be changed
      regardless of any official adjustments made by professional sports games.
    </div>
    <div class="d-flex mt-3">
      12. This is not the same situation as a case where a correction needs to be made after
      settlement, due to a problem with the settlement process. In these cases, the settlement
      process (including prize awarding or airtime provisioning) may be reversed and games
      resettled correctly.
    </div>
    <div class="d-flex mt-3">
      13. Prizes may be substituted for Cash alternatives as needed. All taxes associated with the
      receipt or use of any prize is the sole responsibility of the winner. If any legal authority
      contests the awarding of a prize, we may, in our sole discretion, determine whether or
      not to award such a prize.
    </div>
    <div class="d-flex mt-3">
      14. Every winner will be contacted by the Organizer on the mobile phone number s/he will use
      to participate in the Promotion. The Organizer will attempt to contact the winner at least 3
      times a day over a period of 3 days, between the hours of 9am and 8pm. If the winner cannot be
      contacted during this period, s/he will be disqualified and forfeit the prize and the Organizer
      will attempt to contact the next runner up.
    </div>
    <div class="d-flex mt-3">
      15. The winner will be required to provide proof of eligibility and sign a prize acceptance form
      (as requested by the Organizer) before prizes will be collected. If the winner fails to provide
      sufficient proof within that period, s/he will be disqualified and forfeit the prize and the next
      runner up will be contacted.
    </div>
    <div class="d-flex mt-3">
      16. If a winner fails to redeem his/her prize within 6 months from the date of contact, the
      first substitute will be selected to claim the prize.
    </div>
    <div class="d-flex mt-3">
      17. If the winner and all runners-up will forfeit the prize, the prize will be deemed unclaimed
      and will remain at the disposal of the Organizer.
    </div>
    <div class="d-flex mt-3">
      18. You hereby agree to Your Username and location being published in the event that You
      are a winner. Winners may be asked to return via e-mail or regular mail an affidavit of
      eligibility. A winner’s failure to comply with such requests can result in disqualification.
      Any prize notification returned as undeliverable may result in disqualification and
      selection of an alternate winner.
    </div>
    <div class="d-flex mt-3">
      19. Rewards can only be earned by Operator customers. Any non-Operator customers will be unable
      to earn rewards due to their being active on a different network. You acknowledge that
      this is outside of Operator’s control and you indemnify Operator from any inconvenience caused
      by this.
    </div>
    <div class="d-flex mt-3">
      20. Only the qualifying Operator mobile number that is subscribed to the Service, can earn
      rewards during the draws.
    </div>
    <div class="d-flex mt-3">
      21. In the event that you terminate your Operator contract, you accept that you forfeit the use of
      any unused rewards by that point as well as the earning of future rewards from that
      point.
    </div>
    <div class="d-flex mt-3">
      22. The applicable prizes are as shown below:
    </div>
    <div class="d-flex">
      <table style="font-size: x-small" class="table-responsive table-bordered">
        <tr>
          <th>#</th>
          <th>TYPE</th>
          <th>DRAWS</th>
          <th>DRAW TYPE</th>
          <th># OF UNITS</th>
          <th>UNIT/DRAW</th>
          <th>COST/UNIT(LC)</th>
        </tr>
        <tr>
          <td>1</td>
          <td>Data/Airtime</td>
          <td>Daily</td>
          <td>Random</td>
          <td>360</td>
          <td>7</td>
          <td>10,000</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Monthly games
            Visit
          </td>
          <td>Monthly</td>
          <td>Random</td>
          <td>12</td>
          <td>1</td>
          <td>2,000,000</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Cash Prize</td>
          <td>Monthly</td>
          <td>Random</td>
          <td>12</td>
          <td>3</td>
          <td>100,000</td>
        </tr>
      </table>
    </div>
    <div class="d-flex mt-3">
      <b>Game Rules</b>
    </div>
    <div class="d-flex mt-3">
      1. Operator Fantasy games are games of skill and require some skill and knowledge of
      professional games information as well as our rules for You to accumulate fantasy points.
    </div>
    <div class="d-flex mt-3">
      2. Users are eligible to receive extra 10 points upon every successful billing.
    </div>
    <div class="d-flex mt-3">
      3. Fantasy points are accumulated through the performance of real-life athletes across
      multiple sporting matches or through participation in one of our trivia games
    </div>
    <div class="d-flex px-2 mt-3">
      Fantasy games Game Rules
    </div>
    <div class="d-flex px-2 mt-3">
      1. Each fantasy line-up must include real-life athletes, so that each entry into a Fantasy
      games event shall be made up of players drawn from a minimum of one real-life sporting
      match.
    </div>
    <div class="d-flex px-2 mt-3">
      2. We strictly prohibit the winning of any Fantasy games event based solely on the single
      performance of an individual athlete.
    </div>
    <div class="d-flex px-2 mt-3">
      3. The Service will automatically prevent You from saving a line-up that is in violation of
      any of these restrictions.
    </div>
    <div class="d-flex px-2 mt-3">
      4. There will be no trading of players, no extra moves and no replacing injured or benched
      players.
    </div>
    <div class="d-flex px-2 mt-3">
      4. There will be no trading of players, no extra moves and no replacing injured or benched
      players.
    </div>
    <div class="d-flex px-2 mt-3">
      5. Each Fantasy games event requires selecting one striker, two midfielders, one defensive
      unit (consisting of players listed as defensive players by the stats provider in a specific
      team) and a goalkeeper.
    </div>
    <div class="d-flex px-2 mt-3">
      6. The player pool consists of players that the Statistics Provider has statistics for in that
      particular games and determines to be eligible for the matches included in the Fantasy
      games event.
    </div>
    <div class="d-flex px-2 mt-3">
      7. Any players left off any squad by the Statistics Provider (“missing” players) will only be
      added once the Statistics Provider recognizes and/or adds the new player and we shall
      not be liable in any way for such player not being featured in any Fantasy games event.
    </div>
    <div class="d-flex px-2 mt-3">
      8. Each Fantasy games event specifies a date after which entries may not be added, edited,
      or cancelled.
    </div>
    <div class="d-flex px-2 mt-3">
      9. Fantasy games event results and winners will be based on the total points scored across
      each entrant’s squad (scoring summarized below) during the duration of the Fantasy
      games event.
    </div>
    <div class="d-flex px-2 mt-3">
      10. If a match that is part of a Fantasy games event is rescheduled outside of the originally
      scheduled beginning and end of the Fantasy games event, players in those matches will
      earn zero points.
    </div>
    <div class="d-flex px-2 mt-3">
      11. You can edit Your line-up at any point up to the start of a Fantasy games event by
      clicking on the edit option on the entry view. HOWEVER, once a Fantasy games event
      starts, your line-up will no longer be editable
    </div>
    <div class="d-flex px-2 mt-3">
      12. Users of the Service will have the ability to enter into Fantasy games events as long as
      their subscription to the content is active.
    </div>
    <div class="d-flex px-2 mt-3">
      13. We reserve the right to cancel any Fantasy games event before it is scheduled to begin.
    </div>
    <div class="d-flex px-2 mt-3">
      14. We reserve the right to combine player pools should the number of users in a pool be
      too low for reasonable levels of participation.
    </div>
    <div class="d-flex mt-3">
      <b>15. How Players Earn Points - Fantasy games Scoring system</b>
    </div>
    <div class="d-flex">
      <table class="table table-bordered">
        <tr>
          <th>Game /Action</th>
          <th>Daily Fantasy games</th>
        </tr>
        <tr>
          <td>Upon every successful charge</td>
          <td>10 points</td>
        </tr>
        <tr>
          <td>Minutes Played</td>
          <td>(Up to 60 minutes played) 2 points</td>
        </tr>
        <th colspan="2" class="text-center">Attacking</th>
        <tr>
          <td>Goal Scored by Midfielder</td>
          <td>5 points</td>
        </tr>
        <tr>
          <td>Goal Scored by Defender</td>
          <td>6 points</td>
        </tr>
        <tr>
          <td>Shot on target</td>
          <td>1 points</td>
        </tr>
        <tr>
          <td>Aide</td>
          <td>3 points</td>
        </tr>
        <tr>
          <td>Penalty Kick missed/Saved</td>
          <td>-2 points</td>
        </tr>
        <th colspan="2" class="text-center">Defending/Discipline</th>
        <tr>
          <td>Pass intercepted</td>
          <td>1 points</td>
        </tr>
        <tr>
          <td>Winning tackle</td>
          <td>1 point</td>
        </tr>
        <tr>
          <td>Clean sheet (Defenders)</td>
          <td>4 point</td>
        </tr>
        <tr>
          <td>Clean sheet (Midfielders)</td>
          <td>1 point</td>
        </tr>
        <tr>
          <td>Goal against(Defenders)</td>
          <td>-1 point</td>
        </tr>
        <tr>
          <td>Own goal</td>
          <td>-2 point</td>
        </tr>
        <tr>
          <td>Yellow card</td>
          <td>-1 point</td>
        </tr>
        <tr>
          <td>Red card</td>
          <td>-3 point</td>
        </tr>
        <th colspan="2" class="text-center">Goalkeepers</th>
        <tr>
          <td>Save</td>
          <td>1 point</td>
        </tr>
        <tr>
          <td>Penalty kick save</td>
          <td>5 point</td>
        </tr>
        <tr>
          <td>Clean Sheet</td>
          <td>4 point</td>
        </tr>
        <tr>
          <td>Goal against</td>
          <td>-1 point</td>
        </tr>
      </table>
    </div>
    <div class="d-flex mt-3">
      <b>Ownership</b>
    </div>
    <div class="d-flex mt-3">
      1. All rights, title and interest in and to the Service including, but not limited to, any games,
      titles, computer code, themes, objects, stories, dialogue, concepts, artwork, animations,
      sounds, musical compositions, audio-visual effects, methods of operation, moral rights,
      documentation are owned by Operator and its appointed partner. We reserve all rights,
      including, but not limited to, all intellectual property rights or other proprietary rights, in
      connection with the Service.
    </div>
    <div class="d-flex mt-3">
      2. Operator and its appointed partner owns, has licensed, or otherwise has rights to use, or
      provide all of the content that appears in the Service.
    </div>
    <div class="d-flex mt-3">
      <b>Your Obligations and Entitlements</b>
    </div>
    <div class="d-flex mt-3">
      1. Operator and its appointed partner reserves the right to determine what conduct it considers
      to be in violation of the rules of use or otherwise outside the intent or spirit of these
      Terms and Conditions or the Service itself. We reserve the right to take action as a result,
      which may include, if relevant, terminating your registration and prohibiting you from using
      the Service. Said termination and prohibition may occur with or without notice to you, and
      as a result may involve the loss of any benefits, privileges or earned items associated with
      your use of the Service, and we are under no obligation to compensate you for any such losses
      or results.
    </div>
    <div class="d-flex mt-3">
      2. You are entitled to unsubscribe from the Service at any time and for any reason by following
      the “cancel” prompts available within the Service menu, or by sending STOP OK to 32708.
    </div>
    <div class="d-flex mt-3">
      <b>Limitation of Liability</b>
    </div>
    <div class="d-flex mt-3">
      1. You agree that your use of the services shall be at your sole risk. To the fullest extent
      permitted by law, we and our officers, directors, employees, and agents disclaim all
      warranties, express or implied, in connection with the services and your use thereof. We
      make no warranties or representations about the accuracy or completeness of the
      services’ content or the content of any websites or services linked to these services and
      assume no liability or responsibility for any (i) errors, mistakes, or inaccuracies of content;
      (ii) personal injury or property damage of any nature whatsoever resulting from your
      access to and use of the services; (iii) any unauthorized access to or use of our servers
      and/or any and all personal information and/or financial information stored therein; (iv)
      any interruption or cessation of transmission to or from the services; (v) any bugs, viruses,
      trojan horses or the like which may be transmitted to or through the services by any third
      party; or (vi) any errors or omissions in any content or for any loss or damage of any kind
      incurred as a result of the use of any content posted, emailed, transmitted, or otherwise
      made available via the services. We do not warrant, endorse, guarantee, or assume
      responsibility for any product or service advertised or offered by a third party through the
      services or any hyperlinked website or featured in any banner or other advertising, and
      we will not be a party to or in any way be responsible for monitoring any transaction
      between you and third-party providers of products or services.
    </div>
    <div class="d-flex mt-3">
      2. To the extent permitted by applicable law, in no event shall we or our subsidiaries and
      affiliates, and their respective officers, directors, owners, agents, employees,
      representatives and licensors be liable for any special, incidental, indirect, consequential,
      punitive or exemplary losses or damages whatsoever or for loss of profits (including,
      without limitation, damages for loss of revenue, loss of data, failure to realize expected
      savings, interruption of activities, or any other pecuniary or economic loss) and whether
      arising from breach of contract, damages (including negligence), strict liability or
      otherwise, arising out of the use of or inability to use these services or its content, or any
      product or service described or provided through the services.
    </div>
    <div class="d-flex mt-3">
      3. Save to the extent permitted by law, you agree to indemnify, defend and hold harmless
      Operator from and against any and all claims, demands, actions, liability, losses, costs and
      expenses (including legal fees and expenses) arising from or related to: (i) any use or
      alleged use of the Service by any other person, whether or not authorized by you; (ii) your
      breach of these Terms and Conditions, including any breach of your representations and
      warranties herein; and (iii) your violation of any law or (iv) your use of or inability to use
      the Service, the contents, any hyperlinked website, and any of the products and services
      made available on the Service thereof.
    </div>
    <div class="d-flex mt-3">
      <b>Privacy and data protection</b>
    </div>
    <div class="d-flex mt-3">
      1. This clause explains how we collect, use, share and protect your personal information.
      This clause should be read together with Operator’s Privacy Policy . If we update this clause
      and our privacy policy, we will post any changes on our website.
    </div>
    <div class="d-flex mt-3">
      2. <b>Collecting your personal information</b>
    </div>
    <div class="d-flex px-2">
      We can get your personal information when you use this Service. In the case of the use of
      the Service your personal information is collected for the purpose of being used and
      processed in:
    </div>
    <div class="d-flex px-2">
      ● personalising the Service to your respective preferences,
    </div>
    <div class="d-flex px-2">
      ● for the serving of appropriate, tailored advertising to you via the Service,
    </div>
    <div class="d-flex px-2">
      ● for the purpose of tracking the Service’s performance,
    </div>
    <div class="d-flex px-2">
      ● troubleshooting, data analysis, testing, research and service improvement and/or to
      identify any technical issues that may occur from time to time,
    </div>
    <div class="d-flex px-2">
      ● for use in devising additional enhancements or improvements to the Service, and for
    </div>
    <div class="d-flex px-2">
      ● statistical, tracking, redundancy and audit purposes.
    </div>
    <div class="d-flex mt-3">
      3. The above data will not be shared with other users of the Service. However, Operator may
      collect your information in terms of clause 10 (h) and (i) below.
    </div>
    <div class="d-flex mt-3">
      4. We may also collect information about you from other organisations, if this is
      appropriate. These include fraud-prevention agencies, business directories and credit
      reference agencies. We may also collect information about you from other companies,
      our business, or joint venture partners.
    </div>
    <div class="d-flex mt-3">
      5.<b>Understanding what you want</b>
    </div>
    <div class="d-flex mt-3">
      We might also use cookies (small text files stored in your browser) and other techniques
      such as web beacons (small, clear picture files used to follow your movements on our
      website). These collect information that tells us how you use our websites, web-related
      products and services.
    </div>
    <div class="d-flex mt-3">
      6. This, in turn, helps us make our website relevant to your interests and needs. We may use
      a persistent cookie (a cookie that stays linked to your browser) to record your details so
      we can recognise you if you visit our website again. See the next section for more details.
    </div>
    <div class="d-flex mt-3">
      7. You can choose to refuse cookies, or set your browser to let you know each time a
      website tries to set a cookie.
    </div>
    <div class="d-flex mt-3">
      8.<b>The personal information we collect</b>
    </div>
    <div class="d-flex px-2">
      We might also use cookies (small text files stored in your browser) and other techniques
      such as web beacons (small, clear picture files used to follow your movements on our
      website). These collect information that tells us how you use our websites, web-related
      products and services.
    </div>
    <div class="d-flex px-2">
      ● your name, gender, date of birth, home language, address, and email address;
    </div>
    <div class="d-flex px-2">
      ● your preferences for particular products, services or lifestyle activities when you tell us
      what they are – or when we assume what they are, depending on how you use our
      products and services;
    </div>
    <div class="d-flex px-2">
      ● your contact with us – such as a note or recording of a call you make to one of our
      contact centres, an email or letter you send to us or other records of any contact you
      have with us;
    </div>
    <div class="d-flex mt-3">
      9. We will also get information on how you use our products and services, such as:
    </div>
    <div class="d-flex px-2">
      ● the phone numbers that you call or send messages to (or the phone numbers that you
      receive these calls and messages from);
    </div>
    <div class="d-flex px-2">
      ● the date, time and length of the calls and messages you send or receive through our
      network, and your approximate location at the time these communications take place;
    </div>
    <div class="d-flex px-2">
      ● the level of service you receive – for example, network faults and other network events
      which may affect our network services;
    </div>
    <div class="d-flex px-2">
      ● your website browsing information (which includes information about the websites you
      visit, and about how you use our website or other Operator Group websites on your mobile
      or a PC;
    </div>
    <div class="d-flex px-2">
      ● the date, time and length of your internet browsing, and your approximate location at
      the time of browsing;
    </div>
    <div class="d-flex px-2">
      ● your brand preference, preferred video categories, related preferences (e.g. team
      choice); and type of services you typically access.
    </div>
    <div class="d-flex mt-3">
      10. <b>Using your personal information</b>
    </div>
    <div class="d-flex px-2">We may use and analyse your information to:</div>
    <div class="d-flex px-2">
      ● process the goods and services you have bought from us, and keep you updated with
      your order progress;
    </div>
    <div class="d-flex px-2">
      ● keep you informed generally about new products and services (unless you choose not
      to receive our marketing messages);
    </div>
    <div class="d-flex px-2">
      ● provide the relevant service or product to you. This includes other services not included
      in this terms and conditions, and services that use information about where you are
      when using your mobile equipment (location information) and to contact you with
      messages about changes to the service or product;
    </div>
    <div class="d-flex px-2">
      ● contact you with offers or promotions based on how you use our products and services.
      These include your calling and messaging activities, location information and browsing
      information (unless you choose not to receive these messages – see below on ‘How to
      opt-out’;
    </div>
    <div class="d-flex px-2">
      ● send you targeted and relevant messages, based on your behaviour, permission and
      preferences. From time to time, we will send you a range of different messages, from
      Operator as well as brands, to keep you informed, or simply for you to tell us what you are
      into. These are not just offers and promotions but messages from your favourite brands
      including new products, discounts, limited offers, gifts and more. It works by using
      information about you to send you targeted messages relevant to you;
    </div>
    <div class="d-flex px-2">
      ● bill you for using our products or services, or to take the appropriate amount of credit
      from you;
    </div>
    <div class="d-flex px-2">
      ● respond to any questions or concerns you may have about using our network, products
      or services;
    </div>
    <div class="d-flex px-2">
      ● bill you for using our products or services, or to take the appropriate amount of credit
      from you;
    </div>
    <div class="d-flex px-2">
      ● let you know about other companies&#39; products and services we think may interest you
      (including offers and discounts we’ve specially negotiated for our customers);
    </div>
    <div class="d-flex px-2">
      ● protect our network and manage the volume of calls, texts and other use of our
      network. For example, we identify peak periods of use so we can try and ensure the
      network can handle the volume at those times
    </div>
    <div class="d-flex px-2">
      ● understand how you use our network, products and services. That way, we can develop
      more interesting and relevant products and services, as well as personalising the
      products and services we offer you;
    </div>
    <div class="d-flex px-2">
      ● carry out research and statistical analysis including to monitor how customers use our
      network, products and services on an anonymous or personal basis;
    </div>
    <div class="d-flex px-2">
      ● prevent and detect fraud or other crimes, recover debts or trace those who owe us
      money;
    </div>
    <div class="d-flex px-2">
      ● provide aggregated reports to third parties (such reports do not contain any
      information which may identify you as an individual).
    </div>
    <div class="d-flex mt-3">
      11. The information we use will be your approximate location, based on the nearest mobile
      cell site. As a result, this will change as you move around with your mobile phone.
    </div>
    <div class="d-flex mt-3">
      12. We will store your information for as long as we have to by law. If there is no legal
      requirement, we will only store it for as long as we need it.
    </div>
    <div class="d-flex mt-3">
      13.<b> Sharing your personal information</b>
    </div>
    <div class="d-flex px-2">We may share information about you with:</div>
    <div class="d-flex px-2">
      ● your name, gender, date of birth, home language, address, and email address;
    </div>
    <div class="d-flex px-2">
      ● companies in the Operator and Vodafone Group (Vodafone Group Plc and any company or
      other organisation in which Operator owns more than 15% of the share capital);
    </div>
    <div class="d-flex px-2">
      ● partners or agents involved in delivering the Services;
    </div>
    <div class="d-flex px-2">
      ● companies who are engaged to perform Service for, on behalf of Operator.
    </div>
    <div class="d-flex px-2">
      ● where applicable, credit reference, fraud prevention or business scoring agencies, or
      other credit scoring agencies;
    </div>
    <div class="d-flex px-2">
      ● debt collection agencies or other debt recovery organisations;
    </div>
    <div class="d-flex px-2">
      ● law enforcement agencies, regulatory organisations, courts or other public authorities if
      we have to, or are authorised to by law;
    </div>
    <div class="d-flex px-2">
      ● emergency services (if you make an emergency call), including your approximate
      location.
    </div>
    <div class="d-flex mt-3">
      14. We will release information if it’s reasonable for the purpose of protecting us against
      fraud, defending our rights or property, or to protect the interests of our customers.
    </div>
    <div class="d-flex mt-3">
      15. If we are reorganised or sold to another organisation, we may transfer any personal
      information we hold about you to that organisation.
    </div>
    <div class="d-flex mt-3">
      16. We may need to transfer your information to other group companies or service providers
      in countries outside South Africa. This may happen if our servers or suppliers and service
      providers are based outside South Africa, or if you use our services and products while
      visiting countries outside this area.
    </div>
    <div class="d-flex mt-3">
      17. At your option, we may also share your information with partner organisations we’ve
      chosen carefully, so they can contact you about their products and services.
    </div>
    <div class="d-flex mt-3">
      18. <b>Keeping your personal information secure</b>
    </div>
    <div class="d-flex mt-3 px-2">
      We have specialised security teams who constantly review and improve our measures to
      protect your personal information from unauthorised access, accidental loss, disclosure or
      destruction.
    </div>
    <div class="d-flex mt-3">
      19. If we have a contract with another organisation to provide us with services or a service on
      our behalf to process your personal information, we will make sure they have appropriate
      security measures and only process your information in the way we’ve authorised them
      to. These organisations will not be entitled to use your personal information for their own
      purposes. If necessary, our security teams will check them to make sure they meet the
      security requirements we have set.
    </div>
    <div class="d-flex mt-3">
      20. Communications over the internet (such as emails) are not secure unless they have been
      encrypted. Your communications may go through a number of countries before being
      delivered – as this is the nature of the internet. We cannot accept responsibility for any
      unauthorised access or loss of personal information that’s beyond our control.
    </div>
    <div class="d-flex mt-3">
      21. <b>How to opt-out</b>
    </div>
    <div class="d-flex px-2">
      If you want to opt out of the Operator Soccer Fantasy games service and content messages;
    </div>
    <div class="d-flex px-2">
      ● Send STOP FS to 30708, and go to “Fantasy”, “Settings”, “Cancel messages” and cancel
      the messages as you prefer; or
    </div>
    <div class="d-flex px-2">
      ● Please note you may not opt out of system messages.
    </div>
  </div>
</template>

<script>
export default {
  name: "DemoTerms"
}
</script>

<style scoped>

</style>
