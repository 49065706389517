<template>
  <div v-if="!roundSelected.selections[position]" class="player-plus-circle"
       @click="playerPlusIconClicked(position)">
    <img src="../../assets/plus-icon.png" class="player-plus-icon">
    <div class="team-position-title secondary-bg-colour primary-text">
      {{ translationsFormatter(`team_builder_${getPositionName(position)}_position_label`) }}
    </div>
  </div>
  <div v-if="roundSelected.selections[position]" class="player-plus-circle"
       @click="playerPlusIconClicked(position)">
    <img class="captain-logo" v-if="roundSelected.selections[position].captain"
         :src="require(`../../brand/${brandConfig.brand}/assets/captain-icon.png`)">
    <img :src="Helpers.findJerseyImageModule(roundSelected.selections[position].teamNames[0])"
         class="player-plus-icon">
    <div class="team-position-title secondary-bg-colour primary-text">
      {{ this.initialSurnameFormat(roundSelected.selections[position].name) }}
      <img class="edit-player-circle" :src="require(`../../brand/${brandConfig.brand}/assets/edit-icon.png`)"
           @click="playerPlusIconClicked(position)"/>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {setSelectedPosition} from "@/functions/roundSelection";

export default {
  name: "PlayerFieldPosition",
  props: {
    roundSelected: Object,
    position: String
  },
  computed: {...mapState(['brandConfig'])},
  methods: {
    ...mapMutations(['setSelectedPosition', 'setSelectedPositionInRound']),

    getPositionName(position) {
      if (position === "midfielder1" || position === "midfielder2") return "midfield"
      if (position === "defence") return "defender"
      return position;
    },

    playerPlusIconClicked(position) {
      const positionLowerCase = position.toLowerCase()
      this.roundSelected.selections[positionLowerCase]
      this.setSelectedPositionInRound(position)
      setSelectedPosition(position)
      this.$router.push('/team-selector');
    },

    initialSurnameFormat(fullName) {
      const noArrayName = Array.isArray(fullName) ? fullName[0] : fullName
      const hasSpaces = noArrayName.includes(" ");
      if (!hasSpaces) return noArrayName;
      const firstNameLetter = noArrayName[0];
      const indexOfSpace = noArrayName.indexOf(' ');
      const surname = noArrayName.slice(indexOfSpace);
      return `${firstNameLetter}. ${this.getLastWord(surname)}`;
    },
    getLastWord(inputString) {
      let trimmedString = inputString.trim();
      let wordsArray = trimmedString.split(" ");
      return wordsArray[wordsArray.length - 1];
    }
  },
}
</script>

<style scoped>

.player-plus-circle {
  position: relative;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.player-plus-icon {
  height: 60px;
  width: 60px;
}

.team-position-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  width: auto;
  padding: 5px;
  height: 20px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 600;
}

.captain-logo {
  position: absolute;
  left: 67%;
  top: -3%;
  height: 20px;
}

.edit-player-circle {
  position: relative;
  right: -10%;
  height: 20px !important;
  width: 20px !important;
  border-radius: 50%;
}
</style>
