<template>
  <div class="notifications-page" v-if="!isLoading">
    <div
        class="d-flex flex-column justify-content-center text-center primary-bg-colour primary-text notifications-header">
      <img @click="backButtonClicked" :src="require(`../../brand/${brandConfig.brand}/assets/left-keyboard-arrow.png`)"
           class="go-back-key">
      {{ translationsFormatter('notifications_header') }}
    </div>
    <div
        class="d-flex flex-column justify-content-center text-center p-4 secondary-bg-colour primary-text manage-notifications-text">
      {{ translationsFormatter('notifications_manage') }}
    </div>
    <div class="d-flex align-items-center justify-content-center circle-container pt-5 pb-5">
      <div class="d-flex flex-column justify-content-center mt-5 primary-bg-colour primary-text question-mark-circle">
        ?
      </div>
    </div>
    <div class="d-flex flex-row justify-content-center pt-5 ">
      <input class="col-3" type="checkbox" v-model="checked" :checked="isChecked">
      <div class="d-flex flex-column justify-content-center col-7 checkbox-text">
        {{ translationsFormatter('notifications_checkbox') }}
      </div>
    </div>
    <div class="d-flex flex-column text-center p-4 service-updates-message">
      {{ translationsFormatter('notifications_service_updates_message') }}
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center">
      <button @click="saveButtonClicked" class="mt-4 col-10 primary-bg-colour primary-text save-button">
        {{ translationsFormatter('notifications_save_button') }}
      </button>
      <button @click="backButtonClicked" class="mt-1 mb-2 col-5 secondary-bg-colour primary-text back-button">
        {{ translationsFormatter('notifications_back_button') }}
      </button>
    </div>
  </div>
</template>

<script>
import UserDataService from "@/services/userDataService";
import {mapState, mapMutations} from "vuex";

export default {
  name: "ManageNotifications",
  data() {
    return {
      isChecked: false
    }
  },
  async beforeMount() {
    if (this.user.optInServiceMsgs) this.isChecked = true
    this.setIsLoading(false);
  },
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'user'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setUser']),
    async saveButtonClicked() {
      if (this.checked) await UserDataService.updateUserData(this.user.msisdn, this.user.username, 1);
      else await UserDataService.updateUserData(this.user.msisdn, this.user.username, 0);
      const user = await UserDataService.getUserStatus(this.user.token);
      this.setUser(user.data);
      this.$router.push('/profile');
    },
    backButtonClicked() {
      this.$router.push('/settings');
    },
  }
}
</script>

<style scoped>

.notifications-page {
  background-color: #e8e8e8;
  overflow-y: scroll;
  padding-bottom: 20px;
}

.notifications-page::-webkit-scrollbar {
  display: none;
}

.notifications-header {
  position: relative;
  font-weight: 600;
  font-size: 18px;
  height: 45px;
}

.go-back-key {
  position: absolute;
  height: 20px;
  left: 27px;
  top: 13px;
}

.manage-notifications-text {
  height: 70px;
  border-radius: 0 0 20px 20px;
  font-size: 14px;
  font-weight: 500;
}

.question-mark-circle {
  position: absolute;
  width: 115px;
  height: 115px;
  border-radius: 50%;
  font-weight: 700;
  font-size: 80px;
  padding-bottom: 10px;
  padding-left: 40px;
}

.circle-container {
  height: 40%;
  position: relative;
  margin-top: 32px;
  margin-bottom: 32px;
}

input {
  height: 35px;
}

.checkbox-text {
  font-weight: 700;
}

.service-updates-message {
  font-weight: 700;
}

.save-button {
  height: 45px;
  border: 0;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
}

.back-button {
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  border: 0;
}


</style>
