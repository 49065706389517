<template>
  <div class="main-container">
    <div class="d-flex flex-column col-12">
      <div class="m-2">
        <div class="m-1">
          ጥ፡- “FANTASY SPORTS” አገልግሎት ምንድነው?
        </div>
        <div class="m-1">
          መ: “FANTASY SPORTS” የእግር ኳስ እና የእግር ኳስ ተጫዋቾች እውቀትዎን በጥሩ ሁኔታ እንዲጠቀሙ
          የሚያስችልዎ አስደሳች የጨዋታ ፖርታል ነው። ከበርካታ ሊጎች ውስጥ የተውጣጡ የእግር ኳስ ተጫዋቾችን
          በመምረጥ በየቀኑ የእርስዎን ህልም ቡድን በመፍጠር ይጀምሩ። ከዚያም፣ በተጫዋቾቹ የእውነተኛ ህይወት አፈጻጸም
          ላይ በመመስረት፣ የእርስዎ ምናባዊ ቡድን ነጥብ ያገኛል። ብዙ ነጥቦች ባገኙ ቁጥር በመሪዎች ሰሌዳው ላይ ከፍ
          ብለው የመረመዝገቡ ይሆናል። በአገልግሎት ጊዜ ማብቂያ ላይ ለመጨረሻው ሽልማት እጣ ውስጥ ይገባሉ።
        </div>
      </div>
      <div class="m-2">
        <div class="m-1">
          ጥ፡ ይህ የ&quot;FANTASY SPORTS&quot; አገልግሎት ለማን ነው?
        </div>
        <div class="m-1">
          መ፡ “FANTASY SPORTS” ለሁሉም የኢትዮ ቴሌኮም የሞባይል ደንበኞች ይገኛል።
        </div>
      </div>
      <div class="m-2">
        <div class="m-1">
          ጥ፡ በ&quot;FANTASY SPORTS&quot; አገልግሎት እንዴት መሳተፍ እችላለሁ?
        </div>
        <div class="m-1">
          መ: ለ “FANTASY SPORTS” ለመመዝገብ ሶስት መንገዶች አሉ። አገልግሎቱን በመስመር ላይ ሲያዩ በቀላሉ
          የሞባይል ማስታወቂያ ባነር መጫን ይችላሉ; ወይም በመነሻ ገጹ ላይ &quot;Subscribe&quot; የሚለውን ቁልፍ በመጫን
          በዚህ ፖርታል በቀጥታ መመዝገብ ይችላሉ። በመጨረሻ፣ OK ብለው ወደ 9401 በመላክ መመዝገብ ይችላሉ።
        </div>
      </div>
      <div class="m-2">
        <div class="m-1">
          ጥ፡ “FANTASY SPORTS” አገልግሎቱ ምን ያህል ያስከፍላል?
        </div>
        <div class="m-1">
          መ: የ “FANTASY SPORTS” አገልግሎት ዕለታዊ ምዝገባ በቀን 2 ብር ያስከፍላል ፣ ወዲያውኑ ይታደሳል።
          የእንኳን ደህና መጣችሁ ቅናሽ፡ ለእያንዳንዱ አዲስ የአገልግሎቱ ተመዝጋቢ 3 ነጻ ቀናት።
        </div>
      </div>
      <div class="m-2">
        <div class="m-1">
          ጥ: ምን ያህል ነጥቦችን ማግኘት እችላለሁ?
        </div>
        <div class="m-1 col-12">
          መ፡ “FANTASY SPORTS” (ፖርታል/መተግበሪያ)
          <table class="table table-bordered mt-2">
            <thead>
            <tr class="table-header secondary-bg-colour primary-text">
              <th scope="col">ድርጊት</th>
              <th scope="col">የተገኙ ነጥቦች</th>
            </tr>
            </thead>
            <tbody class="table-body primary-bg-colour">
            <tr>
              <th scope="row">ክፍያ ላይ ነጥቦች</th>
              <td>ለሂሳብ አከፋፈል ታሪፍ በእያንዳንዱ የተሳካ ክፍያ ላይ 10 ነጥቦች</td>
            </tr>
            </tbody>
          </table>

          <table class="table table-bordered mt-2">
            <thead>
            <tr class="table-header secondary-bg-colour primary-text">
              <th scope="col">ድርጊት</th>
              <th scope="col">የተገኙ ነጥቦች</th>
            </tr>
            </thead>
            <tbody class="table-body primary-bg-colour">
            <tr>
              <th scope="row">የተጫወቱት ደቂቃዎች</th>
              <td>እስከ 60 ደቂቃዎች ሲጫወቱ፣ 2 ነጥብ</td>
            </tr>
            <tr>
              <th scope="row">በአጥቂነት ያስቆጠሩት ግብ</th>
              <td>4 ነጥብ</td>
            </tr>
            <tr>
              <th scope="row">በመሃል ሜዳ ተጫዋችነት ያስቆጠሩት ግብ</th>
              <td>5 ነጥብ</td>
            </tr>
            <tr>
              <th scope="row">በተከላካይነት ያስቆጠሩት ግብ</th>
              <td>6 ነጥብ</td>
            </tr>
            <tr>
              <th scope="row">መርዳት</th>
              <td>3 ነጥብ</td>
            </tr>
            <tr>
              <th scope="row">ኢላማ ላይ መለጋት</th>
              <td>1 ነጥብ</td>
            </tr>
            <tr>
              <th scope="row">የፍፁም ቅጣት ምት መሳት/መዳን</th>
              <td>-2 ነጥብ</td>
            </tr>
            <tr>
              <th scope="row">እንዳያልፍ መጥለፍ</th>
              <td>1 ነጥብ</td>
            </tr>
            <tr>
              <th scope="row">የማሸነፍ ትግል/ታክል</th>
              <td>1 ነጥብ</td>
            </tr>
            <tr>
              <th scope="row">ክሊን ሺት (ተከላካዮች)</th>
              <td>4 ነጥብ</td>
            </tr>
            <tr>
              <th scope="row">ክሊን ሺት (የመሃል ሜዳ ተጫዋቾች)</th>
              <td>1 ነጥብ</td>
            </tr>
            <tr>
              <th scope="row">(በተከላካዮች ላይ) ግብ ሲገባ</th>
              <td>-1 ነጥብ</td>
            </tr>
            <tr>
              <th scope="row">በራስ ላይ ግብ ሲገባ</th>
              <td>-2 ነጥብ</td>
            </tr>
            <tr>
              <th scope="row">ቢጫ ካርድ</th>
              <td>-1 ነጥብ</td>
            </tr>
            <tr>
              <th scope="row">ቀይ ካርድ</th>
              <td>-3 ነጥብ</td>
            </tr>
            <tr>
              <th scope="row">ማዳን (ግብ ጠባቂ)</th>
              <td>1 ነጥብ</td>
            </tr>
            <tr>
              <th scope="row">የፍፁም ቅጣት ምት ማዳን (ግብ ጠባቂ)</th>
              <td>5 ነጥብ</td>
            </tr>
            <tr>
              <th scope="row">ክሊን ሺት (ግብ ጠባቂ)</th>
              <td>4 ነጥብ</td>
            </tr>
            <tr>
              <th scope="row">ግብ ሲገባ (በግብ ጠባቂ ላይ)</th>
              <td>-1 ነጥብ</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="m-2">
        <div class="m-1">
          ጥ፡ ሽልማቶቹ ምንድናቸው?
        </div>
        <div class="m-1 col-12">
          መ፡ ፕሪሚየም አገልግሎት
          <table class="table table-bordered mt-2">
            <thead>
            <tr class="table-header secondary-bg-colour primary-text">
              <th scope="col">የሽልማት ዓይነት</th>
              <th scope="col">ብዛት</th>
              <th scope="col">ድግግሞሽ</th>
              <th scope="col">ምድብ</th>
            </tr>
            </thead>
            <tbody class="table-body primary-bg-colour">
            <tr>
              <th scope="row">FANTASY SPORTS: iPhone 13 Pro phone</th>
              <td>1</td>
              <td>የመጨረሻ ሽልማት</td>
              <td>እጣ በወጣበት</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="m-2">
        <div class="m-1">
          ጥ፡ የ«FANTASY SPORTS» ፖርታልን ለመድረስ የሞባይል ዳታ ማብራት አለብኝ?
        </div>
        <div class="m-1">
          መ: አዎ፣ የጨዋታ መግቢያውን &quot;FANTASY SPORTS&quot; ለመድረስ የሞባይል ዳታ ማብራት ወይም የWi-Fi ግንኙነት
          ሊኖርዎት ይገባል።
        </div>
      </div>
      <div class="m-2">
        <div class="m-1">
          ጥ: በፖርታሉ ውስጥ እንዴት መጫወት እችላለሁ?
        </div>
        <div class="m-1">
          መ: ወደ ፖርታሉ መግቢያ/ሊንክ ያለው የጽሁፍ መልእክት ይደርስዎታል። መግቢያው/ሊንኩ ላይ ይጫኑ እና
          መጫወት መጀመር ይችላሉ።
        </div>
      </div>
      <div class="m-2">
        <div class="m-1">
          ጥ፡ እንዴት ከ&quot;FANTASY SPORTS&quot; አገልግሎት ደንበኝነት መውጣት እችላለሁ?
        </div>
        <div class="m-1">
          መ: ከ&quot;FANTASY SPORTS&quot; አገልግሎት ደንበኝነት ለመውጣት &quot;STOP&quot; የሚለውን ቃል ወደ 9401 አጭር ኮድ
          ይላኩ።
        </div>
      </div>
      <div class="m-2">
        <div class="m-1">
          ጥ፡ በዝግጅቱ ላይ ተጨማሪ መረጃ ካስፈለገኝስ?
        </div>
        <div class="m-1">
          መ: ለሚፈልጉት ማንኛውም ተጨማሪ መረጃ፣ http://sp-tcs.upp.st/ መጎብኘት ይችላሉ
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: "EthiotelAmharicFAQs",
  computed: {
    ...mapState(['brandConfig']),
  },
}
</script>

<style scoped>
.main-container {
  background-color: #e8e8e8;
  height: 100%;
}

table {
  font-size: 10px;
}
</style>
