<template>
  <div class="change-username-page" v-if="!isLoading">
    <div
        class="d-flex flex-column justify-content-center text-center primary-bg-colour primary-text change-username-header">
      <img @click="backButtonClicked" :src="require(`../../brand/${brandConfig.brand}/assets/left-keyboard-arrow.png`)"
           class="go-back-key">
      {{ translationsFormatter('username_header') }}
    </div>
    <div
        class="d-flex flex-column justify-content-center text-center p-4 secondary-bg-colour primary-text change-username-explanation">
      {{ translationsFormatter('username_explanation_content') }}
    </div>
    <div class="text-center pt-5 pb-4 username-input-container">
      <img :src="require(`../../brand/${brandConfig.brand}/assets/user-icon.png`)" class="mb-5 user-profile-icon">
      <div class="d-flex flex-row justify-content-center pt-3 current-username-text">
        <div>{{ translationsFormatter('username_current_username') }}</div>
        <div class="username">&nbsp{{ this.user.username }}</div>
      </div>
      <input class="col-11 text-center new-username-input-box" type="text" v-model="newUsername"
             :placeholder="translationsFormatter('username_input_placeholder')">
      <div class="p-3 username-min-max-character-explanation-text">
        {{ translationsFormatter('username_min_max_character') }}
      </div>
      <button @click="changeUsernameButtonClicked(newUsername)"
              class="mt-4 col-9 primary-bg-colour primary-text change-username-button">
        {{ translationsFormatter('username_change_username_button') }}
      </button>
      <div class="mt-2 mb-1" v-if="invalidUsername"> {{ translationsFormatter('username_invalid') }}</div>
      <button @click="backButtonClicked" class="mt-1 mb-2 col-4 secondary-bg-colour primary-text back-button">
        {{ translationsFormatter('username_back_button') }}
      </button>
    </div>
  </div>
</template>
<script>
import {mapState, mapMutations} from "vuex";
import UserDataService from "@/services/userDataService";

export default {
  name: "EditUsername",
  data() {
    return {
      newUsername: '',
      invalidUsername: false,
    }
  },
  async beforeMount() {
    const userStats = await UserDataService.getUserStats(this.user.msisdn);
    this.getUserStatsResponse = userStats.data;
    this.setIsLoading(false);
  },
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'user'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setUser']),
    async changeUsernameButtonClicked(username) {
      this.setIsLoading(true)
      let usernameCharacterCountCheckResponse = this.checkUsernameCharacterCount(username);

      if (usernameCharacterCountCheckResponse === true) {
        const validateUsernameResponse = await UserDataService.validateUsername(username, this.token);
        if (validateUsernameResponse.data.isValid === false) {
          this.invalidUsername = true
        }

        if (validateUsernameResponse.data.isValid) {
          this.invalidUsername = false;
          this.newUsername = username;
          await UserDataService.updateUserData(this.user.msisdn, username);
          const userData = await UserDataService.getUserStatus(`${this.user.token}`);
          this.setUser(userData.data)
          this.$router.push('/settings')
        }
      }
      this.setIsLoading(false)
    },
    checkUsernameCharacterCount(username) {
      if (username.length < 6 || username.length > 14) {
        this.invalidUsername = true;
        return false;
      } else return true;
    },
    backButtonClicked() {
      this.$router.push('/settings');
    },
  }
}
</script>

<style scoped>
.change-username-page {
  background-color: #e8e8e8;
}

.change-username-header {
  position: relative;
  font-weight: 600;
  font-size: 18px;
  height: 45px;
}

.go-back-key {
  position: absolute;
  height: 20px;
  left: 10px;
  top: 13px;
}

.change-username-explanation {
  height: 70px;
  border-radius: 0 0 20px 20px;
  font-size: 14px;
  font-weight: 500;
}

.username-input-container {
  position: relative;
  margin-top: -12px;
}

.user-profile-icon {
  height: 120px;
}

.username {
  font-weight: 700;
}

.new-username-input-box {
  height: 50px;
  border-radius: 10px;
  border: 1px solid white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 2;
}

::placeholder {
  font-size: 14px;
  color: #cccccc;
}

.username-min-max-character-explanation-text {
  font-weight: 700;
  font-size: 14px;
}

.change-username-button {
  height: 45px;
  border: 0;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
}

.back-button {
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  border: 0;
}
</style>
