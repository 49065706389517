export const translations = {
    "network_title": 'แฟนตาซี สปอร์ตส์',
    "profile_header": "โปรไฟล์ของฉัน",
    "profile_airtime_won": "แอร์ไทม์ที่ได้",
    "profile_play": "เล่น",
    "profile_currency_acronym": 'R',
    "profile_total_season_points_header": "คะแนนฤดูกาลทั้งหมด",
    "profile_rounds_entered_header": "รอบที่เข้าร่วมทั้งหมด",
    "profile_top_hundred_header": "จบ 20 อันดับแรก",
    "profile_top_two_hundred_result": "{0}",
    "profile_auto_team_builder_button": "ตัวสร้างทีมอัตโนมัติ",
    "profile_auto_team_builder_popup_one": "เฮ้ โค้ช! ",
    "profile_auto_team_builder_popup_two": 'สัปดาห์นี้ยุ่งเกินกว่าจะตัดสินใจอะไรยากๆ ได้สินะ ให้ผู้ช่วยโค้ชมาช่วยคุณด้วยการใช้ตัวสร้างทีม',
    "profile_top_managers_header": "ผู้จัดการตัวท็อป",
    "profile_my_top_picks_header": "ตัวเลือกยอดนิยมของฉัน",
    "footer_profile_icon": "โปรไฟล์",
    "footer_top_picks_icon": "ตัวเลือกยอดนิยม",
    "footer_play_icon": "เล่น",
    "footer_my_rounds_icon": "รอบของฉัน",
    "footer_trophy_icon": "ผลลัพธ์",
    "sidebar_menu_how_to_play_icon": "วิธีเล่น",
    "sidebar_menu_trophy_icon": "รางวัล",
    "sidebar_menu_terms_conditions_icon": "ข้อตกลงและเงื่อนไข",
    "sidebar_menu_more_games_title": "เกมอื่นๆ",
    "sidebar_menu_rugby_icon": 'แฟนตาซี รักบี้',
    "sidebar_menu_predictor_icon": "ตัวทายผล",
    "top_picks_header": "ตัวเลือกยอดนิยม",
    "upcoming_rounds_header_one": "รอบ: ",
    "upcoming_rounds_header_two": "เข้าร่วมและชิงชัย",
    "upcoming_rounds_play_button": "เล่น",
    "upcoming_fixtures_header_title": "กำหนดการที่จะมาถึง",
    "upcoming_fixtures_view_games_title": "ดูเกมต่างๆ",
    "my_rounds_header": "รอบของฉัน",
    "my_rounds_complete_team": "จัดทีมให้เสร็จ",
    "my_rounds_upcoming_fixtures_header": "กำหนดการที่จะมาถึง",
    "my_rounds_view_games_title": "ดูเกมต่างๆ",
    "team_builder_header": "ตัวสร้างทีม",
    "team_builder_matches_dropdown_header_matches": "แมตช์",
    "team_builder_matches_dropdown_header_round": "รอบ",
    "team_builder_forward_position_label": "กองหน้า",
    "team_builder_midfield_position_label": "กองกลาง",
    "team_builder_defender_position_label": "กองหลัง",
    "team_builder_goalkeeper_position_label": "ผู้รักษาประตู",
    "team_builder_start_button": "เริ่ม",
    "team_builder_submit_button": "ส่ง",
    "team_builder_captain_popup_header": "เลือกกัปตันเพื่อรับแต้มสองเท่า",
    "team_builder_captain_popup_submit_button": 'ส่ง',
    "team_builder_popup_cancel_button": 'ยกเลิก',
    "team_builder_auto_team_builder_button": "ตัวสร้างทีมอัตโนมัติ",
    "team_selection_header": "ตัวสร้างทีม",
    "team_selection_select_position": "เลือก {0}",
    "team_selection_forward_position_label": "กองหน้า",
    "team_selection_midfield_position_label": "กองกลาง",
    "team_selection_defender_position_label": "กองหลัง",
    "team_selection_goalkeeper_position_label": "ผู้รักษาประตู",
    "team_selection_table_column_rank_label": "อันดับ",
    "team_selection_table_column_player_label": "ผู้เล่น",
    "team_selection_table_column_previous_round_label": "รอบก่อนหน้า",
    "team_selection_table_column_total_points_label": "คะแนนรวม",
    "team_selection_table_column_average_points_label": "คะแนนเฉลี่ย",
    "team_selection_next_button_label": "ต่อไป",
    "active_round_play_button_label": "เล่น",
    "active_round_edit_team_button_label": "แก้ไขทีม",
    "my_results_header_title": "ผลลัพธ์ของฉัน",
    "my_results_play_circle": "เล่น",
    "my_results_winnings_header": "แอร์ไทม์ที่ได้ทั้งหมด",
    "my_results_airtime_won_vertical_tag": 'ผู้ชนะ',
    "my_results_airtime_won_currency_acronym": 'R',
    "my_results_airtime_won_label": 'แอร์ไทม์',
    "my_results_table_round_column_header": 'รอบ',
    "my_results_table_position_column_header": 'ตำแหน่ง',
    "my_results_table_points_column_header": 'คะแนน',
    "my_results_table_airtime_column_header": 'แอร์ไทม์',
    "round_leaderboard_header_title": "กระดานผู้นำ",
    "round_leaderboard_table_position_column_header": 'ตำแหน่ง',
    "round_leaderboard_table_user_name_column_header": 'ชื่อผู้ใช้',
    "round_leaderboard_table_highest_rank_column_header": 'อันดับสูงสุด',
    "round_leaderboard_table_total_points_column_header": 'คะแนนรวม',
    "round_leaderboard_my_position_circle": 'ตำแหน่งของฉัน',
    "auto_team_builder_header_banner": 'ตัวสร้างทีมอัตโนมัติ',
    "auto_team_builder_header_description": 'ยุ่งเกินกว่าจะเลือกตัวในแฟนตาซี ฟุตบอลใช่ไหม?',
    "auto_team_builder_how_it_works_header": "วิธีใช้",
    "auto_team_builder_how_it_works_bullet_point_one": "สมัครรับการเลือกทีมอัตโนมัติ",
    "auto_team_builder_how_it_works_bullet_point_two": 'เราจะใช้ฟีเจอร์การเลือกอัตโนมัติเพื่อส่งทีมเข้าแข่งในทุกรอบของแพลตฟอร์ม',
    "auto_team_builder_how_it_works_bullet_point_three": 'เราจะสุ่มเลือกผู้เล่น 5 คนในทุกสัปดาห์เข้าทีมที่จะส่งลงเล่นให้คุณ',
    "auto_team_builder_how_it_works_bullet_point_four": 'คุณจะได้เข้าร่วมในทุกรอบหรือเฉพาะรอบด่วนปัจจุบันเท่านั้น',
    "auto_team_builder_how_it_works_bullet_point_five": "หากคุณต้องการแก้ไขชุดผู้เล่นลงสนาม คุณสามารถเปลี่ยนทีมก่อนเริ่มรอบได้เสมอ",
    "auto_team_builder_selection_button": "เพิ่มการเลือกทีมอัตโนมัติ",
    "auto_team_builder_selection_button_remove": "ลบการเลือกทีมอัตโนมัติ",
    "auto_team_builder_back_button": "กลับ",
    "settings_header": 'การตั้งค่า',
    "settings_change_username_button": 'เปลี่ยนชื่อผู้ใช้',
    "settings_change_notifications_button": 'แจ้งเตือน',
    "settings_change_cancel_package_button": 'ยกเลิกแพ็คเกจ',
    "username_header": 'เปลี่ยนชื่อผู้ใช้ของคุณ',
    "username_explanation_content": 'โปรดป้อนชื่อผู้ใช้ใหม่อย่างที่คุณอยากให้ปรากฏบนกระดานผู้นำ',
    "username_current_username": 'ชื่อผู้ใช้ปัจจุบัน:',
    "username_input_placeholder": 'ป้อนชื่อผู้ใช้ใหม่',
    "username_min_max_character": 'ชื่อผู้ใช้ต้องมีตัวเลขหรือตัวอักษรรวมกันระหว่าง 6-14 อักขระ',
    "username_change_username_button": 'เปลี่ยนชื่อผู้ใช้',
    "username_back_button": 'กลับ',
    "notifications_header": 'การแจ้งเตือน',
    "notifications_manage": 'ควบคุมการแจ้งเตือนของคุณ',
    "notifications_checkbox": 'ข้อความจากทีมบริการ',
    "notifications_service_updates_message": 'ฉันต้องการรับข่าวสารใหม่ๆ เกี่ยวกับบริการหรือโปรโมชันที่มีอยู่',
    "notifications_save_button": 'บันทึก',
    "notifications_back_button": 'กลับ',
    "unsubscribe_header": 'ยกเลิกแพ็คเกจ',
    "unsubscribe_status": 'ขณะนี้คุณได้สมัครรับข้อมูลจาก แฟนตาซี สปอร์ตส์ เรียบร้อยแล้ว',
    "unsubscribe_confirm_unsubscription": 'ฉันยืนยันว่าฉันต้องการยกเลิกแพ็คเกจของฉันกับ แฟนตาซี สปอร์ตส์',
    "unsubscribe_confirm_button": 'ยืนยัน',
    "unsubscribe_back_button": 'กลับ',
    "login_header": 'ยินดีต้อนรับ',
    "login_main_fantasy": 'แฟนตาซี สปอร์ตส์',
    "login_info": 'ลุ้นรับรางวัลเป็น iPhone 13 Pro ใส่หมายเลขโทรศัพท์ที่ด้านล่างเพื่อเริ่มเล่น',
    "login_input_placeholder": 'หมายเลขโทรศัพท์',
    "login_button": 'เริ่มเล่น',
    "login_price_data": 'ราคา: 2 ETB/วัน',
    "login_trial_period": 'ระยะเวลาทดลองเล่นฟรี: 3 วัน',
    "login_below_button": 'เลือกทีมของคุณจากสุดยอดผู้เล่นในยุโรปและแอฟริกา!',
    "login_subscribe_link_one": 'หากต้องการสมัคร คลิก',
    "login_subscribe_link_two": 'หรือส่งข้อความ “OK” ไปที่ 9401',
    "login_unsubscribe_link": 'วิธียกเลิกการสมัคร ให้ส่งข้อความ “STOP” ไปที่ 9401',
    "login_faq": 'คำถามที่พบบ่อย',
    "login_terms_conditions": 'ข้อตกลงและเงื่อนไข',
    "otp_input_placeholder": 'ป้อนรหัส OTP',
    "otp_button": 'ยืนยัน',
    "otp_not_received": 'ฉันไม่ได้รับรหัส OTP',
    "otp_resend": "ส่งรหัส OTP อีกครั้ง",
    "login_terms_conditions_one": 'นี่เป็นบริการแบบสมัครสมาชิกและ',
    "login_terms_conditions_two": 'ข้อตกลงและเงื่อนไข',
    "login_terms_conditions_three": 'จะมีผลใช้งาน',
    "how_to_play_header": 'วิธีเล่น:',
    "how_to_play_general_header": 'ทั่วไป',
    "how_to_play_general_info_point_one": 'Fanclash Contests เป็นเกมวัดทักษะและต้องใช้ทั้งทักษะกับความรู้ในข้อมูลกีฬาระดับอาชีพตลอดจนถึงกฎเกณฑ์ของ Fanclash ให้คุณได้สะสมคะแนนแฟนตาซี;',
    "how_to_play_general_info_point_two": 'คะแนนแฟนตาซีจะรวบรวมผ่านความสามารถในการเล่นจริงของนักกีฬาจากการแข่งกีฬาในหลายแมตช์การแข่งขัน;',
    "how_to_play_general_info_point_three": 'แต่ละชุดผู้เล่นแฟนตาซีจะต้องมีนักกีฬาจริงอยู่ด้วย ซึ่งหมายความว่าในการเข้าร่วม Fanclash Contest แต่ละครั้งจะต้องใช้ผู้เล่นที่มีการแข่งขันกีฬาจริงอย่างน้อยหนึ่งแมตช์',
    "how_to_play_general_info_point_four": 'Fanclash ห้ามเป็นอย่างยิ่งไม่ให้มีการชนะการแข่งขันโดยอิงตามผลการเล่นของนักกีฬาเพียงรายเดียวเท่านั้น;',
    "how_to_play_general_info_point_five": 'เว็บไซต์นี้จะป้องกันไม่ให้คุณบันทึกชุดผู้เล่นที่ฝ่าฝืนข้อห้ามเหล่านี้โดยอัตโนมัติ;',
    "how_to_play_general_info_point_six": 'จะไม่มีการแลกเปลี่ยนตัวผู้เล่น ไม่มีการชดเชยพิเศษ และไม่มีการเปลี่ยนตัวผู้เล่นที่บาดเจ็บหรืออยู่ตำแหน่งสำรอง;',
    "how_to_play_contest_rules_header": 'กฎการแข่งขัน',
    "how_to_play_contest_rules_point_one": 'ในแต่ละการแข่งขันจะต้องเลือกกองหน้าหนึ่งคน กองกลางสองคน และกองหลังหนึ่งคน (ประกอบด้วยผู้เล่นในรายชื่อผู้เล่นตัวรับตามผู้บอกค่าสถิติในทีมที่กำหนด) และผู้รักษาประตู',
    "how_to_play_contest_rules_point_two": 'จำนวนผู้เล่นจะประกอบด้วยผู้เล่นที่ผู้บอกค่าสถิติ (Statistics Provider) มีค่าสถิติสำหรับในลีกเฉพาะนั้นๆ และกำหนดว่าเข้าเกณฑ์สำหรับแมตช์ที่มีในการแข่งขันหรือไม่;',
    "how_to_play_contest_rules_point_three": 'ผู้เล่นใดก็ตามที่ออกจากชุดทีมใดๆ ตามที่กำหนดโดยผู้บอกค่าสถิติ (ผู้เล่น “หายไป”) จะได้รับการเพิ่มเข้ามาเมื่อผู้บอกค่าสถิติได้รับทราบและ/หรือเพิ่มผู้เล่นใหม่ และทาง Fanclash จะไม่มีส่วนรับผิดใดๆ จากการที่ผู้เล่นไม่ปรากฏในการแข่งขันใดก็ตาม;',
    "how_to_play_contest_rules_point_four": 'เมื่อการแข่งขันเริ่มต้น ค่าจ้างผู้เล่นจะไม่เปลี่ยนแปลงแม้ว่าอาจมีความผันผวนในแต่ละการแข่งขันตามผลงานและความนิยมของผู้เล่น;',
    "how_to_play_contest_rules_point_five": 'ในแต่ละการแข่งขันจะมีการระบุวันที่เอาไว้ ซึ่งจากนั้นแล้วจะไม่สามารถเพิ่ม แก้ไข หรือยกเลิกทีมที่ส่งได้;',
    "how_to_play_contest_rules_point_six": 'ผลการแข่งขันและผู้ชนะจะคิดตามคะแนนรวมที่ได้จากแต่ละชุดทีมของผู้ที่ส่งเข้าร่วม (วิธีการคิดคะแนนจะมีสรุปไว้ที่ด้านล่าง) ในระหว่างระยะเวลาการแข่งขัน;',
    "how_to_play_contest_rules_point_seven": 'หากมีการเลื่อนกำหนดการแมตช์ของการแข่งขันไปอยู่นอกระยะเวลาเริ่มต้นและสิ้นสุดการแข่งขันที่กำหนดไว้แต่แรก ผู้เล่นในแมตช์เหล่านั้นจะได้ศูนย์คะแนน',
    "how_to_play_edit_lineup_header": 'การแก้ไขชุดทีม',
    "how_to_play_edit_lineup_info": 'คุณสามารถแก้ไขชุดทีมของคุณได้ทุกเมื่อจนกว่าจะเริ่มการแข่งขันโดยการคลิกที่ตัวเลือกแก้ไขในมุมมองเข้าแข่งขัน อย่างไรก็ตาม เมื่อการแข่งขันเริ่มขึ้น ชุดทีมของคุณจะไม่สามารถแก้ไขได้อีกและคุณจะได้เห็นชุดทีมของคู่แข่ง',
    "how_to_play_entry_fees_header": 'ค่าเข้าร่วม',
    "how_to_play_entry_fees_info": 'มีบางการแข่งขันที่จะไม่มีค่าเข้าร่วม สมาชิกของบริการ Fanclash จะมีสิทธิ์เข้าร่วมการแข่งขันรายวันและรายสัปดาห์ตราบใดที่การสมัครสมาชิกยังไม่หมดอายุ',
    "how_to_play_entry_cancellation_header": 'การยกเลิกการเข้าร่วมแข่งขัน',
    "how_to_play_entry_cancellation_info": 'Fanclash ขอสงวนสิทธิ์ในการยกเลิกเกมใดก็ตามก่อนที่จะถึงกำหนดการเริ่มต้น เมื่อมีหลายเกมที่มีเงื่อนไขการเข้าร่วมเดียวกัน (เช่นที่รวมถึงแต่ไม่จำกัดเพียง เกมการแข่งขันจริง ขนาดจำนวนที่คาดการณ์ ฯลฯ) เข้าข่ายที่จะยกเลิกเนื่องด้วยจำนวนผู้เข้าแข่งขันน้อยเกินไป Fanclash อาจเลี่ยงการยกเลิกโดยการรวมจำนวนผู้เล่นจากเกมเหล่านั้นเข้าไว้ด้วยกันได้ อย่างไรก็ตาม Fanclash จะไม่มีทางรวมจำนวนผู้เล่นที่มีเงื่อนไขการเข้าร่วมแตกต่างกันโดยเด็ดขาด;',
    "how_to_play_cancel_contests_header": 'การยกเลิกการแข่งขัน',
    "how_to_play_cancel_contests_info": 'Fanclash ขอสงวนสิทธิ์ในการยกเลิกการแข่งขันตามดุลยพินิจของตนเองโดยไม่คำนึงถึงข้อจำกัดใดๆ ทั้งสิ้น ซึ่งกรณีนี้จะเกิดขึ้นเฉพาะเมื่อเราเชื่อว่าจะเกิดผลกระทบในวงกว้างต่อบูรณภาพของการแข่งขันเนื่องด้วยปัญหาของไซต์หรือเหตุการณ์ในชีวิตจริงที่ส่งผลกระทบต่อการแข่งขัน',
    "how_to_play_contest_term_header": 'เงื่อนไขการแข่งขัน',
    "how_to_play_contest_term_info": 'การแข่งขันจะจัดขึ้นแบบวันเดียว หลายวัน หรือนานกว่านั้นตามที่ระบุในกติกาของแต่ละการแข่งขัน ผู้ชนะจะตัดสินตามเกณฑ์ที่ระบุไว้ในกติกาของเกม',
    "how_to_play_squads_header": 'ชุดทีม',
    "how_to_play_squads_rules_point_one": 'ชุดทีม จะประกอบไปด้วยผู้เล่น 11 (สิบเอ็ด) คนหรือน้อยกว่านั้นขึ้นอยู่กับจำนวนผู้เล่นในชุดทีมตั้งรับที่กำหนด อาจมีการกำหนดข้อจำกัดอื่นๆ ในกติกาการแข่งขันเฉพาะเป็นบางรายการ',
    "how_to_play_squads_rules_point_two": 'ตำแหน่งในการเลือกผู้เล่นจะจำกัดอยู่ที่: ผู้รักษาประตู (GK), ตัวรับ (D), กองกลาง (MF) และกองหน้า (F) Fanclash มีกฎพิเศษเฉพาะในการกำหนดเกณฑ์ตำแหน่ง และผู้เล่นแต่ละคนจะมีได้เพียง ตำแหน่งเดียว จากทั้งหมดสี่ตำแหน่งในรายการข้างต้น',
    "how_to_play_game_cancel_header": 'การยกเลิกและการเลื่อนกำหนด',
    "how_to_play_game_cancel_info": 'ในกรณีที่มีการยกเลิกเกมการแข่งขันจริง เลื่อนไปแข่งก่อนวันที่กำหนด (“เลื่อนเข้า”), หรือเลื่อนออกไปด้วยเหตุผลใดก็ตาม จะไม่มีการให้คะแนนแก่ผู้เล่นจากกรณีที่มีการยกเลิก เลื่อนเข้า หรือเลื่อนเกมการแข่งขันออกไป หรือเกิดเหตุการณ์ใดก็ตาม',
    "how_to_play_prizes_header": 'รางวัล',
    "how_to_play_contest_prizes_point_one": 'Fanclash มีการแข่งขันหลากหลายรูปแบบ ในแต่ละประเภทนั้น Fanclash จะประกาศรางวัลในข้อเสนอเมื่อเริ่มต้นการแข่งขัน;',
    "how_to_play_contest_prizes_point_two": 'ของรางวัลจะไม่เปลี่ยนไปตามจำนวนผู้เข้าแข่งขันหรือค่าเข้าร่วมทั้งหมดในการแข่งขันนั้นๆ;',
    "how_to_play_contest_prizes_point_three": `รางวัลจะมอบให้กับผู้เข้าแข่งขันที่สะสมคะแนนแฟนตาซีได้มากที่สุดตามที่กำหนดในรายละเอียดการแข่งขัน หลังจากที่การแข่งขันนั้นๆ สิ้นสุดลง Fanclash จะไม่ตัดสินผู้ชนะจากแต้ม ส่วนต่างคะแนน หรือผลงานของทีมจริงหรือทีมรวมของผู้เล่นต่างๆ;`,
    "how_to_play_contest_prizes_point_four": `เมื่อผู้เข้าร่วมชนะการแข่งขัน รางวัลจะส่งให้ถึงลูกค้าโดยตรงในรูปแบบของแอร์ไทม์ สำหรับรางวัลอื่นนอกเหนือจากแอร์ไทม์ เราจะติดต่อหาลูกค้าเป็นการส่วนตัวตามข้อตกลงและเงื่อนไขสำหรับรางวัลเฉพาะนั้นๆ`,
    "how_to_play_contest_prizes_point_five": 'Fanclash อาจสามารถให้บริการเกมแบบ “ฟรี” ด้วยวัตถุประสงค์เพื่อความบันเทิง โดยไม่มีหน้าที่รับผิดชอบในการแจกของรางวัลต่างๆ ซึ่งจะมีการระบุไว้อย่างชัดเจนตั้งแต่แรกเริ่ม;',
    "how_to_play_contest_prizes_point_six": 'การคำนวณรางวัลจะคิดตามผลของการแข่งขันเมื่อ Fanclash คิดอันดับคะแนนสุดท้าย',
    "how_to_play_contest_prizes_point_seven": 'คะแนนที่ได้จะคิดตามค่าสถิติจากข้อมูลที่ได้รับจากผู้บอกค่าสถิติภายนอกของเรา (“ผู้บอกค่าสถิติหรือ Statistics Provider”) แม้ว่า Fanclash จะใช้ความพยายามอย่างถึงที่สุดเพื่อให้แน่ใจถึงบริการที่ดีได้มาตรฐาน แต่ทางเราได้อาศัยเพียงข้อมูลที่ได้จากผู้บอกค่าสถิติเท่านั้นและจะไม่รับผิดชอบต่อความผิดพลาดของข้อมูลที่ได้รับจากผู้บอกค่าสถิติ',
    "how_to_play_contest_prizes_point_eight": `คุณยินยอมที่จะไม่เอาผิดเรียกร้องใดๆ ต่อ Fanclash ทั้งทางตรงและทางอ้อมเนื่องด้วยความผิดพลาดของข้อมูลที่ได้รับจาก Fanclash ซึ่งมาจากผู้บอกค่าสถิติ;`,
    "how_to_play_contest_prizes_point_nine": 'Fanclash จะรอเป็นระยะเวลาหนึ่งเพื่อยืนยันผู้ชนะการแข่งขันหลังจากที่เกมสุดท้ายในการแข่งขันสิ้นสุดลงเพื่อให้ผู้บอกค่าสถิติสามารถแก้ไขข้อผิดพลาดใหญ่ๆ ในระบบก่อนได้;',
    "how_to_play_contest_prizes_point_ten": 'โปรดทราบว่าเมื่อมอบรางวัลให้แก่ผู้ชนะแล้ว ผลการคิดคะแนนจะไม่เปลี่ยนแปลงแม้ว่าจะมีการแก้ไขอย่างเป็นทางการจากลีกกีฬาอาชีพก็ตาม',
    "how_to_play_contest_prizes_point_eleven": 'ซึ่งส่วนนี้ไม่ใช่สถานการณ์เดียวกันกับกรณีที่จำเป็นต้องมีการแก้ไขหลังการคิดคะแนนตัดสินเนื่องด้วยปัญหาจากกระบวนการคิดคะแนนตัดสิน ในกรณีเหล่านี้ กระบวนคิดคะแนนตัดสิน (รวมถึงการมอบรางวัลหรือให้แอร์ไทม์) อาจมีการย้อนกลับแล้วคิดตัดสินเกมใหม่ให้ถูกต้อง',
    "how_to_play_contest_prizes_point_twelve": 'ของรางวัลไม่อาจทดแทนด้วยสิ่งอื่นหรือถ่ายโอนได้ ภาษีทั้งหมดที่เกี่ยวข้องกับการรับหรือใช้ของรางวัลถือเป็นความรับผิดชอบของผู้ชนะแต่เพียงผู้เดียว หากเจ้าหน้าที่ทางกฎหมายเป็นผู้ชนะรางวัลการแข่งขัน Fanclash อาจใช้วิจารณญาณของตนเพื่อตัดสินว่าจะมอบของรางวัลดังกล่าวให้ได้หรือไม่',
    "how_to_play_contest_prizes_point_thirteen_point_header": 'เสมอกัน',
    "how_to_play_contest_prizes_point_thirteen_point_one": 'หากเกิดผลเสมอ/คะแนนเท่ากันในการแข่งขันใดเป็นการเฉพาะระหว่างผู้ใช้ Fanclash ของรางวัลจะแบ่งให้กับผู้เล่นที่ได้ผลเสมอกันเป็นจำนวนเท่าๆ กัน;',
    "how_to_play_contest_prizes_point_thirteen_point_two": 'ในกรณีที่ได้ผลเสมอกันระหว่างผู้เล่นที่ไม่สามารถแบ่งของรางวัลกระจายเท่าๆ กันได้ จะใช้ผลคะแนนนักกีฬาที่ทำคะแนนเดี่ยวได้สูงสุดเป็นตัวตัดสิน ตามมาด้วยผู้เล่นอันดับสอง อันดับสามต่อจากอันดับสูงสุดหากจำเป็นต้องหาผลตัดสินชี้ขาด ในกรณีที่ยังไม่สามารถวัดผลชี้ขาดได้ จะประกาศให้ผู้เล่นที่ส่งรายชื่อทีมก่อนเป็นผู้ชนะ',
    "how_to_play_notifications_header": 'แจ้งเตือน',
    "how_to_play_notifications_info": 'โดยทั่วไปแล้วผู้ชนะจะได้รับ SMS ภายในหนึ่งวันทำการ คุณยอมรับที่จะให้ชื่อผู้ใช้และประเทศตามตำแหน่งที่ตั้งของคุณเผยแพร่ได้ในกรณีที่คุณเป็นผู้ชนะ เราจะขอให้ผู้ชนะตอบกลับทางอีเมลหรือจดหมายเพื่อส่งหนังสือรับรองสถานภาพพำนักและแบบฟอร์มการเสียภาษีให้ถูกต้อง หากผู้ชนะไม่ปฏิบัติตามคำขอนี้อาจส่งผลให้ตกรอบได้ หากการแจ้งเตือนรับรางวัลส่งกลับมาด้วยสถานะไม่สามารถส่งได้ อาจส่งผลให้ตกรอบและมีการคัดเลือกผู้ชนะรายอื่นแทน สามารถยื่นขอรายชื่อผู้ชนะแบบลายลักษณ์อักษรในแต่ละรอบระยะเวลาการแข่งขันได้ที่: Fanclash: hello@thefanclash.com',
    "how_to_play_point_scoring_header": 'การคิดคะแนน',
    "how_to_play_point_scoring_system_header": `ระบบการคิดคะแนนของ แฟนตาซี สปอร์ตส์`,
    "how_to_play_point_scoring_system_game_action": 'เกม/แอ็คชัน',
    "how_to_play_point_scoring_system_daily_fantasy_league": `แฟนตาซี สปอร์ตส์ รายวัน`,
    "how_to_play_point_scoring_system_point": 'คะแนน',
    "how_to_play_point_scoring_system_points": 'คะแนน',
    "how_to_play_point_scoring_system_minutes_played_label": 'เล่นถึงหกสิบนาที',
    "how_to_play_point_scoring_system_attack_header": 'ทำเกมบุก',
    "how_to_play_point_scoring_system_attack_forward_goal_scored": 'ทำประตูด้วยกองหน้า',
    "how_to_play_point_scoring_system_attack_midfielder_goal_scored": 'ทำประตูด้วยกองกลาง',
    "how_to_play_point_scoring_system_attack_defender_goal_scored": 'ทำประตูด้วยกองหลัง',
    "how_to_play_point_scoring_system_attack_assist": 'ช่วยเหลือ',
    "how_to_play_point_scoring_system_attack_target_shot": 'ยิงเข้ากรอบ',
    "how_to_play_point_scoring_system_attack_penalty_missed": 'เตะลูกโทษพลาด/รับได้',
    "how_to_play_point_scoring_system_defending_header": 'ป้องกัน/ความถนัด',
    "how_to_play_point_scoring_system_defending_pass_intercept": 'แย่งลูกส่ง',
    "how_to_play_point_scoring_system_defending_tackle_won": 'ชนะการปะทะ',
    "how_to_play_point_scoring_system_defending_clean_sheet": 'ไม่เสียแต้ม (กองหลัง)',
    "how_to_play_point_scoring_system_midfielder_clean_sheet": 'ไม่เสียแต้ม (กองกลาง)',
    "how_to_play_point_scoring_system_defending_goal_against": 'ทำประตู (กองหลัง)',
    "how_to_play_point_scoring_system_defending_own_goal": 'เข้าประตูตัวเอง',
    "how_to_play_point_scoring_system_defending_yellow_card": 'ใบเหลือง',
    "how_to_play_point_scoring_system_defending_red_card": 'ใบแดง',
    "how_to_play_point_scoring_system_goalkeeper_header": 'ผู้รักษาประตู',
    "how_to_play_point_scoring_system_goalkeeping_save": 'เซฟลูก',
    "how_to_play_point_scoring_system_goalkeeping_penalty_save": 'เซฟลูกจุดโทษ',
    "how_to_play_point_scoring_system_goalkeeping_clean_sheet": 'ไม่เสียแต้ม',
    "how_to_play_point_scoring_system_goalkeeping_goal_conceded": 'ทำประตู',
    "prizes_header": 'รางวัล:',
    "prizes_promo_info": `ลุ้นโอกาสรับของรางวัลใหญ่สุดน่าทึ่งเมื่อคุณเล่น แฟนตาซี สปอร์ตส์!`,
    "prizes_grand_prize_header": 'รางวัลใหญ่ - ทีมไร้ที่ติ',
    "prizes_grand_prize_currency_amount": 'R50 000.00',
    "prizes_grand_prize_how_it_works_header": 'กติกา:',
    "prizes_grand_prize_how_it_works_point_one": 'ทุกครั้งที่คุณเลือกตัวและส่งทีมเข้าร่วม คุณมีโอกาสได้รับรางวัล R50,000.00!',
    "prizes_grand_prize_how_it_works_point_two": 'หากทีมของคุณได้คะแนนสูงที่สุดในแต่ละตำแหน่งของรอบ คุณจะได้ปลดล็อก ทีมไร้ที่ติ',
    "prizes_first_place_header": 'รางวัลอันดับ 1',
    "prizes_first_place_airtime_amount": '฿10 แอร์ไทม์',
    "prizes_second_tenth_place_header": 'รางวัลอันดับ 2 - 10',
    "prizes_second_place_airtime_amount": '฿10 แอร์ไทม์',
    "change_captain_team_builder_button": "เปลี่ยนกัปตัน",
    "subscription_header": "ยืนยันการสมัคร",
    "subscription_confirm": "ฉันยืนยันการสมัครของฉันที่อัตรา 2 ETB/วัน",
    "subscription_manage": "สามารถจัดการส่วนนี้ในภายหลังได้ภายในการตั้งค่าโปรไฟล์ของคุณ",
    "subscribe_button": "สมัคร",
    "subscription_terms_conditions": `เมื่อคลิกที่ 'สมัคร' ถือว่าฉันยอมรับในข้อตกลงและเงื่อนไขของ แฟนตาซี สปอร์ตส์`,
    "upcoming_rounds_entered_all_rounds": 'คุณได้เข้าร่วมในรอบที่จะมาถึงทั้งหมดแล้ว ไปที่ รอบของฉัน เพื่อดูทีมที่เข้าร่วม',
    "upcoming_rounds_entered_no_rounds": 'คุณยังไม่ได้เข้าร่วมในรอบใดเลย ไปที่ เล่น เพื่อสร้างทีมของคุณ',
    "round_leaderboard_table_player_username_default": 'ผู้เล่น',
    "team_builder_successfully_submitted_one": 'ขอบใจนะโค้ช! คุณได้ส่งทีมของคุณสำเร็จแล้ว',
    "team_builder_successfully_submitted_two": "แฟนตาซี สปอร์ตส์",
    "team_builder_successfully_submitted_my_rounds_button": 'ไปที่ รอบของฉัน',
    "settings_season_points_header": "คะแนนฤดูกาล",
    "invalid_otp": 'รหัส OTP ไม่ถูกต้อง',
    "expired_otp": 'รหัส OTP ของคุณหมดอายุแล้ว โปรดคลิกที่ "ส่งรหัส OTP อีกครั้ง" เพื่อขอรับรหัส OTP ชุดใหม่',
    "otp_limit": 'คุณกรอกรหัสไม่ถูกต้องสองครั้ง โปรดคลิกที่ "ส่งรหัส OTP อีกครั้ง" เพื่อขอรับรหัส OTP ชุดใหม่',
    "otp_used": 'รหัส OTP นี้ใช้งานไปแล้ว โปรดคลิกที่ "ส่งรหัส OTP อีกครั้ง" เพื่อขอรับรหัส OTP ชุดใหม่',
    "login_info_incorrect_msisdn": 'ป้อนไม่ถูกต้อง เนื่องจากต้องมีเพียงตัวเลขเท่านั้น',
    "login_info_incorrect_msisdn_length": 'ความยาวตัวเลขไม่ถูกต้อง'
}
