import {encryptionKeyMap} from "@/brand/encryptionKeys";
import {brandConfigObjectMap} from "@/brand/brandConfig";
import {termsAndConditionsMap} from "@/brand/termsAndConditions";

export const brandConfigMap = new Map();

brandConfigMap.set('http://localhost:8080', getBrandConfig('vc-za-en'));
brandConfigMap.set('http://localhost:8081', getBrandConfig('mtn-nga-en'));
brandConfigMap.set('default', getBrandConfig('vc-za-en'));
brandConfigMap.set('https://fantasysoccer.vodacomfantasy.com', getBrandConfig('vc-za-en'));
brandConfigMap.set('https://staging.fantasysoccer.vodacomfantasy.com', getBrandConfig('vc-za-en'));
brandConfigMap.set('https://cg.mtnfantasyfootball.com', getBrandConfig('mtn-cg-fr'));
brandConfigMap.set('https://ci.mtnfantasyfootball.com', getBrandConfig('mtn-ci-fr'));
brandConfigMap.set('https://cm.mtnfantasyfootball.com', getBrandConfig('mtn-cmr-fr'));
brandConfigMap.set('https://demo.fantasy.penrosegaming.com', getBrandConfig('saudi-uae-en'));
brandConfigMap.set('https://ais.fantasy.penrosegaming.com', getBrandConfig('ais-tai-en'));
brandConfigMap.set('https://dtac.fantasy.penrosegaming.com', getBrandConfig('dtac-tai-tha'));
brandConfigMap.set('https://ng.mtnfantasygames.com', getBrandConfig('mtn-nga-en'));
brandConfigMap.set('https://www.mtn-ffootball.com', getBrandConfig('mtn-zam-en'));
brandConfigMap.set('https://mtn-ffootball.com', getBrandConfig('mtn-zam-en'));
brandConfigMap.set('https://ng.airtel-fantasygames.com', getBrandConfig('air-nga-en'));
brandConfigMap.set('https://ethiofantasy.com', getBrandConfig('ethiotel-et-en'));
brandConfigMap.set('https://th.championshipmanagers.com', getBrandConfig('ais-tai-tha'));

export function getBrandConfig(brand) {
    const brandConfigObject = brandConfigObjectMap.get(brand);
    return {
        brand,
        revenuePartnerId: brandConfigObject.revenuePartnerId,
        isRandomDrawAwarded: brandConfigObject.isRandomDrawAwarded,
        tabTitle: brandConfigObject.tabTitle,
        userAccessId: brandConfigObject.userAccessId,
        encryptionKey: encryptionKeyMap.get(brand),
        termsAndConditionsMap: termsAndConditionsMap.get(brand),
        googleTag: brandConfigObject.googleTag,
        productId: brandConfigObject.productId,
    }
}
