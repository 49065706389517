export const translations = {
    "network_title": 'Vodacom Fantasy League',
    "profile_header": "My Profile",
    "profile_airtime_won": "AIRTIME WON",
    "profile_play": "PLAY",
    "profile_currency_acronym": 'R',
    "profile_total_season_points_header": "TOTAL SEASON POINTS",
    "settings_season_points_header": "SEASON POINTS",
    "profile_rounds_entered_header": "ROUNDS ENTERED",
    "profile_top_hundred_header": "TOP 100 FINISHES",
    "profile_top_two_hundred_result": "{0}",
    "profile_auto_team_builder_button": "Auto team builder",
    "profile_auto_team_builder_popup_one": "Hey Coach! ",
    "profile_auto_team_builder_popup_two": "Too busy to make the tough choice this week? Get some help from your assistant coach by using Team Builder",
    "profile_top_managers_header": "TOP MANAGERS",
    "profile_my_top_picks_header": "MY TOP PICKS",
    "footer_profile_icon": "Profile",
    "footer_top_picks_icon": "Top Picks",
    "footer_play_icon": "Play",
    "footer_my_rounds_icon": "My Rounds",
    "footer_trophy_icon": "Results",
    "sidebar_menu_how_to_play_icon": "how to play",
    "sidebar_menu_trophy_icon": "prizes",
    "sidebar_menu_terms_conditions_icon": "t's & c's",
    "sidebar_menu_more_games_title": "More Games",
    "sidebar_menu_rugby_icon" : "fantasy rugby",
    "sidebar_menu_predictor_icon": "predictor",
    "sidebar_menu_freeplay_predictor_icon": "freeplay predictor",
    "top_picks_header": "Top Picks",
    "upcoming_rounds_header_one" : "Rounds: ",
    "upcoming_rounds_header_two" : "Enter and Win",
    "upcoming_rounds_play_button": "Play",
    "upcoming_fixtures_header_title": "Upcoming Fixtures",
    "upcoming_fixtures_view_games_title": "view games",
    "my_rounds_header": "My Rounds",
    "my_rounds_complete_team": "COMPLETE TEAM",
    "my_rounds_upcoming_fixtures_header": "Upcoming Fixtures",
    "my_rounds_view_games_title" : "view games",
    "team_builder_header": "Team Builder",
    "team_builder_matches_dropdown_header_matches": "Matches",
    "team_builder_matches_dropdown_header_round": "Round",
    "team_builder_forward_position_label": "Forward",
    "team_builder_midfield_position_label": "Midfield",
    "team_builder_defender_position_label": "Defender",
    "team_builder_goalkeeper_position_label": "Goalkeeper",
    "team_builder_start_button": "Start",
    "team_builder_submit_button": "Submit",
    "team_builder_captain_popup_header": "Select a captain to earn DOUBLE POINTS from them",
    "team_builder_captain_popup_submit_button": 'Submit',
    "team_builder_popup_cancel_button": 'Cancel',
    "team_builder_auto_team_builder_button": "Auto Team Builder",
    "team_selection_header": "Team Builder",
    "team_selection_select_position": "Select {0}",
    "team_selection_forward_position_label": "Forward",
    "team_selection_midfield_position_label": "Midfielder",
    "team_selection_defender_position_label": "Defense",
    "team_selection_goalkeeper_position_label": "Goalkeeper",
    "team_selection_table_column_rank_label": "Rank",
    "team_selection_table_column_player_label": "Player",
    "team_selection_table_column_previous_round_label": "Previous Round",
    "team_selection_table_column_total_points_label": "Total Points",
    "team_selection_table_column_average_points_label": "Average Points",
    "team_selection_next_button_label": "Next",
    "active_round_play_button_label": "Play",
    "active_round_edit_team_button_label": "Edit Team",
    "my_results_header_title": "My Results",
    "my_results_play_circle": "PLAY",
    "my_results_winnings_header": "Total Airtime Won",
    "my_results_airtime_won_vertical_tag" : 'WINNER',
    "my_results_airtime_won_currency_acronym": 'R',
    "my_results_airtime_won_label": 'Airtime',
    "my_results_table_round_column_header": 'Round',
    "my_results_table_position_column_header": 'Position',
    "my_results_table_points_column_header": 'Points',
    "my_results_table_airtime_column_header": 'Airtime',
    "round_leaderboard_header_title" : "Leaderboard",
    "round_leaderboard_table_position_column_header": 'Position',
    "round_leaderboard_table_user_name_column_header": 'Username',
    "round_leaderboard_table_highest_rank_column_header": `No. Top 10's`,
    "round_leaderboard_table_total_points_column_header": 'Total Points',
    "round_leaderboard_my_position_circle": 'My Position',
    "auto_team_builder_header_banner" : 'Auto Team Builder',
    "auto_team_builder_header_description": "Too busy to make your Vodacom Fantasy Football picks?",
    "auto_team_builder_how_it_works_header": "How it works",
    "auto_team_builder_how_it_works_bullet_point_one": "Sign up for auto team selection",
    "auto_team_builder_how_it_works_bullet_point_two": "We will use the auto select feature to enter a team into every round on the platform",
    "auto_team_builder_how_it_works_bullet_point_three": "We will randomly select 5 players every week as a team that we will enter for you",
    "auto_team_builder_how_it_works_bullet_point_four": "You will be entered into all rounds or only the immediate round",
    "auto_team_builder_how_it_works_bullet_point_five": "If you want to edit your line-up you can always change the team before the round starts",
    "auto_team_builder_selection_button": "Add Auto Team Selection",
    "auto_team_builder_selection_button_remove": "Remove Auto Team Selection",
    "auto_team_builder_back_button": "Back",
    "settings_header": 'Settings',
    "settings_change_username_button": 'Change Username',
    "settings_change_notifications_button": 'Notifications',
    "settings_change_cancel_package_button": 'Cancel Package',
    "username_header": 'Change Your Username',
    "username_explanation_content": 'Please enter a new username how you would like to be identified on our leaderboards',
    "username_current_username": 'Current Username:',
    "username_input_placeholder": 'Enter New Username',
    "username_min_max_character" : 'Username must be a mixture of alphabetical characters ranging from 6-14 characters',
    "username_change_username_button": 'Change Username',
    "username_back_button" : 'Back',
    "username_invalid" : 'Error: Invalid Username',
    "notifications_header": 'Notifications',
    "notifications_manage": 'Manage your notifications',
    "notifications_checkbox": 'Service messages',
    "notifications_service_updates_message": 'I would like to receive updates about our service or any promotions we run',
    "notifications_save_button": 'Save',
    "notifications_back_button" : 'Back',
    "unsubscribe_header": 'Cancel Package',
    "unsubscribe_status": 'You are currently subscribed to Vodacom Fantasy League',
    "unsubscribe_confirm_unsubscription": ' I confirm that I would like to cancel my package with Vodacom Fantasy League',
    "unsubscribe_confirm_button": 'Confirm',
    "unsubscribe_back_button" : 'Back',
    "login_header": 'Welcome to',
    "login_main_fantasy": 'Fantasy Football',
    "login_info": 'Stand a chance to WIN AIRTIME and other GREAT PRIZES. Enter your cell number below to start playing',
    "login_input_placeholder" : 'Cellphone number',
    "login_button": 'Start Playing',
    "login_below_button": 'Pick your team from the best players in Europe and Africa!',
    "login_terms_conditions": 'This is a subscription service and Terms and Conditions apply',
    "otp_input_placeholder" : 'Enter OTP',
    "otp_button": 'Confirm',
    "otp_not_received": 'I did not receive my OTP',
    "otp_resend": "Resend OTP",
    "how_to_play_header": 'How To Play:',
    "how_to_play_general_header": 'General',
    "how_to_play_general_info_point_one": 'Fanclash Contests is a game of skill and require some skill and knowledge of professional sports information as well as the Fanclash rules from You to accumulate fantasy points;',
    "how_to_play_general_info_point_two": 'Fantasy points are accumulated through the performance of real-life athletes across multiple sporting matches;',
    "how_to_play_general_info_point_three": 'Each fantasy line-up must include real-life athletes, so that each entry into a Fanclash Contest shall be made up of players drawn from a minimum of one real-life sporting matches.',
    "how_to_play_general_info_point_four": 'Fanclash strictly prohibits the winning of any Contest based solely on the single performance of an individual athlete;',
    "how_to_play_general_info_point_five": 'The site will automatically prevent You from saving a line-up that is in violation of any of these restrictions;',
    "how_to_play_general_info_point_six": 'There will be no trading of players, no extra moves and no replacing injured or benched players;',
    "how_to_play_contest_rules_header": 'Contest Rules',
    "how_to_play_contest_rules_point_one": 'Each Contest requires selecting one striker, two midfielders, one defensive unit (consisting of players listed as defensive players by the stats provider in a specific team) and a goal keeper.',
    "how_to_play_contest_rules_point_two": 'The player pool consists of players that the Statistics Provider has statistics for in that particular league and determines to be eligible for the matches included in the Contest;',
    "how_to_play_contest_rules_point_three": 'Any players left off any squad by the Statistics Provider (“missing” players) will only be added once the Statistics Provider recognizes and/or adds the new player and Fanclash shall not be liable in any way for such player not being featured in any Contest;',
    "how_to_play_contest_rules_point_four": 'Once a Contest starts, player salaries will not change, however it can fluctuate from Contest to Contest based on the player’s performance and popularity;',
    "how_to_play_contest_rules_point_five": 'Each Contest specifies a date after which entries may not be added, edited, or cancelled;',
    "how_to_play_contest_rules_point_six": 'Contest results and winners will be based on the total points scored across each entrant’s squad (scoring summarized below) during the duration of the Contest;',
    "how_to_play_contest_rules_point_seven": 'If a match that is part of a Contest is rescheduled outside of the originally scheduled beginning and end of the Contest, players in those matches will earn zero points.',
    "how_to_play_edit_lineup_header": 'Editing a Line-up',
    "how_to_play_edit_lineup_info": 'You can edit Your line-up at any point up to the start of a Contest by clicking on the edit option on the entry view. HOWEVER, once a Contest starts, your line-up will no longer be editable and you will be able to see your opponents’ line-up.',
    "how_to_play_entry_fees_header": 'Entry Fees',
    "how_to_play_entry_fees_info": 'There are no entry fees for the specific contests. Members of the Fanclash service will have the ability to enter into weekly and daily contests as long as their subscription to the content is active.',
    "how_to_play_entry_cancellation_header": 'Entry Cancellation',
    "how_to_play_entry_cancellation_info": 'Fanclash reserves the right to cancel any Game before it is scheduled to begin. When multiple Games with the same entry terms (such as but not limited to real–life games, anticipated pool size etc) are subject to cancellation due to the number of Contestants participating being too low, Fanclash may avoid cancellation by combining the player pools for those Games. HOWEVER Fanclash will never merge player pools with different entry terms;',
    "how_to_play_cancel_contests_header": 'Cancelling Contests',
    "how_to_play_cancel_contests_info": 'Fanclash reserves the right to cancel Contests at its discretion, without any restrictions. This would typically only be done where we believe there would be a widespread impact on the integrity of Contests due to problems on the site or real-life events impacting on the Contests.',
    "how_to_play_contest_term_header": 'Contest Term',
    "how_to_play_contest_term_info": 'Contests will take place on a single day, multiple days or longer as specified in each Contest’s rules. Winners are determined by the criteria stated in the game rules.',
    "how_to_play_squads_header": 'Squads',
    "how_to_play_squads_rules_point_one": 'Squads will consist of 11 (eleven) players or less dependent on the number of players in a specific squads defensive unit. Further restrictions may be specified in the particular Contest rules.',
    "how_to_play_squads_rules_point_two": 'The positions to choose players in are limited to: Goalkeeper (GK), Defensive Unit (D), Midfielder (MF) and Forward (F). Fanclash makes the exclusive ruling on position eligibility and each player is only eligible at ONE of the four positions listed above.',
    "how_to_play_game_cancel_header": 'Cancellations & Postponements',
    "how_to_play_game_cancel_info": 'In the event that a scheduled real-life game is cancelled, moved to an earlier date (“pre-empted”), or postponed for any reason, no points will be awarded for players in the cancelled, pre-empted, or postponed game or event.',
    "how_to_play_prizes_header": 'Prizes',
    "how_to_play_contest_prizes_point_one": 'Fanclash offers many different types of Contests. For each one, Fanclash announces the prizes on offer at the start of the contest;',
    "how_to_play_contest_prizes_point_two": 'Prizes are not subject to change based on the number of Contestants or total entry fees for that Contest;',
    "how_to_play_contest_prizes_point_three": 'Prizes are awarded to Contestants who accumulate the most fantasy points as detailed in the Contest details, after the particular Contest has ended. Fanclash will never determine winners based on the score, point-spread, or performance of any real-life team or combination of teams;',
    "how_to_play_contest_prizes_point_four": 'When a Contestant wins a Contest, the Prize will be awarded directly to the customer in the form of airtime. With prizes other than airtime customers will be contacted in person in accordance with the terms and condition attached to that specific prize.',
    "how_to_play_contest_prizes_point_five": 'Fanclash may offer “free” game play for entertainment purposes, without the possibilities of a Prize or Prizes being awarded. This will be clearly indicated at the outset;',
    "how_to_play_contest_prizes_point_six": 'Prize calculations are based on Contests’ results as at the time when Fanclash charts final point scoring.',
    "how_to_play_contest_prizes_point_seven": 'Points scored are based on the statistics from the data feed provided by our third party statistics provider (“Statistics Provider”). Although Fanclash uses its best endeavours to ensure a good reputable Service, it is solely reliant on the information provided by the Statistics Provider and is in no way responsible for faulty data received from the Statistics Provider.',
    "how_to_play_contest_prizes_point_eight": 'You hereby indemnify Fanclash against any and all claims, arising directly or indirectly due to faulty data received by Fanclash from its Statistics Provider;',
    "how_to_play_contest_prizes_point_nine": 'Fanclash waits for a certain period to elapse, to confirm the winner(s) of a Contest after the final game in the Contest is completed so that the Statistics Provider can correct any major errors in its system first;',
    "how_to_play_contest_prizes_point_ten": 'Note that once Prizes are awarded to the winners, scoring results will not be changed regardless of any official adjustments made by professional sports leagues.',
    "how_to_play_contest_prizes_point_eleven": 'This is not the same situation as a case where a correction needs to be made after settlement, due to a problem with the settlement process. In these cases, the settlement process (including prize awarding or airtime provisioning) may be reversed and games resettled correctly.',
    "how_to_play_contest_prizes_point_twelve": 'Prizes may not be substituted or transferred. All taxes associated with the receipt or use of any Prize is the sole responsibility of the winner. If any legal authority Contests Prize awards, Fanclash may, in its sole discretion, determine whether or not to award such Prizes.',
    "how_to_play_contest_prizes_point_thirteen_point_header": 'Ties',
    "how_to_play_contest_prizes_point_thirteen_point_one": 'If there is a tie/draw in a specific Contest among Fanclash users, the Prize will be divided equally between all tied players;',
    "how_to_play_contest_prizes_point_thirteen_point_two": 'In the event of a tie between players where the Prize(s) cannot be equally split the entry with the highest individually scoring athlete will be used as a tiebreaker, proceeding to the second-highest, third-highest, etc. player as needed to break the tie. In the event the tie remains unbroken, the player who submitted their team first will be declared the winner.',
    "how_to_play_notifications_header": 'Notifications',
    "how_to_play_notifications_info": 'Winners are generally advised by SMS within one business day. You hereby agree to Your Username and country location being published in the event that You are a winner. Winners may be asked to return via e-mail or regular mail an affidavit of eligibility and appropriate tax forms. A winner’s failure to comply with such requests can result in disqualification. Any Prize notification returned as undeliverable may result in disqualification and selection of an alternate winner. A list of winners for each competition period may be obtained by written request to: Fanclash: hello@thefanclash.com',
    "how_to_play_point_scoring_header": 'Point Scoring',
    "how_to_play_point_scoring_system_header": 'Fantasy League Scoring System',
    "how_to_play_point_scoring_system_game_action": 'Game/Action',
    "how_to_play_point_scoring_system_daily_fantasy_league": 'Daily Fantasy League',
    "how_to_play_point_scoring_system_point": 'point',
    "how_to_play_point_scoring_system_points": 'points',
    "how_to_play_point_scoring_system_minutes_played_label": 'Sixty Minutes Played',
    "how_to_play_point_scoring_system_attack_header": 'Attacking',
    "how_to_play_point_scoring_system_attack_forward_goal_scored": 'Goal Scored By Forward',
    "how_to_play_point_scoring_system_attack_midfielder_goal_scored": 'Goal Scored By Midfielder',
    "how_to_play_point_scoring_system_attack_defender_goal_scored": 'Goal Scored By Defender',
    "how_to_play_point_scoring_system_attack_assist": 'Assist',
    "how_to_play_point_scoring_system_attack_target_shot": 'Shot On Target',
    "how_to_play_point_scoring_system_attack_penalty_missed": 'Penalty Kick Missed/Saved',
    "how_to_play_point_scoring_system_defending_header": 'Defending/Discipline',
    "how_to_play_point_scoring_system_defending_pass_intercept": 'Pass Intercepted',
    "how_to_play_point_scoring_system_defending_tackle_won": 'Winning Tackle',
    "how_to_play_point_scoring_system_defending_clean_sheet": 'Clean Sheet (Defenders)',
    "how_to_play_point_scoring_system_midfielder_clean_sheet": 'Clean Sheet (Midfielders)',
    "how_to_play_point_scoring_system_defending_goal_against": 'Goal Against (Defenders)',
    "how_to_play_point_scoring_system_defending_own_goal": 'Own Goal',
    "how_to_play_point_scoring_system_defending_yellow_card": 'Yellow Card',
    "how_to_play_point_scoring_system_defending_red_card": 'Red Card',
    "how_to_play_point_scoring_system_goalkeeper_header": 'Goalkeepers',
    "how_to_play_point_scoring_system_goalkeeping_save": 'Save',
    "how_to_play_point_scoring_system_goalkeeping_penalty_save": 'Penalty Kick Save',
    "how_to_play_point_scoring_system_goalkeeping_clean_sheet": 'Clean Sheet',
    "how_to_play_point_scoring_system_goalkeeping_goal_conceded": 'Goal Against',
    "prizes_header": 'Prizes:',
    "prizes_promo_info": 'Stand a chance to WIN AIRTIME and other GREAT PRIZES when you play Vodacom Fantasy League!',
    "prizes_grand_prize_header": 'Grand Prize - Perfect Pick',
    "prizes_grand_prize_currency_amount": 'R50 000.00',
    "prizes_grand_prize_how_it_works_header": 'How it works:',
    "prizes_grand_prize_how_it_works_point_one": 'Everytime you pick & enter a team you stand a chance to win R50 000.00!',
    "prizes_grand_prize_how_it_works_point_two": 'If your team scores the highest points in each position for the round you will unlock the Perfect Pick',
    "prizes_first_place_header": '1st - 100th Prize',
    "prizes_first_place_airtime_amount": 'R10.00 airtime',
    "change_captain_team_builder_button": "Change Captain",
    "subscription_header": "Confirm Subscription",
    "subscription_confirm": "I confirm my subscription of R3.00 p/day (or a lesser amount).",
    "subscription_manage": "This can later be managed within your profile settings.",
    "subscribe_button": "Subscribe",
    "subscription_terms_conditions": "By clicking 'Subscribe' I agree to the Terms and Conditions of Fantasy Football",
    "upcoming_rounds_entered_all_rounds" : 'You have entered all upcoming rounds. To view entered teams go to My Rounds',
    "upcoming_rounds_entered_no_rounds" : 'You haven\'t entered any rounds yet. To build your team, go to Play',
    "round_leaderboard_table_player_username_default" : 'Player',
    "team_builder_successfully_submitted_one": 'Thanks coach! Your team has been successfully submitted on',
    "team_builder_successfully_submitted_two": 'Fantasy League',
    "team_builder_successfully_submitted_my_rounds_button": 'Go to My Rounds',
    'sidebar_menu_terms_soccer_news': 'soccer news',
    "invalid_otp": 'Invalid OTP',
    "expired_otp": 'Your OTP has expired, please click "Resend OTP" to receive a new OTP.',
    "otp_limit": 'You have made two incorrect attempts, please click "Resend OTP" to receive a new OTP.',
    "otp_used": 'This OTP has already been used, please click "Resend OTP" to receive a new OTP.',
    "login_info_incorrect_msisdn": 'Input invalid, must contain only numbers.',
    "login_info_incorrect_msisdn_length": 'Length of number is incorrect.'
}
