<template>
  <div class="d-flex p-3 header-navbar">
    <div class="justify-content-start col-9 col-sm-9 fantasy-header">
      <img :src="require(`../../brand/${brandConfig.brand}/assets/main-fantasy-header.png`)">
    </div>
    <div class="d-flex justify-content-end col-3 settings-menu-icons">
      <div v-if="brandConfig.brand === 'ethiotel-et-amh' || brandConfig.brand === 'ethiotel-et-en'"
           class="d-flex justify-content-center align-items-center border-1 primary-bg-colour language">
        <span @click="onLanguageClicked" class="secondary-text"
              v-if="this.brandConfig.brand === 'ethiotel-et-en' || this.brandConfig.brand === 'ethiotel-et-amh'">{{
            getLanguage()
          }}</span>
      </div>
      <div v-if="brandConfig.brand === 'dtac-tai-tha' || brandConfig.brand === 'dtac-tai-en'"
           class="d-flex justify-content-center align-items-center border-1 primary-bg-colour language">
        <span @click="onLanguageClicked" class="primary-text"
              v-if="this.brandConfig.brand === 'dtac-tai-en' || this.brandConfig.brand === 'dtac-tai-tha'">{{
            getLanguage()
          }}</span>
      </div>
      <img @click="settingsIconClicked" src="../../assets/settings-gear-wheel.png">
      <div v-if="menuIconVisible" @click="menuIconCLicked">
        <img src="../../assets/menu-icon.png">
      </div>
      <div v-if="!menuIconVisible" @click="menuIconCLicked" class="w3 center w3-animate-top">
        <img src="../../assets/header-cancel-icon.png">
      </div>
    </div>
  </div>
  <transition name="slide-burger-menu" v-if="this.brandConfig.brand">
    <div v-if="showMenu">
      <SidebarMenu/>
    </div>
  </transition>
</template>

<script>
import SidebarMenu from "./SidebarMenu";
import {mapState} from "vuex";
import {setNewBrandConfigLanguage} from "@/functions/setBrandConfig";

export default {
  name: "Header",
  components: {SidebarMenu},
  data() {
    return {
      showMenu: false,
      menuIconVisible: true,
      language: undefined
    }
  },
  computed: {
    ...mapState(['brandConfig'])
  },
  methods: {
    menuIconCLicked() {
      this.showMenu = !this.showMenu;
      this.menuIconVisible = !this.menuIconVisible;
    },
    settingsIconClicked() {
      this.$router.push('/settings');
    },
    getSubstringAfterSecondHyphon() {
      var laungauge;
      var textArray = this.brandConfig.brand.split('-')
      laungauge = textArray.slice(2).join('-').trim()
      return laungauge;
    },
    onLanguageClicked() {
      setNewBrandConfigLanguage(this.brandConfig.brand);
    },
    getLanguage() {
      return this.getSubstringAfterSecondHyphon().toUpperCase()
    }
  },
  beforeMount() {
    return this.getSubstringAfterSecondHyphon().toUpperCase()
  },
  watch: {
    $route(to, from) {
      if (this.$route.path === '/how-to-play' || this.$route.path === '/prizes') {
        this.menuIconVisible = true
        this.showMenu = false;
      }
    }
  }
}
</script>

<style scoped>
.language {
  margin-left: 5px;
  padding: 5px;
  margin-right: 10px;
}

.header-navbar {
  background-color: #ffffff;
}

.fantasy-header img {
  margin-left: 15px;
  width: 220px;
}

.settings-menu-icons img {
  margin-right: 15px;
  height: 30px;
  width: 30px;
}

.slide-burger-menu-leave-active, .slide-burger-menu-enter-active {
  transition: 1s;
  z-index: 1;
}

.slide-burger-menu-enter-from {
  transform: translate(-100%, 0);
}

.slide-burger-menu-leave-to {
  transform: translate(-100%, 0);
}


</style>
