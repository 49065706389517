<template>
  <div class="d-flex header row justify-content-center" v-if="!$route.meta.hideNavBar">
    <Header v-if="this.brandConfig && user"/>
  </div>
  <div class="d-flex row justify-content-center content-body" v-bind:class="`content-body-${this.bodyState}`">
    <LoadingOverlay class="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-3" v-if="this.isLoading"/>
    <router-view class="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-3"></router-view>
  </div>
  <div class="d-flex row justify-content-center" v-if="!$route.meta.hideFooterMenu">
    <Footer v-if="user" class="d-flex col col-12 col-sm-9 col-md-7 col-lg-6 col-xl-4 primary-text"/>
  </div>
</template>

<script>
import Header from "./views/components/Header";
import Footer from "./views/components/Footer";
import {mapState, mapMutations} from "vuex";
import {setBrandConfig} from "@/functions/setBrandConfig";
import LoadingOverlay from "@/views/components/LoadingOverlay";

export default {
  components: {LoadingOverlay, Header, Footer},
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'user', 'headerEnrichmentAttempted'])
  },
  data() {
    return {
      bodyState: 'default'
    }
  },

  async beforeMount() {
    setBrandConfig();
    this.setFavicon();
    this.setTabName();
    this.injectGoogleTags();
    if (this.brandConfig.brand === 'vc-za-en') this.injectTealium();
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setUserStats', 'setUser']),

    setFavicon() {
      const favicon = document.getElementById("favicon");
      favicon.href = require(`./brand/${this.brandConfig.brand}/assets/favicon.png`)
    },
    setTabName() {
      document.title = this.brandConfig.tabTitle;
    },
    injectGoogleTags() {
      this.createScriptTag()
      this.pushGoogleTag();
    },
    createScriptTag() {
      const script = document.createElement('script');
      script.setAttribute('async', '');
      script.setAttribute(
          'src',
          `https://www.googletagmanager.com/gtag/js?id=${this.brandConfig.googleTag}`,
      );
      document.head.appendChild(script);
    },
    pushGoogleTag() {
      window.dataLayer = window.dataLayer || [];

      function gtag() {
        window.dataLayer.push(arguments);
      }

      gtag('js', new Date());
      gtag('config', this.brandConfig.googleTag);
    },
    injectTealium() {
      (function (a, b, c, d) {
        a = 'https://tags.tiqcdn.com/utag/vodafone/za-nxtvl-main/prod/utag.js';
        b = document;
        c = 'script';
        d = b.createElement(c);
        d.src = a;
        d.type = 'text/java' + c;
        d.async = true;
        a = b.getElementsByTagName(c)[0];
        a.parentNode.insertBefore(d, a);
      })();
    }
  },
  watch: {
    $route(to, from) {
      if (this.$route.path === '/team-builder' || this.$route.path === '/login' || this.$route.path === '/otp' || this.$route.path === '/subscription') {
        this.bodyState = 'noHeader'
      } else if (this.$route.path === '/team-selector') {
        this.bodyState = 'teamBuilderPlayers'
      } else this.bodyState = 'default';
    }
  }
};
</script>

<style>

Header {
  background-color: #ffffff;
  height: 60px;
  width: 80vw;
  position: fixed;
  top: 0;
}

.content-body-default {
  position: absolute;
  height: auto;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 75px;
  z-index: -2;
}

.content-body-noHeader {
  position: absolute;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  z-index: -2;
}

Footer {
  position: fixed;
  bottom: 0;
  border-radius: 15px 15px 0 0;
  background-color: #000000;
  height: 75px;
  color: white;
  font-size: 12px;
}
</style>
