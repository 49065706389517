<template>
  <div>
    <div class="d-flex flex-row align-items-center pb-2 fixed-container">
      <img class="pt-3" :src="require(`../../brand/${this.brandConfig.brand}/assets/main-fantasy-header.png`)">
    </div>
    <EthiotelEnglishFAQs class="ts-and-cs-wrapper" v-if="this.brandConfig.brand === 'ethiotel-et-en'"/>
    <EthiotelAmharicFAQs class="ts-and-cs-wrapper" v-if="this.brandConfig.brand === 'ethiotel-et-amh'"/>
  </div>
</template>

<script>
import EthiotelAmharicFAQs from "@/views/config/frequentlyAskedQuestions/EthiotelAmharicFAQs";
import EthiotelEnglishFAQs from "@/views/config/frequentlyAskedQuestions/EthiotelEnglishFAQs";
import {mapState} from "vuex";


export default {
  name: "FrequentlyAskedQuestions",
  components: {
    EthiotelAmharicFAQs,
    EthiotelEnglishFAQs
  },
  computed: {
    ...mapState(['brandConfig'])
  },

}
</script>

<style scoped>
.ts-and-cs-wrapper {
  padding: 0 2rem;
}

.fixed-container {
  position: fixed;
  top: 0;
}

.fixed-container img {
  width: 300px;
}
</style>
