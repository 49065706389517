<template>
  <div>
    <div class="d-flex flex-row justify-content-start pt-2 pb-2 text-center header-text">
      {{ translationsFormatter('prizes_header') }}
    </div>
    <div class="p-2 text-center promo-text">
      {{ translationsFormatter('prizes_promo_info') }}
    </div>
    <div class="d-flex flex-column col-12 grand-prize-container text-center primary-bg-colour primary-text pb-4">
      <img class="trophy-img" :src="require('@/assets/prize-trophy.png')">
      <div class="mt-3 grand-prizes-header col-">Grand prize - Season Champions</div>
      <div class="cash-total-text col-6">R100 000.00</div>
      <div class="d-flex flex-column align-items-center  col-12">
        <div class="horizontal-line mt-3 mb-2 col-10"></div>
      </div>
      <div class="fw-bold how-it-works-text col-6">How it works:</div>
      <div class="d-flex flex-column align-items-center col-12">
        <div class="d-flex flex-row align-items-center text-start col-10 prize-breakdown-container mt-2 mb-2">
          <img class="prize-icon-img" :src="require('@/assets/prize-podium.png')">
          <div class="prize-info-text">Compete in as many rounds as you can during the season to increase your overall standing & better your chances of winning.</div>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center col-12">
        <div class="d-flex flex-row align-items-center text-start col-10 prize-breakdown-container mt-2 mb-2">
          <img class="prize-icon-img" :src="require('@/assets/prize-pig.png')">
          <div class="prize-info-text">Points can be accumulated by partaking in any & all leagues during the season.</div>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center col-12">
        <div class="d-flex flex-row align-items-center text-start col-10 prize-breakdown-container mt-2 mb-2">
          <img class="prize-icon-img" :src="require('@/assets/prizes-cash.png')">
          <div class="prize-info-text">Stand to be 1 of 5 winners walking away with R20 000 cash each.</div>
        </div>
      </div>
    </div>
    <div class=" d-flex flex-row col-12 mt-3 pt-2 pb-2 secondary-bg-colour primary-text align-items-center prize-container">
      <img class="prizes-place-icon" :src="require('@/assets/prizes-first.png')"/>
      <div class="col-10 d-flex flex-column">
        <div class=" first-place-header-text">{{ translationsFormatter('prizes_first_place_header') }}</div>
        <div class="grand-prize-amount">{{ translationsFormatter('prizes_first_place_airtime_amount') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VodacomEnglishPrizes",
}
</script>

<style scoped>
.header-text {
  font-size: 13px;
  font-weight: bold;
}

.trophy-img {
  position: absolute;
  top: 16px;
  left: 10px;
  width: 30px;
}

.promo-text {
  font-weight: 700;
  font-size: 15px;
  line-height: 1.2;
}

.grand-prize-container {
  position: relative;
  border-radius: 10px;
}

.grand-prizes-header {
  font-weight: 600;
  font-size: 15px;
  padding-left: 20px;
}

.grand-prize-container {
  position: relative;
}

.grand-prize-amount {
  font-size: 14px;
}

.cash-total-text {
  font-size: 12px;
  margin-left: 7px;
}

.how-it-works-text {
  font-size: 12px;
  margin-left: 5px;
}

.first-place-header-text {
  font-weight: 600;
  font-size: 14px;
}

.prize-container {
  border-radius: 10px;
  height: max-content;
}

.prizes-place-icon {
  margin-left: 12px;
  margin-right: 21px;
  height: 40px;
}

table {
  border: 1px solid black;
}

.horizontal-line {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  padding: 0;
}

.prize-breakdown-container {
  margin-left: 20px;
}

.prize-icon-img {
  width: 30px;
}

.prize-info-text {
  font-size: 11px;
  line-height: 1.2;
  margin-left: 5px;
}
</style>
