<template>
  <div class="pb-3 user-stats-container" v-if="!isLoading">
    <div v-if="this.$route.path === '/settings'">
      <div class="pb-1 settings-header">
        {{ translationsFormatter('settings_header') }}
      </div>
      <div class="d-flex align-items-center justify-content-center my-winnings-container">
        <img :src="require(`../../brand/${brandConfig.brand}/assets/main-banner.png`)" class="main-banner">
        <div @click="routeToPlay()" class="text-center my-profile-play-circle">
          <div class="my-profile-play-circle-text primary-text"><strong>{{ translationsFormatter('profile_play') }}</strong>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-around text-center p-3">
        <div
            class="d-flex flex-column flex-wrap col-4 total-season-points-container primary-bg-colour primary-text justify-content-center">
          <div class="p-1 total-season-points-text">
            {{ translationsFormatter('settings_season_points_header') }}
          </div>
          <div class="flex-wrap pt-2 total-season-points-results">
            {{ userStats.totalSeasonPoints }}
          </div>
        </div>
        <div
            class="d-flex flex-column flex-wrap col-4 rounds-entered-container primary-bg-colour primary-text justify-content-center">
          <div class="rounds-entered-text p-1">
            {{ translationsFormatter('profile_rounds_entered_header') }}
          </div>
          <div class=" pt-2 rounds-entered-results">
            {{ userStats.roundsPreviouslyEntered }}
          </div>
        </div>
        <div
            class="d-flex col-4 data-won-container primary-bg-colour primary-text d-flex flex-wrap flex-column justify-content-center">
          <div class="data-won-text p-1">
            {{ translationsFormatter('profile_top_hundred_header') }}
          </div>
          <div class="data-won-results">
            {{ userStats.totalTopHundred }}
          </div>
        </div>
      </div>
      <div class="d-flex flex-column text-center pt-1 pb-3 m-1 edit-profile-container">
        <button @click="changeUsernameButtonClicked" class="primary-text secondary-bg-colour edit-profile-button">
          {{ translationsFormatter('settings_change_username_button') }}
        </button>
        <button @click="notificationsButtonClicked" class="primary-text secondary-bg-colour edit-profile-button">
          {{ translationsFormatter('settings_change_notifications_button') }}
        </button>
        <button @click="cancelPackageButtonClicked" class="primary-text secondary-bg-colour edit-profile-button">
          {{ translationsFormatter('settings_change_cancel_package_button') }}
        </button>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import UserDataService from "@/services/userDataService";

export default {
  name: "UserSettings",
  data() {
    return {
      userStats: {},
      settingsVisible: true,
    }
  },
  async beforeMount() {
    this.setIsLoading(true);
    const userStatsResponse = await UserDataService.getUserStats(this.user.msisdn);
    this.userStats = userStatsResponse.data.userStats;
    this.setIsLoading(false);
  },
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'user'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setUser']),
    changeUsernameButtonClicked() {
      this.$router.push('/settings/username');
      this.settingsVisible = false;
    },
    notificationsButtonClicked() {
      this.$router.push('/settings/notifications');
      this.settingsVisible = false;
    },
    cancelPackageButtonClicked() {
      this.$router.push('/settings/unsubscribe');
      this.settingsVisible = false;
    },
    routeToPlay() {
      this.$router.push({path: '/upcoming-rounds'});
    },
  },
  watch: {
    $route(to, from) {
      if (this.$route.path === '/settings') {
        this.settingsVisible = true;
      }
    }
  }
}
</script>

<style scoped>
.user-stats-container {
  background-color: #e8e8e8;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 5vh;
  background-size: cover;
}

.settings-header {
  font-size: 14px;
  font-weight: 750;
  padding-left: 12px;
  padding-top: 10px;
}

.main-banner {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.my-winnings-container {
  position: relative;
}

.my-winnings-circle {
  position: absolute;
  z-index: 1;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #05a105;
  color: white;
}

.my-winnings-circle-airtime-amount-text {
  padding-top: 24px;
  font-size: 34px;
  font-weight: 700;
}


.my-winnings-circle-airtime-won-text {
  font-weight: 750;
  font-size: 12px;
}

.my-profile-play-circle {
  position: absolute;
  z-index: 1;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #05a105;
  color: white;
}

.my-profile-play-circle-text {
  font-size: 40px;
  margin-top: 25%;
}

.currency-acronym-text {
  position: absolute;
  left: 4%;
  top: 24%;
  font-weight: 700;
  font-size: 20px;
}


.total-season-points-container {
  height: 100px;
  margin-right: 7px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 10px;
}

.total-season-points-text, .rounds-entered-text, .data-won-text {
  height: 30%;
  font-size: 12px;
}

.total-season-points-results, .rounds-entered-results, .data-won-results {
  height: 50%;
  font-size: 30px;
}

.data-won-results {
  padding-top: 9px;
}

.rounds-entered-container {
  height: 100px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 10px;
}

.data-won-container {
  height: 100px;
  border-radius: 10px;
  margin-left: 7px;
  font-weight: bold;
  font-size: 10px;
}

.edit-profile-button {
  height: 50px;
  border-radius: 10px;
  margin-bottom: 15px;
  font-weight: 600;
  border: 0;
}

@media (max-height: 700px) {
  .edit-profile-button {
    height: 40px;
  }
}

@media (max-width: 410px) {
  .total-season-points-text, .rounds-entered-text, .data-won-text {
    font-size: 11px;
    margin-bottom: 15px;
  }

  .total-season-points-results, .rounds-entered-results, .data-won-results {
    margin-top: 5px;
    font-size: 20px;
  }

  .data-won-results {
    padding-top: 9px;
  }
}

</style>
