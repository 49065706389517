import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import {translationsFormatter} from "@/functions/translationsFormatter";
import Helpers from "@/helpers/helpers";

Array.prototype.asyncForEach = async function (callback, thisArg) {
    thisArg = thisArg || this;
    for (let i = 0, l = this.length; i !== l; ++i) {
        await callback.call(thisArg, this[i], i, this)
    }
};

const app = createApp(App);

app.config.globalProperties = {
    translationsFormatter,
    Helpers
};

app.use(store).use(router).mount('#app');
