<template>
  <div>
    <div class="pt-2 pb-2 text-center header-text">
      {{ translationsFormatter('prizes_header') }}
    </div>
    <div class="p-2 text-center promo-text">
      {{ translationsFormatter('prizes_promo_info') }}
    </div>
    <div class="mt-2">
      <table class="table table-bordered mt-2">
        <thead>
        <tr class="table-header secondary-bg-colour primary-text">
          <th scope="col">Prix</th>
          <th scope="col">Fréquence</th>
          <th scope="col">Montant</th>
          <th scope="col">Quantité</th>
          <th scope="col">Total</th>
        </tr>
        </thead>
        <tbody class="table-body primary-bg-colour">
        <tr>
          <th scope="row">Porte-monnaie</th>
          <td class="smaller-text">Mensuel (1 gagnant de 100 000 XAF)</td>
          <td class="smaller-text">XAF 100 000</td>
          <td>12</td>
          <td class="smaller-text">XAF 1200 000</td>
        </tr>
        <tr>
          <th scope="row">Données mobile</th>
          <td>Hebdomadaire (35 gagnants de 1000 XAF)</td>
          <td class="smaller-text">XAF 35 000</td>
          <td>52</td>
          <td class="smaller-text">XAF 1820 000</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "MtnCameroonPrizes",
  computed: {
    ...mapState(['brandConfig'])
  },
}
</script>

<style scoped>
.smaller-text {
  font-size: x-small;
}

.header-text {
  font-size: 1.2rem;
  font-weight: bold;
}

.promo-text {
  font-weight: 700;
  font-size: 15px;
}

.grand-prize-container {
  position: relative;
  border-radius: 10px;
  height: max-content;
  padding-left: 60px;
  padding-bottom: 15px;
}

.grand-prizes-header {
  font-weight: 600;
  font-size: 17px;
}

.grand-prize-amount {
  font-size: 14px;
}

.how-it-works-container {
  border-top: 1px solid #FFFFFF;
}

.how-it-works-text {
  font-weight: 600;
  font-size: 14px;
}

.trophy-icon {
  position: absolute;
  height: 40px;
  left: 4%;
  top: 10%;
}

.perfect-pick-explanation-text {
  padding-left: 5px;
  font-size: 11px;
}

.perfect-pick-icon {
  height: 28px;
}

.prize-container {
  border-radius: 10px;
  height: max-content;
}

.prizes-place-icon {
  margin-left: 12px;
  margin-right: 21px;
  height: 40px;
}

.table-header {
  font-size: 13px;
}

table {
  border: 1px solid black;
}

.table-body {
  font-size: 11px;
}

</style>
