<template>
  <div class="d-flex flex-column text-center body-container" v-if="!isLoading">
    <div class="fixed-container">
      <div
          class="d-flex flex-row justify-content-center align-items-center text-center team-builder-header primary-text secondary-bg-colour">
        <img class="back-button" src="../../assets/left-keyboard-arrow.png" @click="backButtonClicked">
        {{ translationsFormatter('team_selection_header') }}
      </div>
      <div
          class="d-flex flex-row justify-content-center align-items-center team-builder-select-position primary-text secondary-bg-colour">
        {{ translationsFormatter('team_selection_select_position', [this.roundSelected.position]) }}
      </div>
      <div class="d-flex flex-column align-items-center justify-content-center">
        <div class="flex-row align-items-center countdown-match-container secondary-bg-colour primary-text">
          <div class="col-3">
            <img class="countdown-whistle" src="../../assets/whistle-icon.png">
          </div>
          <div class="col-9 countdown-time-text">
            {{ -roundSelected.timeUntilRoundClose.days }}d:{{ roundSelected.timeUntilRoundClose.hours }}h
          </div>
        </div>
      </div>
      <div class="d-flex flex-column button-container">
        <div class="d-flex flex-row justify-content-around mb-3 squad-container">
          <div class="d-flex flex-column align-items-center col-3" @click="positionHeaderIconClicked('forward')">
            <img v-if="!this.roundSelected.selections.forward" src="../../assets/plus-icon.png">
            <img v-if="this.roundSelected.selections.forward" :src="require(`../../brand/${brandConfig.brand}/assets/user-icon.png`)">
            <div
                :class="this.roundSelected.selections.forward ? positionIconDynamicClassActive : positionIconDynamicClassInactive">
              {{ translationsFormatter('team_selection_forward_position_label') }}
            </div>
            <div v-if="this.roundSelected.selections.forward" class="circle">
              <div class="checkmark"></div>
            </div>
          </div>
          <div class="d-flex flex-column align-items-center col-3"
               @click="positionHeaderIconClicked('midfielder1')">
            <img v-if="!this.roundSelected.selections.midfielder1 && !this.roundSelected.selections.midfielder2"
                 src="../../assets/plus-icon.png">
            <img v-if="this.roundSelected.selections.midfielder1 || this.roundSelected.selections.midfielder2"
                 :src="require(`../../brand/${brandConfig.brand}/assets/user-icon.png`)">
            <div
                :class="this.roundSelected.selections.midfielder1 ? positionIconDynamicClassActive : positionIconDynamicClassInactive">
              {{ translationsFormatter('team_selection_midfield_position_label') }}
            </div>
            <div class="d-flex justify-content-center">
              <div v-if="this.roundSelected.selections.midfielder1" class="circle">
                <div class="checkmark"></div>
              </div>
              <div v-if="this.roundSelected.selections.midfielder2" class="circle">
                <div class="checkmark"></div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column align-items-center col-3" @click="positionHeaderIconClicked('defence')">
            <img v-if="!this.roundSelected.selections.defence" src="../../assets/plus-icon.png">
            <img v-if="this.roundSelected.selections.defence" :src="require(`../../brand/${brandConfig.brand}/assets/user-icon.png`)">
            <div
                :class="this.roundSelected.selections.defence ? positionIconDynamicClassActive : positionIconDynamicClassInactive">
              {{ translationsFormatter('team_selection_defender_position_label') }}
            </div>
            <div v-if="this.roundSelected.selections.defence" class="circle">
              <div class="checkmark"></div>
            </div>
          </div>
          <div class="d-flex flex-column align-items-center col-3"
               @click="positionHeaderIconClicked('goalkeeper')">
            <img v-if="!this.roundSelected.selections.goalkeeper" src="../../assets/plus-icon.png">
            <img v-if="this.roundSelected.selections.goalkeeper" :src="require(`../../brand/${brandConfig.brand}/assets/user-icon.png`)">
            <div
                :class="this.roundSelected.selections.goalkeeper ? positionIconDynamicClassActive : positionIconDynamicClassInactive">
              {{ translationsFormatter('team_selection_goalkeeper_position_label') }}
            </div>
            <div v-if="this.roundSelected.selections.goalkeeper" class="circle">
              <div class="checkmark"></div>
            </div>
          </div>
        </div>
        <div class="next-button" @click="nextButtonClicked(this.roundSelected.position)">
          <button class="primary-bg-colour primary-text col-6">{{ translationsFormatter('team_selection_next_button_label') }}</button>
        </div>
      </div>
    </div>
    <div class="scrollable-container">
      <table class="table-team-selection table-hover table-borderless mb-5">
        <thead>
        <tr class="table-header">
          <th scope="col">{{ translationsFormatter('team_selection_table_column_rank_label') }}</th>
          <th class="col-5" scope="col">{{ translationsFormatter('team_selection_table_column_player_label') }}</th>
          <th scope="col">{{ translationsFormatter('team_selection_table_column_previous_round_label') }}</th>
          <th scope="col">{{ translationsFormatter('team_selection_table_column_total_points_label') }}</th>
          <th scope="col">{{ translationsFormatter('team_selection_table_column_average_points_label') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr class="individual-row" style="height: 50px" v-for="(player, index) in this.playersOrDefences"
            :key=player.position @click="onPlayerClicked(player)">
          <td class="player-rank">{{ index + 1 }}</td>
          <td>
            <div class="d-flex flex-row">
              <img class="col-3" :src="this.findTeamLogo(player)">
              <div class="player-name">{{ player.name }}</div>
            </div>
          </td>
          <td>{{ player.roundPoints }}</td>
          <td>{{ player.points }}</td>
          <td>{{ player.averagePoints }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Helpers from "@/helpers/helpers";
import GameDataService from "@/services/gameDataService";
import {getSelectedPosition, setSelectedPosition, getSelectedRound, setSelectedRound} from "@/functions/roundSelection";
import {mapMutations, mapState} from "vuex";

export default {
  name: "TeamSelection",
  data() {
    return {
      playersOrDefences: undefined,
      positionIconDynamicClassActive: 'auto-team-builder-button primary-bg-colour primary-text',
      positionIconDynamicClassInactive: 'auto-team-builder-button secondary-bg-colour primary-text',
      fontColour: 'white',
    }
  },
  async beforeMount() {
    await this.setData();
  },
  watch: {},
  computed: {
    ...mapState(['isLoading', 'roundSelected', 'user', 'activeRounds', 'brandConfig'])
  },
  methods: {
    ...mapMutations(['setRoundHasFullTeam', 'updateActiveRound', 'setSelectedRound', 'setRoundsEntered', 'setIsLoading', 'setSelectedPositionInRound', 'setSelectedpositionIndexRound', 'setActiveRoundGoalkeeperPick', 'setActiveRoundForwardPick', 'setActiveRoundDefencePick', 'setActiveRoundMidfielderTwoPick', 'setActiveRoundMidfielderOnePick']),
    onPlayerClicked(player) {
      if (this.roundSelected.position === 'defence') this.onDefenceClicked(player);
      if (this.roundSelected.position === 'midfielder2' && !this.isMidfielderTwoSelectedSameAsOne(player)) this.onMidfielderTwoClicked(player);
      if (this.roundSelected.position === 'midfielder1' && !this.isMidfielderOneSelectedSameAsTwo(player)) this.onMidfielderOneClicked(player);
      if (this.roundSelected.position === 'forward') this.onForwardClicked(player);
      if (this.roundSelected.position === 'goalkeeper') this.onGoalkeeperClicked(player);
      setSelectedRound(this.roundSelected);
      this.isFullTeamSelected();
      this.updateActiveRound(this.roundSelected);
    },
    setPlayersData(player, position) {
      player.playerRef = player.ref;
      player.position = position;
      player.roundId = this.roundSelected.roundId;
      player.teamNames = position === "defence" ? [player.name] : player.teamNames;
      player.selectedPosition = position;
    },
    isMidfielderOneSelectedSameAsTwo(player) {
      return this.roundSelected.selections.midfielder2 && player.name === this.roundSelected.selections.midfielder2.name;

    },
    isMidfielderTwoSelectedSameAsOne(player) {
      return this.roundSelected.selections.midfielder1 && player.name === this.roundSelected.selections.midfielder1.name;
    },
    onDefenceClicked(player) {
      this.setPlayersData(player, 'defence');
      this.setActiveRoundDefencePick(player);
    },
    onMidfielderOneClicked(player) {
      this.setPlayersData(player, 'midfielder1');
      this.setActiveRoundMidfielderOnePick(player);
      this.setSelectedPositionInRound('midfielder2');
    },
    onMidfielderTwoClicked(player) {
      this.setPlayersData(player, 'midfielder2');
      this.setActiveRoundMidfielderTwoPick(player);
      this.setSelectedPositionInRound('midfielder1');
    },
    onForwardClicked(player) {
      this.setPlayersData(player, 'forward');
      this.setActiveRoundForwardPick(player);
    },
    onGoalkeeperClicked(player) {
      this.setPlayersData(player, 'goalkeeper');
      this.setActiveRoundGoalkeeperPick(player);
    },
    isFullTeamSelected() {
      const selections = Object.values(this.roundSelected.selections);
      const numberOfSelections = selections.length;
      if (numberOfSelections === 5) this.setRoundHasFullTeam(true);
      else this.setRoundHasFullTeam(false);
    },
    async positionHeaderIconClicked(position) {
      this.setIsLoading(true);
      this.setSelectedPositionInRound(position);
      setSelectedPosition(position);
      await this.setData();
    },
    findTeamLogo(player) {
      const teamName = this.roundSelected.position === "defence" ? Helpers.formatTextForAssetNames(player.name) : Helpers.formatTextForAssetNames(player.teamNames[0])
      return Helpers.findTeamImageModule(teamName)
    },
    async nextButtonClicked() {
      this.$router.push('/team-builder');
    },
    backButtonClicked() {
      this.$router.push(`/team-builder`);
    },
    async setData() {
      const selectedPosition = getSelectedPosition();
      const selectedRound = JSON.parse(getSelectedRound());
      if (!this.roundSelected) this.setSelectedRound(selectedRound);
      if (!this.roundSelected.position) this.setSelectedPositionInRound(selectedPosition);
      const positionName = selectedPosition === "midfielder1" ? "midfielder" : selectedPosition === "midfielder2" ? "midfielder" : selectedPosition;

      if (selectedPosition === 'defence') {
        const response = await GameDataService.getRoundDefences(`${selectedRound.roundId}`);
        this.playersOrDefences = response.data;
        this.setIsLoading(false);
      } else {
        const response = await GameDataService.getRoundPlayers(1, `${positionName}`, `${selectedRound.roundId}`, 50, `${this.user.token}`);
        this.playersOrDefences = response.data;
        this.setIsLoading(false);
      }
    }
  },
}
</script>

<style scoped>
.body-container {
  position: relative;
}

.fixed-container {
  display: block;
  top: 0;
  height: 235px;
  z-index: 1;
}

.scrollable-container {
  display: flex;
  flex-direction: column;
  height: auto;
  top: 235px;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 75px;
  z-index: 0;
}

::-webkit-scrollbar {
  display: none;
}

.circle {
  position: relative;
  background: green;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  top: -30px;
}

.checkmark {
  position: absolute;
  transform: rotate(45deg) translate(-50%, -50%);
  left: 28%;
  top: 40%;
  height: 8px;
  width: 4px;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
}

.team-builder-header {
  position: relative;
  font-weight: bold;
  height: 40px;
}

.back-button {
  position: absolute;
  height: 20px;
  left: 20px;
}

.team-builder-select-position {
  font-size: 20px;
  font-weight: bold;
  height: 45px;
  border-radius: 0 0 20px 20px;
}

.countdown-match-container {
  display: flex;
  width: 85px;
  height: 20px;
  border-radius: 0 0 15px 15px;
  color: white;
}

.countdown-whistle {
  margin-left: 34%;
  margin-bottom: 7%;
  height: 20px;
}

.countdown-time-text {
  font-weight: normal;
  font-size: 10px;
}

.squad-container {
  font-size: 12px;
  font-weight: bold;
  color: #676060;
}

.squad-container img {
  width: 40px;
}

.table-header {
  background-color: #ffffff;
  font-size: 8.5px;
  color: #676060;
}

tr {
  background-color: #e7e7e7;
}

.auto-team-builder-button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  font-size: 10px;
  width: 80px;
  height: 20px;
  border-radius: 10px;
  font-weight: 600;
  margin-top: 5px;
}

.table-team-selection {
  border-collapse: separate;
  border-spacing: 0 5px;
  margin-top: 10px;
}

td:first-child {
  border-right-style: solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

td {
  text-align: center;
  vertical-align: middle;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  color: v-bind('fontColour');
  background: #b6b6b6;
  font-weight: 600;
}

.button-container {
  position: relative;
}

.next-button button {
  font-size: 24px;
  font-weight: bold;
  border: 0px solid red;
  border-radius: 12px;
}

.player-rank {
  font-weight: 700;
}

.player-icon {
  width: 35px;
}

.player-name {
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 9px;
}

.player-club-badge {
  display: flex;
  justify-content: center;
  height: 15px;
}
</style>
