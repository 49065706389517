<template>
  <div
      class="d-flex flex-row justify-content-center align-items-center team-builder-league-round-details-container secondary-bg-colour primary-text">
    <div
        class="d-flex flex-column align-items-center justify-content-center align-content-center text-center col-2 win-data-container primary-bg-colour">
      <img class="col-11" :src="require(`../../brand/${brandConfig.brand}/assets/win-data-icon.png`)">
    </div>
    <div class="d-flex flex-column text-center pt-4 align-items-center league-details">
      <div class="d-flex flex-row align-items-center">
        <img class="league-logo" :src="Helpers.findLeagueImageModule(roundSelected)">
        <div class="pb-2 league-name">{{ roundSelected.leagueName }}</div>
      </div>
      <div class="countdown-match-container secondary-bg-colour primary-text">
        <img class="countdown-whistle" src="../../assets/whistle-icon.png">
        <div class="col-7 countdown-time-text primary-text">
          {{ -roundSelected.timeUntilRoundClose.days }}d:{{ roundSelected.timeUntilRoundClose.hours }}h
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import {mapState} from "vuex";

export default {
  name: "LeagueHeader",
  computed: {
    ...mapState(['brandConfig'])
  },
  methods: {
    setBrandImgMargin() {
      if (this.brandConfig.brand === 'vc-za-en') {
        this.activeRoundImgLeftMargin = '5px';
        this.activeRoundImgTopMargin = '-4px';
      } else {
        this.activeRoundImgLeftMargin = '1px';
        this.activeRoundImgTopMargin = '2px';
      }
    },
  },
  props: {
    roundSelected: Object
  },
  beforeMount() {
    this.setBrandImgMargin();
  }
}
</script>

<style scoped>
.team-builder-league-round-details-container {
  position: relative;
  font-weight: bold;
  border-radius: 0 0 16px 16px;
  height: 60px;
}

.win-data-container {
  position: absolute;
  height: 45px;
  width: 65px;
  left: 2%;
  border-radius: 10px;
}

.win-data-container img {
  margin-left: v-bind('activeRoundImgLeftMargin');
  margin-top: v-bind('activeRoundImgTopMargin');
}

.countdown-match-container {
  z-index: 3;
  display: flex;
  width: 100px;
  height: 20px;
  border-radius: 0 0 15px 15px;
}

.league-logo {
  padding-bottom: 5px;
  padding-left: 6px;
  height: 38px;
}

.league-name {
  padding-left: 5px;
  font-size: v-bind('leagueNameFontSize')
}

.league-details {
  padding-left: 5px;
}

.countdown-whistle {
  padding-left: 8px;
  height: 21px;
}

.countdown-time-text {
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 14px;
}

@media screen and (max-width: 450px) {
  .league-name {
    font-size: 10px;
  }

  .league-logo {
    height: 30px;
  }
}
</style>
