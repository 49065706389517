export const styles = {
    backgrounds: [
        { className: "primary-bg-colour", colour: "#4CA9F2" },
        { className: "secondary-bg-colour", colour: "#2D343B" },
        { className: "navbar-bg-colour", colour: "#2D343B" }
    ],
    text: [
        { className: "primary-text", colour: "#FFFFFF" },
        { className: "secondary-text", colour: "#4CA9F2" }
    ],
    borders: [
        { className: "primary-border-colour", colour: "#4CA9F2" },
    ]
};
