<template>
  <div class="d-flex flex-column body-container" v-if="!this.isLoading" :style="cssProps">
    <div class="d-flex flex-row p-2 my-results-header">
      {{ translationsFormatter('round_leaderboard_header_title') }}
    </div>
    <div class="d-flex align-items-center justify-content-center pb-2 winnings-container">
      <img :src="require(`../../brand/${brandConfig.brand}/assets/results-main-banner.png`)" class="main-banner">
      <div class="my-position-circle primary-border-colour secondary-text">
        <img class="my-position-award-icon" :src="require(`../../brand/${brandConfig.brand}/assets/badge-icon.png`)"
             alt="">
        <div class="my-position-text secondary-text">
          {{ translationsFormatter('round_leaderboard_my_position_circle') }}
        </div>
        <div class="d-flex">
          <div class="number-position secondary-text">{{ this.currentUser.Rank }}</div>
          <div class="number-position-end  secondary-text">
            {{ this.getCorrectNumberEnding(`${this.currentUser.Rank}`) }}
          </div>
        </div>
      </div>
    </div>
    <div class="table-headers col-12">
      <div class="header-text-first w-10">{{
          translationsFormatter('round_leaderboard_table_position_column_header')
        }}
      </div>
      <div class="header-text w-50 p-l-1">{{
          translationsFormatter('round_leaderboard_table_user_name_column_header')
        }}
      </div>
      <div class="header-text w-10 p-l-1">{{
          translationsFormatter('round_leaderboard_table_total_points_column_header')
        }}
      </div>
      <div class="header-text w-10 p-l-1">{{
          'Prize Won'
        }}
      </div>
    </div>
    <div class="user-field-container" v-for="(user) in this.leaderboard">
      <div :class="`${user.Rank > 5 ? 'table-row-default' : 'table-row-winner'}`">
        <div class="position-text">{{ user.Rank }}</div>
        <div class="d-flex flex-row align-items-center username-text">
          <img :src="require(`../../brand/${this.brandConfig.brand}/assets/user-icon.png`)" class="user-icon">
          <div v-if="user.Username">{{ user.Username }}</div>
          <div v-if="user.Username === null">{{
              translationsFormatter('round_leaderboard_table_player_username_default')
            }}
            {{ user.Msisdn.slice(-3) }}
          </div>
        </div>
        <div class="highest-rank-text"> {{ user.TotalPoints }}</div>
        <div>
          <img v-if="user.Rank < 6" class="prize-won-img"
               :src="require(`../../brand/${this.brandConfig.brand}/assets/20k-cash-asset.png`)">
          <div v-if="user.Rank > 5" class="prize-won-img"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import GameDataService from "@/services/gameDataService";
import UserDataService from "@/services/userDataService";
import Helpers from "@/helpers/helpers";

export default {
  name: "RoundLeaderboard",
  data() {
    return {
      leaderboard: undefined,
      currentUser: undefined,
      userTeam: [],
    }
  },
  async beforeMount() {
    this.setIsLoading(true);
    this.leaderboard = (await GameDataService.getLeaderboard(this.user.msisdn))[0];
    console.log(this.user)
    this.currentUser = this.leaderboard.find(element => element.Msisdn === this.user.msisdn);
    console.log(this.currentUser)
    this.setIsLoading(false);
  },
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'roundSelected', 'user']),
    cssProps() {
      return {
        backgroundImage: `url(${require(`@/brand/${this.brandConfig.brand}/assets/background.png`)})`,
      }
    }
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    getCorrectNumberEnding(number) {
      if (number[number.length - 1] === "1") return "st"
      if (number[number.length - 1] === "2") return "nd"
      if (number[number.length - 1] === "3") {
        return "rd"
      } else {
        return "th"
      }
    },
  }
}
</script>
<style scoped>
.body-container {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 7vh;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.body-container::-webkit-scrollbar {
  display: none;
}

.prize-won-img {
  position: relative;
  width: 50px;
}

.number-position {
  font-size: 12px;
  font-weight: 900;
}

.number-position-end {
  font-size: 10px;
  font-weight: 900;
  padding-bottom: 4px;
}

.my-position-award-icon {
  height: 30px;
}

.highest-rank-text {
  font-weight: 700;
  width: 10%;
}

.position-text {
  width: 10%;
  font-weight: 700;
}

.table-headers {
  display: flex;
  justify-content: space-around;
}

.header-text {
  font-size: 8px;
  padding: 2px;
  margin-left: 4px;

}

.header-text-first {
  font-size: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.user-field-container {
  display: flex;
  flex-direction: column;
  width: auto;
}

.user-icon {
  height: 20px;
  margin-right: 5px;
}

.username-text {
  font-size: x-small !important;
  width: 50%;
}

.my-results-header {
  font-weight: bold;
}

.winnings-container {
  position: relative;
}

.main-banner {
  width: 100%;
}

.my-position-circle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: white;
  border: solid 4px;
}

.my-position-text {
  font-weight: 700;
  font-size: 12px;
}

table {
  border-collapse: separate;
  border-spacing: 0 3px;
}

thead {
  font-size: 10px;
  font-weight: lighter;
  color: #676060;
}

.table-row-winner {
  background-color: #EF151C;
  color: #FFFFFF;
}

.table-row-default {
  background-color: #e7e7e7;
}

.table-row-winner, .table-row-default {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: small;
  font-weight: 600;
  width: 100% !important;
  border-radius: 10px;
  margin-top: 5px;
  height: 40px;
}

td:first-child {
  border-right-style: solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.border-default {
  border: .5px solid #e7e7e7;
}
</style>
