<template>
  <div class="container-fluid d-flex flex-column">
    <h4 class="d-flex mt-3">
      Entité organisatrice : MTN Congo
    </h4>
    <div class="d-flex mt-3">
      L'Organisateur se réserve le droit d'annuler ou de modifier le service et les présentes Conditions
      Générales à tout moment. Tout changement sera affiché en ligne sur le site Web de l'organisateur.
    </div>
    <div class="d-flex mt-3">
      <b>1. Description du service</b>
    </div>
    <div class="d-flex px-2">
      1.1 MTN Congo (l'Organisateur) et Upstream Mobile Commerce (le Prestataire) lanceront un
      nouveau service par SMS et par Internet (le service) exclusivement pour les clients de
      l'Organisateur.
    </div>
    <div class="d-flex px-2">
      1.2 MTN FANFOOT est un nouveau service à valeur ajoutée qui permettra aux abonnés MTN de
      souscrire et de recevoir les contenus premiums de sports particulièrement sur le FOOTBALL. Les
      clients ont la possibilité de choisir leurs joueurs préférés dans les plus grandes ligues du monde
      et de gagner des prix lorsqu'ils se produisent sur le terrain. De l'EPL à la Ligue des champions, les
      fans peuvent désormais s'affronter.
    </div>
    <div class="d-flex px-2">
      1.3 Le service sera sur une durée indéterminée et les abonnés auront la chance de remporter des
      lots en récoltant des points.
    </div>
    <div class="d-flex flex-column mt-3">
      <p>Le numéro court dédié sera le suivant:</p>
      <ul>
        <li><strong>SMS 1024 et USSD *194*1 & *194*2#</strong></li>
        <li><strong>USSD subscription: *204*11#</strong></li>
        <li><strong>USSD unsubscription: *204*0*11#</strong></li>
        <li><strong>USSD STOP ALL:*204*0*10#</strong></li>
        <li><strong>USSD game: *204*12#</strong></li>
      </ul>
    </div>
    <div class="d-flex mt-3">
      Les participants seront invités à jouer au service pour gagner plus de points et augmenter leurs chances
      de gagner des prix.
    </div>
    <div class="d-flex mt-3 px-2">
      - Une fois les frais quotidiens acquittés, les participants recevront un message de confirmation de
      chargement, confirmant la participation par un chargement réussi, et contenant l&#39;hyperlien pour
      accéder au site mobile dédié. Il peut jouer par sms ou accéder au lien pour participer en ligne.
    </div>
    <div class="d-flex mt-3">
      <b>2. Enregistrement et annulation</b>
    </div>
    <div class="d-flex px-2">
      2.1 Pour s'inscrire et devenir un participant au service, les utilisateurs devront envoyer un SMS avec un
      mot-clé d'inscription valide (par ex. OK, YES, GO) par SMS aux numéros courts dédiés, ou composer les
      codes USSD correspondant, ou confirmer la volonté de participer en répondant [OK] aux messages
      publicitaires SAT Push.
    </div>
    <div class="d-flex px-2">
      2.2 D'autres demandes d'inscription pourront être ajoutées de temps à autre pendant la durée du
      service, à la seule discrétion de l'Organisateur.
    </div>
    <div class="d-flex px-2">
      2.3 Les participants pourront se désabonner à tout moment pendant la durée de la Promotion, en
      envoyant un SMS gratuit avec le mot clé STOP aux numéros courts dédiés.
    </div>
    <div class="d-flex px-2">
      2.4 Les participants pourront également annuler leur abonnement à tout moment en appelant le centre
      d'appels de MTN et demander leur désabonnement.
    </div>
    <div class="d-flex px-2">
      2.5 L'inscription des participants pourra également être annulée à la seule discrétion de l'Organisateur
      dans le cas où une manifestation claire de non-intérêt à participer à la promotion serait démontrée
      par SMS (par exemple, le participant enverra un message MO similaire à "Je ne veux pas participer").
    </div>
    <div class="d-flex px-2">
      2.6 En cas d'échec répété de facturation des frais, à la seule discrétion de l'Organisateur, les Participants
      pourront être retirés du service.
    </div>
    <div class="d-flex px-2">
      2.7 Les participants qui auront annulé leur abonnement pourront se réinscrire à tout moment en
      envoyant le(s) mot(s) clé(s) d'inscription défini(s) au numéro court dédié, ou par tout autre moyen
      d'inscription valide.
    </div>
    <div class="d-flex px-2">
      2.8 L'Organisateur se réserve le droit d'empêcher l'accès au service à un sous-ensemble spécifique de la
      clientèle.
    </div>
    <div class="d-flex mt-3">
      <b>3. Frais d’abonnement</b>
    </div>
    <div class="d-flex px-2">
      3.1 Les frais d'abonnement quotidiens seront de 50F/jour (taxes incluses). Chaque jour, les participants
      seront facturés selon le cycle de facturation quotidien en vigueur pendant la durée active de
      l'abonnement.
    </div>
    <div class="d-flex px-2">
      3.2 L'Organisateur peut offrir l'abonnement à sa seule discrétion, et de temps en temps à un tarif réduit.
    </div>
    <div class="d-flex px-2">
      3.3 Toutes les interactions par SMS que les Participants auront avec le Service seront gratuites.
    </div>
    <div class="d-flex px-2">
      3.4 Toutes les sessions web que les utilisateurs auront sur le portail de jeu seront facturées selon les
      tarifs standards.
    </div>
    <div class="d-flex mt-3">
      <b>4. Durée du service</b>
    </div>
    <div class="d-flex px-2">
      4.1 Le service aura une durée indéterminée.
    </div>
    <div class="d-flex px-2">
      4.2 L'Organisateur se réserve le droit de reporter les dates de début et de fin d'une ou plusieurs
      semaines.
    </div>
    <div class="d-flex px-2">
      4.3 L'Organisateur se réserve le droit de prolonger la Durée du service en mettant à jour les Conditions
      Générales.
    </div>
    <div class="d-flex mt-3">
      <b>5. Les récompenses</b>
    </div>
    <div class="d-flex">
      Le tableau suivant résume les prix qui seront attribués :
    </div>
    <div class="d-flex">
      <b>Lots par trimestre</b>
    </div>
    <div class="d-flex">
      <table class="table table-bordered">
        <tr>
          <th>Trimestriel</th>
          <th>CFA</th>
        </tr>
        <tr>
          <td>1</td>
          <td>200000</td>
        </tr>
        <tr>
          <td>2</td>
          <td>150000</td>
        </tr>
        <tr>
          <td>3</td>
          <td>100000</td>
        </tr>
        <tr>
          <td>4</td>
          <td>50000</td>
        </tr>
        <tr>
          <td>5</td>
          <td>30000</td>
        </tr>
        <tr>
          <td>6</td>
          <td>20000</td>
        </tr>
        <tr>
          <td>7</td>
          <td>20000</td>
        </tr>
        <tr>
          <td>8</td>
          <td>20000</td>
        </tr>
        <tr>
          <td>9</td>
          <td>20000</td>
        </tr>
        <tr>
          <td>10</td>
          <td>20000</td>
        </tr>
        <tr>
          <td>11</td>
          <td>15000</td>
        </tr>
        <tr>
          <td>12</td>
          <td>15000</td>
        </tr>
        <tr>
          <td>13</td>
          <td>15000</td>
        </tr>
        <tr>
          <td>14</td>
          <td>15000</td>
        </tr>
        <tr>
          <td>15</td>
          <td>15000</td>
        </tr>
        <tr>
          <td>16</td>
          <td>10000</td>
        </tr>
        <tr>
          <td>17</td>
          <td>10000</td>
        </tr>
        <tr>
          <td>18</td>
          <td>10000</td>
        </tr>
        <tr>
          <td>19</td>
          <td>10000</td>
        </tr>
        <tr>
          <td>20</td>
          <td>10000</td>
        </tr>
      </table>
    </div>
    <div class="d-flex px-2 mt-3">
      5.1 L&#39;Organisateur se réserve le droit de modifier les récompenses.
    </div>
    <div class="d-flex px-2">
      5.2 Les points seront alloués comme suit :
    </div>
    <div class="d-flex">
      <table class="table table-bordered">
        <tr>
          <th>Jeu /Action</th>
          <th>MTN FANFOOT</th>
        </tr>
        <tr>
          <td>A chaque facturation réussie</td>
          <td>10 points</td>
        </tr>
        <th colspan="2" class="text-center">Attaque</th>
        <tr>
          <td>But marqué par l&#39;attaquant</td>
          <td>4 points</td>
        </tr>
        <tr>
          <td>But marqué par le milieu de terrain</td>
          <td>5 points</td>
        </tr>
        <tr>
          <td>But marqué par le défenseur</td>
          <td>6 points</td>
        </tr>
        <tr>
          <td>Aide</td>
          <td>3 points</td>
        </tr>
        <tr>
          <td>Tir au but</td>
          <td>1 points</td>
        </tr>
        <tr>
          <td>Coup de pied de pénalité manqué/sauvé</td>
          <td>-2 points</td>
        </tr>
        <th colspan="2" class="text-center">Défense</th>
        <tr>
          <td>Passe déviée</td>
          <td>1 points</td>
        </tr>
        <tr>
          <td>Tacle gagnant</td>
          <td>1 point</td>
        </tr>
        <tr>
          <td>Clean sheet (Défenseurs)</td>
          <td>4 point</td>
        </tr>
        <tr>
          <td>Clean sheet ( Milieu de terrain)</td>
          <td>1 point</td>
        </tr>
        <tr>
          <td>But contre (Défenseurs)</td>
          <td>-1 point</td>
        </tr>
        <tr>
          <td>But contre son camp</td>
          <td>-2 point</td>
        </tr>
        <tr>
          <td>Carton jaune</td>
          <td>-1 point</td>
        </tr>
        <tr>
          <td>Carton rouge</td>
          <td>-3 point</td>
        </tr>
        <th colspan="2" class="text-center">Gardien de but</th>
        <tr>
          <td></td>
          <td>1 point</td>
        </tr>
        <tr>
          <td>Coup de pied arrêté</td>
          <td>5 point</td>
        </tr>
        <tr>
          <td>Clean Sheet</td>
          <td>4 point</td>
        </tr>
        <tr>
          <td>But contre</td>
          <td>-1 point</td>
        </tr>
      </table>
    </div>
    <div class="d-flex px-2">
      5.3 Les prix doivent être acceptés tels quels, ne peuvent être échangés contre d'autres prix ou de
      l'argent autre que ceux offerts dans le cadre du lot de prix et ne peuvent être transférés ou
      échangés.
    </div>
    <div class="d-flex px-2">
      5.4 Les prix seront délivrés dans les 180 jours suivant l'annonce du gagnant.
    </div>
    <div class="d-flex mt-3">
      <b>6. Règles de participation</b>
    </div>
    <div class="d-flex px-2">
      6.1 Le service sera ouvert aux résidents du Congo qui sont abonnés à l'Organisateur, à l'exception de
      tous les employés, représentants et agents de l'Organisateur, de leurs parents respectifs et/ou
      sociétés affiliées, fournisseurs du concours, et de ceux avec lesquels l'un des précédents sont
      domiciliés.
    </div>
    <div class="d-flex px-2">
      6.2 La personne qui possède la carte SIM ou qui a l'autorisation expresse du propriétaire de la carte SIM
      de l'utiliser est considérée comme le Participant, quel que soit l'utilisateur réel du téléphone.
      Pour être admissible aux prix, un utilisateur doit avoir accumulé au moins 1 point au cours de la période
      du prix correspondant.
    </div>
    <div class="d-flex mt-3">
      <b>7. Sélection du gagnant, notification et réception du prix</b>
    </div>
    <div class="d-flex px-2">
      7.1 Les prix seront attribués par tirage au sort. Un tirage au sort aura lieu, au cours duquel le nombre
      spécifié de gagnants sera choisi en fonction du nombre de points cumulés parmi tous les bulletins de
      participation admissibles. Chaque tirage identifiera un gagnant et 10 finalistes, qui auront le droit de
      devenir le gagnant dans l'éventualité où le premier sélectionné ne répondrait pas à tous les critères
      d'éligibilité.
    </div>
    <div class="d-flex px-2">
      7.2 Chaque gagnant sera contacté par l'Organisateur au numéro de téléphone portable qu'il utilisera
      pour participer au service. L'Organisateur tentera de contacter le gagnant au moins 3 fois par jour
      sur une période de 3 jours, entre 9h et 20h. Si le gagnant ne peut être contacté durant cette période,
      il sera disqualifié et perdra son prix et l'Organisateur tentera de contacter le prochain finaliste.
    </div>
    <div class="d-flex px-2">
      7.3 Le gagnant devra fournir une preuve d'admissibilité et signer un formulaire d'acceptation de prix (tel
      que demandé par l'Organisateur) avant que les prix ne soient recueillis. Si le gagnant ne fournit pas
      de preuves suffisantes dans ce délai, il sera disqualifié et perdra son prix et le gagnant suivant sera
      contacté.
    </div>
    <div class="d-flex px-2">
      7.4 Si un gagnant ne réclame pas son prix dans les 2 semaines suivant la date du contact, le premier
      substitut sera sélectionné pour réclamer le prix.
    </div>
    <div class="d-flex px-2">
      7.5 Si le gagnant et tous les finalistes renoncent à leur prix, celui-ci sera considéré comme non réclamé
      et restera à la disposition de l'Organisateur.
    </div>
    <div class="d-flex px-2">
      ville de résidence, sa photographie et son portrait soient utilisés dans tout matériel promotionnel
      produit par l'Organisateur. Une telle utilisation ne donnera droit à aucune autre compensation au
      gagnant.
    </div>
    <div class="d-flex px-2">
      7.7 Chaque Participant (personne physique ou MSISDN) ne pourra recevoir qu'un seul prix par catégorie
      de prix.
    </div>
    <div class="d-flex px-2">
      7.8 Toute dépense qui n'est pas explicitement indiquée comme faisant partie du prix, y compris, mais
      sans s'y limiter, l'assurance, les frais de voyage, l'hébergement, les droits de licence ou les taxes, sera
      sous la responsabilité du gagnant.
      La participation à ce service est réservée aux personnes de plus de 18 ans.
    </div>
    <div class="d-flex mt-3">
      <b>8. Abus et disqualification</b>
    </div>
    <div class="d-flex">
      Les participants ne seront pas autorisés à utiliser des machines qui envoient automatiquement des SMS
      ou qui automatisent/facilitent le processus de réponse/jeu, ou toute autre alternative d'envoi de
      SMS, autre que le téléphone portable (ceci interdit l'utilisation de tout modem GSM attaché à un
      ordinateur ou de téléphones portables connectés à un ordinateur). L'Organisateur se réserve le droit
      de disqualifier tout Participant soupçonné d'avoir manqué à cette obligation.
    </div>
    <div class="d-flex mt-3">
      <b>9. Responsabilités de l’organisateur</b>
    </div>
    <div class="d-flex">
      La responsabilité de l'organisateur sera strictement limitée dans l'attribution des prix. L'Organisateur ne
      peut être tenu responsable de tout retard ou impossibilité d'envoyer ou de recevoir des SMS en
      raison d'un problème technique. Tous les SMS seront considérés comme reçus par l'Organisateur
      lorsqu'ils seront enregistrés dans le système informatique du service et non lorsqu'ils seront
      envoyés par le Participant. Tous les SMS seront considérés comme reçus par le Participant au
      moment où ils seront envoyés depuis le système informatique du service. Les participants
      reconnaissent et acceptent que le journal du système informatique du service sera la preuve de la
      date, de l'heure, de la minute et de la seconde de leur réception/envoi de chaque message SMS.
    </div>
    <div class="d-flex mt-3">
      <b>10. Protection des données personnelles</b>
    </div>
    <div class="d-flex mb-5">
      En participant au service, les Participants acceptent expressément que les données personnelles
      qu'ils fournissent soient traitées par l'Organisateur aux fins de l'exécution du service ainsi qu'à des
      fins de publicité du service et de l'Organisateur. Il est précisé que l'Organisateur garantit que la
      publicité, impliquant le partage avec des tiers des données personnelles fournies à la suite de la
      participation au service, (y compris par la télévision, la presse, les dépliants et autres moyens et
      formats de communication) ne sera faite qu'à l'égard des gagnants des Prix et indiquera uniquement
      les noms et prénoms des gagnants, le lieu de leur résidence, leurs images et voix (contenus dans les
      enregistrements photographiques, audio, vidéo et autres types de documents, faits à l'occasion de la
      participation au service). Les Participants gagnants donnent, par leur participation au service, leur
      consentement exprès à l'utilisation de ces données dans les formats définis ci-après. A la suppression
      du service, les données personnelles seront détruites.
      Conformément aux lois et règlements locaux, l'Organisateur respecte la vie privée des Participants à
      ce service, par laquelle il s'engage à utiliser la technologie sous une forme transparente et dans le
      strict respect des droits, libertés et garanties des citoyens et par la préservation de l'intimité de leur
      vie privée et familiale. Les données personnelles collectées dans le cadre de ce service ne seront
      utilisées qu'aux fins de ce service et seront traitées de manière privée et confidentielle. Elles ne
      doivent pas être divulguées à d'autres personnes ou organisations, sauf en cas de législation
      contraire ou lorsque les participants eux-mêmes l'autorisent. Les données seront stockées sur des
      serveurs dont l'accès sera contrôlé en fonction du besoin d'en connaître et limité par des mots de
      passe.
    </div>
    <h2 @click="onReturnToPreviousPageClicked" class="return-previous">RETOUR À LA PAGE PRÉCÉDENTE</h2>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "MtnCongoTerms",
  computed: {
    ...mapState(['user'])
  },
  methods: {
    onReturnToPreviousPageClicked() {
      this.$router.push(`/profile`)
    }
  }
}
</script>

<style scoped>


</style>
