import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";

export default class LoginService {
    static async sendOTP(msisdn, revenuePartnerId) {
        return await axios.post(`${environmentConfig.upstreamIntegrationService}/user-authentication/request-otp`, {
            msisdn: msisdn,
            revenuePartnerId: revenuePartnerId
        })
    }

    static async validateOTP(msisdn, otp) {
        return await axios.post(`${environmentConfig.upstreamIntegrationService}/user-authentication/validate-otp`, {
            msisdn: msisdn,
            otp: otp,
        })
    }

    static async trackLogin(msisdn, revenuePartnerId) {
        return await axios.post(`${environmentConfig.upstreamIntegrationService}/track-login`, {
            msisdn: msisdn,
            revenuePartnerId: revenuePartnerId,
            isFantasy: 1,
            isScorePredictor: 0,
            isWeb: 1
        })
    }

    static async getUserViaProductId(productId, msisdn) {
        const response = await axios.post(`${environmentConfig.upstreamAisHost}/user/get-user-via-product-id`, {
            productId: productId,
            msisdn: msisdn,
        })
        return response.data;
    }

    static async trackAisUserLogin(productId, msisdn) {
        return await axios.post(`${environmentConfig.upstreamAisHost}/user/track-user-login`, {
            productId: productId,
            msisdn: msisdn,
            isWeb: true,
        })
    }
}
