import {brandConfigMap} from "@/brand";
import store from "@/store";
import {getBrandConfig} from "@/brand";

export function setNewBrandConfigLanguage(brand) {
    let base_url = window.location.origin
    if (brand === "ethiotel-et-amh") brandConfigMap.set(base_url, getBrandConfig('ethiotel-et-en'));
    if (brand === "ethiotel-et-en") brandConfigMap.set(base_url, getBrandConfig('ethiotel-et-amh'));
    if (brand === "dtac-tai-tha") brandConfigMap.set(base_url, getBrandConfig('dtac-tai-en'));
    if (brand === "dtac-tai-en") brandConfigMap.set(base_url, getBrandConfig('dtac-tai-tha'));
    setBrandConfig()
}

export function setBrandConfig() {
    const domain = window.origin;
    const brandConfig = brandConfigMap.get(domain) ? brandConfigMap.get(domain) : brandConfigMap.get('default');
    store.commit('setBrandConfig', brandConfig);

    const brandStyles = require(`../brand/${brandConfig.brand}/styles/index.js`).styles;
    const brandStyleSheet = document.createElement('style');

    let brandStyleInnerHTML = '';

    brandStyles.backgrounds.forEach((background) => {
        if (brandStyleInnerHTML === '') brandStyleInnerHTML += `.${background.className} { background-color: ${background.colour} }`;
        else brandStyleInnerHTML += ` .${background.className} { background-color: ${background.colour} }`;
    });

    brandStyles.text.forEach((textItem) => {
        brandStyleInnerHTML += ` .${textItem.className} { color: ${textItem.colour} }`;
    });

    brandStyles.borders.forEach((textItem) => {
        brandStyleInnerHTML += ` .${textItem.className} { border-color: ${textItem.colour} }`;
    });

    brandStyleSheet.innerHTML = brandStyleInnerHTML;
    document.body.appendChild(brandStyleSheet);
}
