<template>
  <div class="container-fluid d-flex flex-column">
    <h4 class="d-flex mt-3">
      "MTN Nigeria Fantasy Games" Promotion - Terms and Conditions
    </h4>
    <div class="d-flex mt-3">
      <b>Organizing Entity: MTN Nigeria</b>
    </div>
    <div class="d-flex mt-3">
      The Organizer reserves the right to cancel or amend the Promotion and these Terms and Conditions at any time. Any
      changes will be posted online at the Organizer’s website.
    </div>
    <div class="d-flex mt-3">
      <b>1) Service Description</b>
    </div>
    <div class="d-flex px-2">
      1.1. MTN Nigeria (the Organizer) and Rembrandt Global Solutions Limited (the Provider) will launch an SMS and
      Interactive Web-based Game (the Promotion) for the Organizer’s customers. The Promotion gives the Organizer’s
      customers the opportunity to participate in draws for various prizes.
    </div>
    <div class="d-flex px-2">
      1.2. The Promotion will be based on a Premium subscription (daily subscription cost NGN 100/day), which will
      enable the participant to play with:
    </div>
    <div class="d-flex px-3">
      ● An SMS-based Premium Trivia Game, consisting in a set of 5 questions and answers about sports news, scores and
      general information, which the subscriber will be invited to reply to, and
    </div>
    <div class="d-flex px-3">
      ● A WAP/WEB-based Premium Interactive Web Game, providing the end user with the ability to pick their favorite
      players from the biggest teams across the world, enter multiple contests daily and weekly across leagues, and view
      various information including player/team news and scores.
    </div>
    <div class="d-flex px-2">
      1.3. Participants will be invited to play the Service to earn more points and increase their chances to win
      prizes.
    </div>
    <div class="d-flex px-2">
      1.4. Upon successful daily charge, Participants will be provided with a:
    </div>
    <div class="d-flex px-3">
      ● Charging confirmation message, confirming the entry accrual due to successful charge, and containing the
      hyperlink to access the Game Portal, and
    </div>
    <div class="d-flex px-3">
      ● Daily SMS query, by replying to which end users will trigger the SMS trivia game start.
    </div>
    <div class="d-flex mt-3">
      <b>2) Registration and Cancellation</b>
    </div>
    <div class="d-flex px-2">
      1) To register and become a Promotion Participant, end users will be required to send an SMS with a valid
      registration keyword (e.g., OK, YES, GO) to the short code 32708, or dial the USSD code *32708#, or confirming the
      willingness to participate by replying [OK] to the SAT Push advertising messages.
    </div>
    <div class="d-flex px-2">
      2) Additional registration requests might be added from time to time during the Promotion duration at sole
      discretion to the Organizer.
    </div>
    <div class="d-flex px-2">
      3) Participants will be able to cancel their subscription at any time during the duration of the Promotion, by
      sending a free-of-charge SMS with the keyword STOP to the short code 32708.
    </div>
    <div class="d-flex px-2">
      4) Participants will also be able to cancel their subscription by calling MTN Call Center on 180 and ask for
      unsubscription.
    </div>
    <div class="d-flex px-2">
      5) Participants’ subscription might be also canceled at the sole discretion of the Organizer in the event a clear
      expression of non-interest in participating to the promotion will be demonstrated via SMS communication (e.g., the
      Participant will send an MO message like “I do not want to participate”).
    </div>
    <div class="d-flex mt-3">
      6) In case of repeated unsuccessful charges, at the sole discretion of the Organizer, Participants might be
      removed from the Promotion.
    </div>
    <div class="d-flex mt-3">
      7) Participants who canceled their subscription can re-register at any given point in time during the Promotion by
      sending the defined registration keyword(s) to the dedicated short code, or by any other valid registration flow.
    </div>
    <div class="d-flex mt-3">
      8) The Organizer reserves the right to prevent the access to the Promotion to specific subsets of the customer
      base.
    </div>
    <div class="d-flex mt-3">
      <b>3) Subscription Charges</b>
    </div>
    <div class="d-flex mt-3">
      1) The daily subscription charge will be NGN 150/day (incl. Tax). Every day, Participants will be charged via the daily billing cycle that runs while a subscription will be active.
    </div>
    <div class="d-flex mt-3">
      2) The Organizer may offer the Premium subscription at its sole discretion from time to time at a discounted rate of ₦100 (NGN) daily OR ₦80 (NGN) daily OR ₦50 (NGN) daily OR ₦30 (NGN) daily OR
      ₦5 (NGN) daily.
    </div>
    <div class="d-flex mt-3">
      3) All SMS interactions Participants will have with the Service will be free of charge.
    </div>
    <div class="d-flex mt-3">
      4) All web sessions users using the game portal will be charged according to the standard operator data rates.
    </div>
    <div class="d-flex mt-3">
      <b>4) Promotion Duration</b>
    </div>
    <div class="d-flex mt-3">
      a. The Promotion will have a duration of 365 days. The start date will be on 2nd November 2023 local time and the
      end date will be on 1st November 2024 (the Promotional Period).
    </div>
    <div class="d-flex mt-3">
      b. The Organizer will reserve the right to shift the start and end date by one or more weeks.
    </div>
    <div class="d-flex mt-3">
      c. The Organizer will reserve the right to extend the Promotion Duration by updating the Terms and Conditions.
    </div>
    <div class="d-flex mt-3">
      <b>5) Rewarding Schema</b>
    </div>
    <div class="d-flex mt-3">
      The following table summarizes the criteria according to which Prizes that will be awarded:
    </div>
    <div class="d-flex">
      <table style="font-size: x-small" class="table-responsive table-bordered">
        <tr>
          <th>Prize</th>
          <th>Airtime/Data: 7 Winners Daily @ N10,000 Airtime/Data Value Per Winner (i.e. Total of N70,000 Value per
            Day)
          </th>
          <th>Monthly Sports Visit: 1 Winner Monthly @ N2million Value</th>
        </tr>
        <tr>
          <td>Leg</td>
          <td>SMS Trivia & Interactive Web</td>
          <td>SMS Trivia & Interactive Web</td>
        </tr>
        <tr>
          <td>Promotion Period</td>
          <td>365 days</td>
          <td>365 days</td>
        </tr>
        <tr>
          <td>Winner Selection Method</td>
          <td>Random</td>
          <td>Random</td>
        </tr>
        <tr>
          <td>Draw Period</td>
          <td>Daily</td>
          <td>Monthly</td>
        </tr>
        <tr>
          <td>Total Number of Draws within period</td>
          <td>365</td>
          <td>12</td>
        </tr>
        <tr>
          <td>Eligibility (Entry) Rules</td>
          <td>The customer must have been charged for the day, to participate in the corresponding daily draw.</td>
          <td>The customer must have been charged at least once during the calendar
            Month, to participate in the corresponding monthly draw.
          </td>
        </tr>
        <tr>
          <td>Points Participation Rules</td>
          <td>All points accumulated within the draw period from either the SMS Trivia or Interactive Web legs.</td>
          <td>All points accumulated within the draw period from either the SMS Trivia or Interactive Web legs.</td>
        </tr>
      </table>
    </div>
    <div class="d-flex px-2 mt-3">
      Note: Users are allowed to win same prize multiple times.
    </div>
    <div class="d-flex px-2 mt-3">
      1) The Prize Title reports the corresponding indicative economic value of the Prize.
    </div>
    <div class="d-flex px-2 mt-3">
      2) The Organizer will reserve the right to change the rewards during potential Extensions of the Promotion.
    </div>
    <div class="d-flex px-2 mt-3">
      3) Monthly Prizes Period will be defined as prizes to be won within one calendar month period, starting from
      00:00:00 of the Promotion launch day. Thus, Monthly Period #1 will last from 00:00:00 of Promotion Day #1 and will
      terminate at 23:59:59 of the last Day of the same month, and where the period is less than 28 days, it will then
      terminate at 23:59:59 of the last Day of the subsequent month, of the Promotional Period.
    </div>
    <div class="d-flex px-2 mt-3">
      4) For every correct answer Participant will send as a response to a question, the Participant will receive 50
      points.
    </div>
    <div class="d-flex px-2 mt-3">
      5) For every incorrect answer Participant will send as a response to a question, the Participant will receive 10
      points.
    </div>
    <div class="d-flex px-2 mt-3">
      6) For every invalid answer Participants will send as a response to a question, the Participant will receive 0
      points.
    </div>
    <div class="d-flex px-2 mt-3">
      7) For every first subscription, Participants will collect 100 points, or the equivalent points if discounted
      tariff was charged.
    </div>
    <div class="d-flex px-2 mt-3">
      8) For every successful charge, Participants will collect 100 points, or the equivalent points if discounted
      tariff was charged.
    </div>
    <div class="d-flex px-2 mt-3">
      9) Points are cumulative across Prize Periods.
    </div>
    <div class="d-flex px-2 mt-3">
      10) Prizes must be accepted as awarded, cannot be exchanged for other prizes or cash different from the ones
      provided within the Prize Pool, and may not be transferred or exchanged.
    </div>
    <div class="d-flex px-2 mt-3">
      11) Prizes will be delivered within 180 days from the moment of the winner announcement.
    </div>
    <div class="d-flex mt-3">
      <b>6) Eligibility Rules</b>
    </div>
    <div class="d-flex px-2 mt-3">
      1) The Promotion will be open to residents of Nigeria that are subscribers of the Organizer, excluding all
      employees, representatives and agents of the Organizer, their respective parents and/or affiliated companies,
      contest suppliers, and those with whom any of the foregoing are domiciled.
    </div>
    <div class="d-flex px-2 mt-3">
      2) The person that owns the SIM card or has express authorization from the SIM card’s owner to use it is
      considered the Participant, regardless of the actual user of the phone.
    </div>
    <div class="d-flex px-2 mt-3">
      3) In order to be eligible for Prizes, a user needs to have collected at least 1 point during the corresponding
      Prize Period.
    </div>
    <div class="d-flex mt-3">
      <b>7) Winner Selection, Notification, and Prize Forfeiture</b>
    </div>
    <div class="d-flex px-2 mt-3">
      1) Prizes will be assigned randomly as depicted in section 5 above.
    </div>
    <div class="d-flex px-2 mt-3">
      2) There will be 10 runner ups, and the next winner be entitled to become the winner in the event the winner did
      not comply with all the eligibility criteria.
    </div>
    <div class="d-flex px-2 mt-3">
      3) Every winner will be contacted by the Organizer on the mobile phone number s/he will use to participate in the
      Promotion. The Organizer will attempt to contact the winner at least 3 times a day over a period of 3 days,
      between the hours of 9am and 8pm. If the winner cannot be contacted during this period, s/he will be disqualified
      and forfeit the prize and the Organizer will attempt to contact the next runner up and repeat the same contact
      cycle until a winner is contacted and claims the prize, or until the 10th runner up has been contacted
      unsuccessfully and the prize is forfeited.
    </div>
    <div class="d-flex px-2 mt-3">
      4) If the winner and all runners-up will forfeit the prize, the prize will be deemed unclaimed and will remain at
      the disposal of the Organizer.
    </div>
    <div class="d-flex px-2 mt-3">
      5) The winner will be required to provide proof of eligibility and sign a prize acceptance form (as requested by
      the Organizer) before prizes will be collected. If the winner will fail to provide sufficient proof within that
      period, s/he will be disqualified and forfeit the prize and the next runner up will be contacted.
    </div>
    <div class="d-flex px-2 mt-3">
      6) By accepting a prize, a winner will consent to the use of his/her first name, initial of the last name, city of
      residence, photograph and likeness in any promotional material produced by the Organizer. Such use will not
      entitle the winner to any further compensation.
    </div>
    <div class="d-flex px-2 mt-3">
      7) Each Participant (physical person or MSISDN) will be entitled to receive multiple prizes per prize category.
    </div>
    <div class="d-flex px-2 mt-3">
      8) Any expenses not explicitly indicated as part of the prize, including but not limited to insurance, travel
      expenses, accommodation, license fees or taxes, will be under the winner's responsibility.
    </div>
    <div class="d-flex px-2 mt-3">
      9) The age limit for participation in the Promotion is 18 years.
    </div>
    <div class="d-flex mt-3">
      <b>8) Abuse and Disqualification</b>
    </div>
    <div class="d-flex px-2 mt-3">
      Participants will not be allowed to use machines that automatically send SMS messages or automate/facilitate the
      answer/gaming process, or any other alternative of sending SMS messages, other than the mobile phone (this
      prohibits the use of any GSM modems attached to a computer or mobile phones attached to a computer). The Organizer
      will reserve the right to disqualify any Participant under the suspicion of breaking this obligation.
    </div>
    <div class="d-flex mt-3">
      <b>9) Organizer’s Responsibilities</b>
    </div>
    <div class="d-flex px-2 mt-3">
      Organizer’s responsibility will be strictly limited in giving the Prizes. The Organizer cannot be held responsible
      for any delay or impossibility of sending or receiving SMS messages because of a technical problem. All the SMS
      messages will be considered received by the Organizer when they will be registered in the Promotion’s IT system
      and not when they will be sent by the Participant. All the SMS messages will be considered received by the
      Participant at the moment they will be sent from the Promotion’s IT system. Participants recognize and accept that
      the journal of the informatics system of the Promotion’s IT System will be the proof of the date, hour, minute and
      second of their receiving/ sending each SMS message.
    </div>
    <div class="d-flex mt-3">
      <b>10) Personal Data Protection</b>
    </div>
    <div class="d-flex px-2 mt-3">
      By participating in the Promotion, the Participants expressly agree that personal data which they supply shall be
      processed by the Organizer for the purposes of executing the Promotion and also for the purpose of publicizing the
      Promotion and the Organizer. It is hereby specified that the Organizer guarantees that publicity, entailing the
      sharing with third parties of personal data supplied as a result of participation in the Promotion, (including
      through television, the press, leaflets and other means and formats of communication) shall be made only with
      respect to the winners of the Prizes and will only indicate the given names and surnames of the winners, the
      location of their place of residence, their images and voices (contained in photographic records, audio, video and
      other types of records, made with the purposes of publicity connected to the Promotion). The winning Participants
      give, through their participation in the Promotion, express consent for the use of this data in formats herein
      defined. Following the termination of the Promotion, the personal data will be destroyed.
      In accordance with local laws and regulations, the Organizer respects the privacy of Participants of this
      Promotion, by which it is committed to the use of technology in a transparent form and in strict observance of the
      rights, freedom and guarantees of the citizens and by the reservation of the intimacy of their private and family
      life. The personal data collected within the scope of this Promotion will only be used for the purposes of the
      Promotion and will be treated as private and confidential. It shall not be disclosed to other individuals or
      organizations, except in the event of legislation to the contrary or where the Participants themselves authorize
      such. The data will be stored on servers with access controlled on a need-to-know basis and limited by passwords.
    </div>
  </div>
</template>

<script>
export default {
  name: "NigeriaTerms",
}
</script>

<style scoped>
.table-fit-screen {
  font-size: x-small !important;
}

</style>
