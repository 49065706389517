export function setSelectedRound(roundSelected) {
    localStorage.setItem('selectedRound', JSON.stringify(roundSelected))
}

export function getSelectedRound() {
    return localStorage.getItem('selectedRound')
}

export function setSelectedPosition(position) {
    localStorage.setItem('selectedPosition', JSON.stringify(position))
}

export function getSelectedPosition() {
    const position = localStorage.getItem('selectedPosition');
    return JSON.parse(position);
}
