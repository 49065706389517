export const encryptionKeyMap = new Map();

encryptionKeyMap.set('mtn-cg-fr', {
    encryptionKey: '2c66MTN5faNt4sy9',
    enrichmentUrl: 'http://mtncg-fun.upp.st/fanclash/fantasy-enrichment'
});
encryptionKeyMap.set('mtn-ci-fr', {
    encryptionKey: '7c18MTN8faNt4sy9',
    enrichmentUrl: 'http://mtnivorycoast.up.st/fanclash/fantasy-enrichment'
});
encryptionKeyMap.set('mtn-cmr-fr', {
    encryptionKey: '6c35MTN3faNt4sy1',
    enrichmentUrl: 'http://mtn-fun.cm/fanclash/fantasy-enrichment'
});
encryptionKeyMap.set('mtn-nga-en', {
    encryptionKey: '5n93MTN2faNt4sy1',
    enrichmentUrl: 'http://ng-app.com/fanclash/fantasy-enrichment'
});
encryptionKeyMap.set('ethiotel-et-amh', {
    encryptionKey: '03t6ETH4faNt4sy8',
    enrichmentUrl: 'http://et-ethio-web.upp.st/fanclash/fantasy-enrichment'
});
encryptionKeyMap.set('ethiotel-et-en', {
    encryptionKey: '03t6ETH4faNt4sy8',
    enrichmentUrl: 'http://et-ethio-web.upp.st/fanclash/fantasy-enrichment'
});
encryptionKeyMap.set('mtn-zam-en', {
    encryptionKey: '8g73MTN1faNt4sy7',
    enrichmentUrl: 'http://www.mtn-zm-webfun.com/fanclash/fantasy-enrichment'
});
encryptionKeyMap.set('air-nga-en', {
    encryptionKey: '3s42AIR5faNt4sy0',
    enrichmentUrl: 'http://ng-airtel-web.upp.st/fanclash/fantasy-enrichment'
});
