import store from "@/store";

export default function () {
    if (store.state.brandConfig.brand === "mtn-ci-fr") return "ci_mtn_upstream_icf_fantasy_sports"
    if (store.state.brandConfig.brand === "mtn-cmr-fr") return "cm_mtn_upstream_cmf_fantasy_sports"
    if (store.state.brandConfig.brand === "mtn-nga-en") return "ng_mtn_upstream_fantasy_sports"
    if (store.state.brandConfig.brand === "ethiotel-et-amh") return "0154222000014364"
    if (store.state.brandConfig.brand === "ethiotel-et-en") return "0154222000014364"
    if (store.state.brandConfig.brand === "air-nga-en") return "ng_airtel_rgs_naf_fantasy"
}
