<template>
  <div class="pb-5">
    <div
        class="d-flex flex-row justify-content-center align-items-center auto-team-builder-header-banner primary-bg-colour primary-text">
      <div>
        <img @click="backButtonClicked" :src="require(`../../brand/${brandConfig.brand}/assets/left-keyboard-arrow.png`)"
             class="go-back-key">
      </div>
      <div>
        {{ translationsFormatter('auto_team_builder_header_banner') }}
      </div>
    </div>
    <div
        class="d-flex flex-column text-center flex-wrap p-3 secondary-bg-colour primary-text auto-team-builder-description-container">
      {{ translationsFormatter('auto_team_builder_header_description', [brandConfig.brand]) }}
    </div>
    <div class="pt-5 how-it-works-container pb-4">
      <div class="how-it-works-header"> {{ translationsFormatter('auto_team_builder_how_it_works_header') }}</div>
      <div class="d-flex flex-row align-items-center how-it-works-bullet-point-containers">
        <div class="d-flex flex-row justify-content-center col-3">1.</div>
        <div class="col-8">{{ translationsFormatter('auto_team_builder_how_it_works_bullet_point_one') }}</div>
      </div>
      <div class="d-flex flex-row align-items-center how-it-works-bullet-point-containers">
        <div class="d-flex flex-row justify-content-center col-3">2.</div>
        <div class="col-8"> {{ translationsFormatter('auto_team_builder_how_it_works_bullet_point_two') }}</div>
      </div>
      <div class="d-flex flex-row align-items-center how-it-works-bullet-point-containers">
        <div class="d-flex flex-row justify-content-center col-3">3.</div>
        <div class="col-8"> {{ translationsFormatter('auto_team_builder_how_it_works_bullet_point_three') }}</div>
      </div>
      <div class="d-flex flex-row align-items-center how-it-works-bullet-point-containers">
        <div class="d-flex flex-row justify-content-center col-3">4.</div>
        <div class="col-8"> {{ translationsFormatter('auto_team_builder_how_it_works_bullet_point_four') }}</div>
      </div>
      <div class="d-flex flex-row align-items-center how-it-works-bullet-point-containers">
        <div class="d-flex flex-row justify-content-center col-3">5.</div>
        <div class="col-8"> {{ translationsFormatter('auto_team_builder_how_it_works_bullet_point_five') }}</div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center pt-3">
      <button v-if="!this.user.automatedEntries" @click="() => autoTeamSelectionButtonClicked(true)"
              class="col-10 primary-bg-colour primary-text primary-border-colour auto-team-selection-button">
        {{ translationsFormatter('auto_team_builder_selection_button') }}
      </button>
      <button v-if="this.user.automatedEntries" @click="() => autoTeamSelectionButtonClicked(false)"
              class="col-10 primary-bg-colour primary-text primary-border-colour auto-team-selection-button">
        {{ translationsFormatter('auto_team_builder_selection_button_remove') }}
      </button>
      <button @click="backButtonClicked" class="col-5 m-2 secondary-bg-colour primary-text auto-team-back-button">
        {{ translationsFormatter('auto_team_builder_back_button') }}
      </button>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import UserDataService from '@/services/userDataService'

export default {
  name: "AutoTeamBuilder",
  data() {
    return {}
  },
  beforeMount() {
    this.setIsLoading(false);
  },
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'user'])
  },
  methods: {
    ...mapMutations(['setRoundsEntered', 'setIsLoading', 'setUser']),
    async autoTeamSelectionButtonClicked(isOptIn) {
      if (isOptIn) await UserDataService.autoPickOptIn(this.user.token, this.user.msisdn, isOptIn);
      else await UserDataService.autoPickOptIn(this.user.token, this.user.msisdn, isOptIn);
      const userData = await UserDataService.getUserStatus(`${this.user.token}`);
      this.setUser(userData.data)
      this.$router.push('/profile');
    },
    backButtonClicked() {
      this.$router.push('/profile');
    },
  },
}
</script>

<style scoped>

.auto-team-builder-header-banner {
  position: relative;
  height: 45px;
  font-size: 18px;
  font-weight: 400;
}

.go-back-key {
  position: absolute;
  height: 20px;
  left: 14px;
  top: 13px;
}

.auto-team-builder-description-container {
  height: 70px;
  border-radius: 0 0 16px 16px;
  font-size: 14px;
  font-weight: 500;
  z-index: 1;
}

.how-it-works-container {
  position: relative;
  background-color: #e8e8e8;
  background-size: cover;
  margin-top: -12px;
  z-index: -1;
  border-radius: 0 0 12px 12px;
}

.how-it-works-header {
  padding-left: 12px;
  font-size: 12px;
  font-weight: 650;
}

.how-it-works-bullet-point-containers {
  background-color: white;
  height: 65px;
  width: 96%;
  border-radius: 8px;
  margin-left: 2%;
  margin-bottom: 5px;
  font-size: 11px;
  font-weight: 650;
}

.auto-team-selection-button {
  height: 45px;
  border-radius: 8px;
  border: 0;
  font-size: 14px;
  font-weight: 500;
}

.auto-team-back-button {
  border: 0;
  height: 32px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
}
</style>
