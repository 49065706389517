import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";

export default class GameDataService {
    static async activeRounds(msisdn) {
        return await axios.post(`${environmentConfig.fantasyHost}/soccer/get-upcoming-rounds`, {
            msisdn: msisdn
        })
    }

    static async getAutoSelectedTeam(roundId) {
        return await axios.get(`${environmentConfig.fantasyHost}/soccer/get-auto-entries/?roundId=${roundId}`)
    }

    static async upcomingFixtures() {
        return await axios.post(`${environmentConfig.fantasyHost}/fixtures/get-upcoming-fixtures`, {
            sportId: 1
        });
    }

    static async getRoundPlayers(pageNumber, playerPosition, roundId, rowsOfPage, userToken) {
        const formattedPosition = playerPosition === "Midfielder1" || playerPosition === "Midfielder2" ? "Midfielder" : playerPosition;
        return await axios.post(`${environmentConfig.fantasyHost}/soccer/get-round-players`, {
            pageNumber,
            position: formattedPosition,
            roundId,
            rowsOfPage,
            userToken
        })
    }

    static async getRoundDefences(roundId) {
        return await axios.post(`${environmentConfig.fantasyHost}/soccer/get-round-defences`, {
            roundId,
        })
    }

    static async getLeagues() {
        return await axios.post(`${environmentConfig.fantasyHost}/soccer/get-leagues`, {
            sportId: 1
        });
    }

    static async getLeagueTopPicks(leagueAbbreviation, season) {
        return await axios.post(`${environmentConfig.fantasyHost}/soccer/get-top-players-in-league`, {
            ref: leagueAbbreviation,
            seasonRef: season
        });
    }

    static async getResultedRounds(msisdn, pageNumber, rowsOfPage) {
        return await axios.post(`${environmentConfig.fantasyHost}/soccer/get-completed-rounds`, {
            msisdn: `${msisdn}`,
            pageNumber,
            rowsOfPage,
        })
    }

    static async getRoundLeaderboard(roundId, msisdn, userAccessId) {
        const response = await axios.post(`${environmentConfig.fantasyHost}/soccer/get-round-leaders`, {
            id: roundId,
            userAccessId,
            msisdn: `${msisdn}`
        })
        return response.data
    }

    static async getLeaderboard(msisdn) {
        const response = await axios.post(`${environmentConfig.fantasyHost}/profile/get-leaderboard`, {
            msisdn: msisdn,
            sportId: 1,
        })
        return response.data.recordsets
    }
}
