<template>
  <div class="main-t-c-container">
    <component :is="component" />
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {termsAndConditionsMap} from "@/brand/termsAndConditions";
import {shallowRef} from "vue";

export default {
  name: "TermsConditions",
  data() {
    return {
      component: undefined
    }
  },
  computed: {
    ...mapState(['brandConfig'])
  },
  methods: {
    ...mapMutations([])
  },
  beforeMount() {
    const tncs = termsAndConditionsMap.get(this.brandConfig.brand)
    this.component = shallowRef(tncs);
  }
}
</script>

<style scoped>
.main-t-c-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

</style>
