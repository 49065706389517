<template>
  <div v-if="sideBarVisible"
       class="d-flex flex-column p-4 col-10 col-sm-8 col-md-8 col-lg-7 col-xl-7 body-container primary-bg-colour primary-text">
    <div class="text-center user-icon">
      <img :src="require(`../../brand/${brandConfig.brand}/assets/user-icon.png`)"/>
    </div>
    <div class="user-container d-flex flex-column text-center p-2">
      <div class="user-name">{{ user.username }}</div>
      <div class="user-msisdn">{{ getMsisdn() }}</div>
    </div>
    <div class="d-flex flex-row pt-2 pb-2 how-to-play-container" @click="howToPlayClicked()">
      <div class="how-to-play-icon align-content-center">
        <img :src="require(`../../brand/${brandConfig.brand}/assets/question-icon.png`)"/>
      </div>
      <div class="d-flex flex-column justify-content-center how-to-play-text">
        {{ translationsFormatter('sidebar_menu_how_to_play_icon') }}
      </div>
    </div>
    <div v-if="showPrizesTab(brandConfig.brand)" class="d-flex flex-row pt-2 pb-2 prizes-container" @click="prizesClicked()">
      <div class="prizes-icon">
        <img :src="require(`../../brand/${brandConfig.brand}/assets/cup-icon.png`)"/>
      </div>
      <div class="d-flex flex-column justify-content-center prizes-text">
        {{ translationsFormatter('sidebar_menu_trophy_icon') }}
      </div>
    </div>
    <div class="d-flex flex-row pt-2 pb-2 terms-conditions-container" @click="tncsClicked()">
      <div class="terms-conditions-icon">
        <img :src="require(`../../brand/${brandConfig.brand}/assets/document-icon.png`)"/>
      </div>
      <div class="d-flex flex-column justify-content-center terms-conditions-text">
        {{ translationsFormatter('sidebar_menu_terms_conditions_icon') }}
      </div>
    </div>
    <div v-if="showMoreVodacom(brandConfig.brand)" class="d-flex flex-row pt-2 pb-2 soccer-news-container"
         @click="soccerNewsClicked()">
      <div class="soccer-news-icon">
        <img :src="require(`../../brand/${brandConfig.brand}/assets/soccer-news-icon.png`)"/>
      </div>
      <div class="d-flex flex-column justify-content-center soccer-news-text">
        {{ translationsFormatter('sidebar_menu_terms_soccer_news') }}
      </div>
    </div>
    <div v-if="showMoreVodacom(brandConfig.brand)">
      <div class="text-center pt-3 pb-2 more-games-container">
        <div class="more-games-text">
          {{ translationsFormatter('sidebar_menu_more_games_title') }}
        </div>
      </div>
      <div class="d-flex flex-row pt-2 pb-2 predictor-container" @click="predictorClicked()">
        <div class="predictor-icon">
          <img :src="require(`../../brand/${brandConfig.brand}/assets/finger-icon.png`)"/>
        </div>
        <div class="d-flex flex-column justify-content-center predictor-text">
          {{ translationsFormatter('sidebar_menu_predictor_icon') }}
        </div>
      </div>
      <div class="d-flex flex-row pt-2 pb-2 predictor-container" @click="freePlayPredictorClicked()">
        <div class="freeplay-icon">
          <img :src="require(`../../brand/${brandConfig.brand}/assets/icon-freeplay.png`)"/>
        </div>
        <div class="d-flex flex-column justify-content-center predictor-text">
          {{ translationsFormatter('sidebar_menu_freeplay_predictor_icon') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {environmentConfig} from "@/config/enviromentConfig";

export default {
  name: "SidebarMenu",
  data() {
    return {
      sideBarVisible: true,
    }
  },
  computed: {
    ...mapState(['user', 'brandConfig']),
  },
  methods: {
    showMoreVodacom(brand) {
      if (brand === 'vc-za-en') return true
    },
    showPrizesTab(brand) {
      return !(brand === 'ais-tai-en' || brand === 'demo-um-en');
    },
    tncsClicked() {
      if (this.brandConfig.brand === "vc-za-en") window.open(`https://termsandconditions.vodacomfantasy.com/?miniAppMode=fantasy&fantasyToken=${this.user.token}`, '_self');
      else this.$router.push('/terms-and-conditions');
    },
    predictorClicked() {
      window.open(`${environmentConfig.predictorHost}/?fantasyToken=${this.user.token}`, '_self');
    },
    freePlayPredictorClicked() {
      window.open(`${environmentConfig.freePlayPredictorHost}/?fantasyToken=${this.user.token}`, '_self');
    },
    howToPlayClicked() {
      this.sideBarVisible = false;
      this.$router.push('/how-to-play');
    },
    prizesClicked() {
      this.sideBarVisible = false;
      this.$router.push('/prizes');
    },
    soccerNewsClicked() {
      window.open(`https://vodacomsoccer.com`, '_self');
    },
    getMsisdn() {
      return '0' + this.user.msisdn.substring(2);
    },
  },
}
</script>

<style scoped>
.body-container {
  position: fixed;
  width: 81vw;
  height: 100vh;
  z-index: 5;
  border-radius: 0 0 0 0;
}

.user-icon img {
  height: 70px;
}

.user-name {
  font-size: 25px;
  font-weight: bold;
}

.user-msisdn {
  font-size: 15px;
  font-weight: lighter;
}

.how-to-play-icon img, .prizes-icon img, .terms-conditions-icon img, .rugby-icon img, .predictor-icon img, .soccer-news-icon img {
  margin-left: 2vw;
  height: 40px;
  width: 40px;
  justify-content: center;
}

.freeplay-icon img {
  margin-left: 1.5vw;
  width: 40px;
}

.how-to-play-text, .prizes-text, .terms-conditions-text, .predictor-text, .soccer-news-text {
  margin-left: 8vw;
  font-size: 14px;
  font-weight: bold;
}

.more-games-text {
  font-weight: bold;
  font-size: 20px;
}

.how-to-play-container, .prizes-container, .terms-conditions-container, .predictor-container, .soccer-news-container {
  border-bottom: 1px solid white;
}

@media (min-width: 750px) {
  .body-container {
    position: fixed;
    width: 72vw;
    height: 100vh;
    z-index: 5;
    border-radius: 0 0 0 0;
  }
}

@media (min-width: 1000px) {
  .body-container {
    position: fixed;
    width: 65vw;
    height: 100vh;
    z-index: 5;
    border-radius: 0 0 0 0;
  }
}

</style>
