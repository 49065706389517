<template>
  <div class="container-fluid d-flex flex-column">
    <h4 class="d-flex mt-3">
      MTN Fantasy Football Terms & Conditions
    </h4>
    <div class="d-flex mt-3">
      1. The terms of this agreement (“Terms and Conditions”) govern the relationship between You and MTN and its
      affiliates (hereinafter “MTN” or “Us” or “We”) regarding your use of MTN Fantasy Football Service and related
      features (the “Service”).
    </div>
    <div class="d-flex mt-3">
      <b>Your Acceptance of these Terms and Conditions</b>
    </div>
    <div class="d-flex px-2">
      2. MTN and its appointed partners (Upstream Systems) are the provider of the Fantasy Football Service (the
      “Service”), in its various formats accessible via mobile devices. Please read these Terms and Conditions carefully
      before using the Service or submitting information through the Service. By subscribing to the Service, You are
      agreeing to these Terms and Conditions. If You do not agree, please do not subscribe to this Service or submit any
      information through the Service. We reserve the right, at any time, to modify and update these Terms and
      Conditions from time to time by posting such updated Terms and Conditions on the MTN Fantasy Football website. Any
      such updates shall apply to You and your use of the Service and by continuing to use the Service, You shall be
      deemed to have accepted such changes.
    </div>
    <div class="d-flex mt-3">
      <b>General</b>
    </div>
    <div class="d-flex px-2">
      3. All subscribers must be above 18 years old. Any subscriber under the age of 18 shall present their
      parent/guardian’s written consent at Service Provider’s request.
    </div>
    <div class="d-flex px-2">
      4. As a Subscriber, You will be asked to submit personal information to us. We are committed to ensuring
      responsible practices in the collection, use and disclosure of your personal information in accordance with
      applicable law. You can read more details about the personal data processing activities in Sections 57-63 below.
      If You do not agree with our Terms and Conditions, please do not provide us with any personal information, and
      please do not use the Service.
    </div>
    <div class="d-flex px-2">
      5. By Subscribing, you agree to the following additional rules:
    </div>
    <div class="d-flex px-3">
      a. Only one subscription is permitted per mobile number. Only MTN users within the Republic of Zambia may
      subscribe.
    </div>
    <div class="d-flex px-3">
      b. You give us consent to provide you with various messages about / based on your interaction with the Service.
      These messages will be delivered to you in the form of text messages sent to your mobile device using the mobile
      number identifying you as a user of the Service.
    </div>
    <div class="d-flex px-3">
      c. These messages include, but are not limited to:
    </div>
    <div class="d-flex px-4">
      i. periodic messages to encourage, or remind you to browse or access the Service;
    </div>
    <div class="d-flex px-4">
      ii. messages inviting you to try alternate versions of the Service based on MTN’s knowledge of the device you are
      using to access the Service;
    </div>
    <div class="d-flex px-4">
      iii. confirmation messages relating to your subscribing for, or cancellation of, the Service;
    </div>
    <div class="d-flex px-4">
      iv. messages with Service content, including, but not limited to, confirmation of weekly results and suggested
      picks for the upcoming game week, and;
    </div>
    <div class="d-flex px-4">
      v. messages relating to the earning, notification and servicing of rewards.
    </div>
    <div class="d-flex px-2">
      6. You accept that the Service’s default language is English.
    </div>
    <div class="d-flex px-2">
      7. You may cancel the Service in its entirety at any time via the Service menu. When cancelling the Service, You
      accept that your old profile and preferences will be retained for a 6 (six) month period and for no longer than 5
      years (only if necessary), for the purpose of audit trail, and in order to facilitate a convenient experience
      should You choose to re-subscribe at a later stage within the six-month period.
    </div>
    <div class="d-flex mt-3">
      8. You acknowledge that your subscription information will be retained for use to allow you access to future
      iterations and new features of the Service, and that You will be engaged to continue using the Service as
      applicable for other soccer competitions intended to be covered by the Service.
    </div>
    <div class="d-flex mt-3">
      <b>How to Subscribe to the service</b>
    </div>
    <div class="d-flex mt-3">
      9. You can subscribe to the service by:
    </div>
    <div class="d-flex px-2">
      • Dialling *755#.
    </div>
    <div class="d-flex px-2">
      • Sending SUBD, SUBW, SUBM to 755, depending on the plan you wish to subscribe.
    </div>
    <div class="d-flex px-2">
      • WEB recruitment campaigns that will be promoting the specific service.
    </div>
    <div class="d-flex mt-3">
      <b>How to Cancel your Subscription</b>
    </div>
    <div class="d-flex mt-3">
      10. You can Cancel your subscription to the service by:
    </div>
    <div class="d-flex px-2">
      • Sending STOPD to 755 for the Daily scheme.
    </div>
    <div class="d-flex px-2">
      • Sending STOPW to 755 for the Weekly scheme.
    </div>
    <div class="d-flex px-2">
      • Sending STOPM to 755 for the Monthly scheme.
    </div>
    <div class="d-flex px-2">
      • Following the in-app instructions
    </div>
    <div class="d-flex mt-3">
      <b>Provisions regarding Subscriptions</b>
    </div>
    <div class="d-flex mt-3">
      11. Subscribers are given exclusive access to the Service on the basis of paying for one of the below subscription
      plans:
    </div>
    <div class="d-flex px-2">
      • Daily Subscription at K1 per day.
    </div>
    <div class="d-flex px-2">
      • Weekly Subscription at K4per week.
    </div>
    <div class="d-flex px-2">
      • Monthly Subscription at K15 per month.
    </div>
    <div class="d-flex ">
      By subscribing to the Service, You agree that Fallback billing may apply for the above subscription schemes.
    </div>
    <div class="d-flex mt-3">
      12. You acknowledge that the Service covers only the teams, players and tournaments as it has the applicable
      rights to cover at that point in time.
    </div>
    <div class="d-flex mt-3">
      13. Unless otherwise specified, the Service only covers official domestic and international soccer competitions,
      which may include the relevant league, cup and continental competitions as arranged by the relevant governing
      football authorities for the leagues which the Service covers, and / or has the appropriate rights to cover. You
      acknowledge that the composition and structure of these tournaments is outside of MTN and its appointed partner’s
      control.
    </div>
    <div class="d-flex mt-3">
      14. You acknowledge that any subscriptions made by you in error will be billed. For your protection, we ask You to
      confirm all purchases twice.
    </div>
    <div class="d-flex mt-3">
      <b>Rewards</b>
    </div>
    <div class="d-flex mt-3">
      15. Upon the first Subscription, users will receive as a reward the below Data Bundle, according to the
      subscription scheme:
    </div>
    <div class="d-flex px-2">
      • Daily Subscription: 5MB
    </div>
    <div class="d-flex px-2">
      • Weekly Subscription: 10MB
    </div>
    <div class="d-flex px-2">
      • Monthly Subscription: 20MB
    </div>
    <div class="d-flex mt-3">
      16. AFCON Competition. MTN is running the AFCON competition where 150 customers stand a chance to receive 4GB and
      1 customer stands a chance to receive a grand prize of K2000 cash via Mobile Money at the end of the promotional
      period. The Competition is open to all MTN customers that are subscribed to MTN Fantasy Football and it is valid
      from 00h01 on the 15th of January 2024 (the “Start Date”) to 23h59 on the 12th of February 2024 (the “End Date”).
      The prizes will be allocated to the winners after a random draw.
    </div>
    <div class="d-flex mt-3">
      17. To be eligible for the above reward from the service, You acknowledge that You need to have an active MTN
      Fantasy Football subscription.
    </div>
    <div class="d-flex mt-3">
      18. You acknowledge, understand and agree that your subscription to the Service does not guarantee any prizes from
      the Service. We shall not have any liability for such loss or damage in the course of using the Service.
    </div>
    <div class="d-flex mt-3">
      19. Points scored are based on the statistics from the data feed provided by our third party statistics provider
      (“Statistics Provider”). Although We use our best endeavours to ensure a good reputable Service, we are solely
      reliant on the information provided by the Statistics Provider and we are in no way responsible for faulty data
      received from the Statistics Provider.
    </div>
    <div class="d-flex mt-3">
      20. We wait for a certain period to elapse, to confirm the winner(s) of a Fantasy Football event after the final
      game in the Fantasy Football event is completed so that the Statistics Provider can correct any errors in its
      system first.
    </div>
    <div class="d-flex mt-3">
      <b>Game Rules</b>
    </div>
    <div class="d-flex mt-3">
      21. MTN Fantasy Football games are games of skill and require some skill and knowledge of professional sports
      information as well as our rules for you to accumulate fantasy points.
    </div>
    <div class="d-flex mt-3">
      22. Fantasy points are accumulated through the performance of real-life athletes across multiple sporting matches.
    </div>
    <div class="d-flex mt-3">
      <b>Fantasy Football Game Ruless</b>
    </div>
    <div class="d-flex mt-3">
      23. Each fantasy line-up must include real-life athletes, so that each entry into a Fantasy Football event shall
      be made up of players drawn from a minimum of one real-life sporting matches.
    </div>
    <div class="d-flex mt-3">
      24. We strictly prohibit the winning of any Fantasy Football event based solely on the single performance of an
      individual athlete.
    </div>
    <div class="d-flex mt-3">
      25. The Service will automatically prevent You from saving a line-up that is in violation of any of these
      restrictions.
    </div>
    <div class="d-flex mt-3">
      26. There will be no trading of players, no extra moves and no replacing injured or benched players.
    </div>
    <div class="d-flex mt-3">
      27. Each Fantasy Football event requires selecting one striker, two midfielders, one defensive unit (consisting of
      players listed as defensive players by the stats provider in a specific team) and a goalkeeper.
    </div>
    <div class="d-flex mt-3">
      28. The player pool consists of players that the Statistics Provider has statistics for in that particular league
      and determines to be eligible for the matches included in the Fantasy Football event.
    </div>
    <div class="d-flex mt-3">
      29. Any players left off any squad by the Statistics Provider (“missing” players) will only be added once the
      Statistics Provider recognizes and/or adds the new player and we shall not be liable in any way for such player
      not being featured in any Fantasy Football event.
    </div>
    <div class="d-flex mt-3">
      230. Each Fantasy Football event specifies a date after which entries may not be added, edited, or cancelled.
    </div>
    <div class="d-flex mt-3">
      31. Fantasy Football event results and winners will be based on the total points scored across each entrant’s
      squad (scoring summarized below) during the duration of the Fantasy Football event.
    </div>
    <div class="d-flex mt-3">
      32. If a match that is part of a Fantasy Football event is rescheduled outside of the originally scheduled
      beginning and end of the Fantasy Football event, players in those matches will earn zero points.
    </div>
    <div class="d-flex mt-3">
      33. You can edit Your line-up at any point up to the start of a Fantasy Football event by clicking on the edit
      option on the entry view. HOWEVER, once a Fantasy Football event starts, your line-up will no longer be editable.
    </div>
    <div class="d-flex mt-3">
      34. Users of the Service will have the ability to enter into Fantasy Football events as long as their subscription
      to the content is active.
    </div>
    <div class="d-flex mt-3">
      35. We reserve the right to cancel any Fantasy Football event before it is scheduled to begin.
    </div>
    <div class="d-flex mt-3">
      36. We reserve the right to combine player pools should the number of users in a pool be too low for reasonable
      levels of participation.
    </div>
    <div class="d-flex mt-3">
      <b>Game Rules Pertaining to the Auto-Pick Team Feature:</b>
    </div>
    <div class="d-flex mt-3">
      37. When selecting a team to enter into a contest a user may use the auto-pick team feature. This feature will
      assist customers in picking a team through auto-picking a random selection of players. The random selection of
      players per position is done completely at random and no previous selections or results have an impact on the
      auto-picks that are generated.
    </div>
    <div class="d-flex mt-3">
      38. The auto-pick feature will only make picks from players that are in teams that are playing in the specific
      contest.
    </div>
    <div class="d-flex mt-3">
      39. Only players who have scored at least one (1) fantasy point in MTN Fantasy Football will be included in the
      randomisation by the auto-pick feature.
    </div>
    <div class="d-flex mt-3">
      40. The auto-pick feature will randomly select one (1) forward, two (2) midfielders, one (1) defence and one (1)
      goalkeeper or such positions that remain unfilled at the time that a user selects the auto-pick button.
    </div>
    <div class="d-flex mt-3">
      41. A team that has been auto selected by the auto-pick feature can at any time prior to the beginning of the
      contest be edited by deleting the entry and re-selecting a new team. Either by generating another team through the
      auto-pick feature or manually selecting a new team.
    </div>
    <div class="d-flex mt-3">
      42. There is no limit on the number of auto-picks a user can do when selecting a team. When the auto-pick button
      is selected a new team will be generated. An entry will only be confirmed once a captain has been selected by the
      user and the team has been entered by selecting the “confirm” button on the contest entry.
    </div>
    <div class="d-flex mt-3">
      43. All existing Fantasy Football rules apply to entries selected by the auto-pick feature.
    </div>
    <div class="d-flex mt-3">
      <b>Game Rules Pertaining to the Auto-Entries Feature:</b>
    </div>
    <div class="d-flex mt-3">
      44. Users may enable the auto entry feature on their profile at any stage. When enabled the Fantasy Football
      platform will auto-pick a team and a captain to be entered into every available contest.
    </div>
    <div class="d-flex mt-3">
      45. Teams will be randomly selected in accordance with the rules of the auto-pick feature. Captains for
      auto-entries will be randomly selected.
    </div>
    <div class="d-flex mt-3">
      46. An entry that has been created by the auto-entry feature can be edited by a user prior to the beginning of the
      first game in a contest.
    </div>
    <div class="d-flex mt-3">
      47. Users who have teams that win through the auto-entry feature will receive an SMS notification to notify them
      of their winnings.
    </div>
    <div class="d-flex mt-3">
      48. All other rules pertaining to Fantasy Football contests, entries and prizes apply to entries generated using
      the auto-entry feature.
    </div>
    <div class="d-flex mt-3">
      49. MTN Fantasy Football encourages users to check their auto-entered teams prior to contests starting to ensure
      they have a team entered and they agree with the random selections that have
    </div>
    <div class="d-flex mt-3">
      <b>Ownership</b>
    </div>
    <div class="d-flex mt-3">
      50. All rights, title and interest in and to the Service including, but not limited to, any games, titles,
      computer code, themes, objects, stories, dialogue, concepts, artwork, animations, sounds, musical compositions,
      audio-visual effects, methods of operation, moral rights, documentation are owned by MTN and its appointed
      partner. MTN and its appointed partner reserve all rights, including, but not limited to, all intellectual
      property rights or other proprietary rights, in connection with the Service.
    </div>
    <div class="d-flex mt-3">
      51. MTN and its appointed partner owns, has licensed, or otherwise has rights to use, or provide all of the
      content that appears in the Service.
    </div>
    <div class="d-flex mt-3">
      <b>Your Obligations and Entitlements</b>
    </div>
    <div class="d-flex mt-3">
      52. When subscribing to the Service, you warrant that You are the account holder and are thus authorized to
      subscribe to and use the Service. You acknowledge that continued use of the Service warrants consent for MTN to
      provide You with access, and to bill the subscription in part or in full. If you do not agree with our Terms and
      Conditions please do not subscribe, and please do not use the Service.
    </div>
    <div class="d-flex mt-3">
      53. MTN and its appointed partner reserves the right to determine what conduct it considers to be in violation of
      the rules of use or otherwise outside the intent or spirit of these Terms and Conditions or the Service itself. We
      reserve the right to take action as a result, which may include, if relevant, terminating your registration and
      prohibiting You from using the Service. Said termination and prohibition may occur with or without notice to You,
      and as a result may involve the loss of any benefits, privileges or earned items associated with your use of the
      Service, and we are under no obligation to compensate You for any such losses or results.
    </div>
    <div class="d-flex mt-3">
      <b>
        Limitation of Liability
      </b>
    </div>
    <div class="d-flex mt-3">
      54. You agree that your use of the services shall be at your sole risk. To the fullest extent permitted by law, We
      and our officers, directors, employees, and agents disclaim all warranties, express or implied, in connection with
      the services and your use thereof. We make no warranties or representations about the accuracy or completeness of
      the services’ content or the content of any websites or services linked to these services and assume no liability
      or responsibility for any (i) errors, mistakes, or inaccuracies of content; (ii) personal injury or property
      damage of any nature whatsoever resulting from your access to and use of the services; (iii) any unauthorized
      access to or use of our servers and/or any and all personal information and/or financial information stored
      therein; (iv) any interruption or cessation of transmission to or from the services; (v) any bugs, viruses, trojan
      horses or the like which may be transmitted to or through the services by any third party; or (vi) any errors or
      omissions in any content or for any loss or damage of any kind incurred as a result of the use of any content
      posted, emailed, transmitted, or otherwise made available via the services. We do not warrant, endorse, guarantee,
      or assume responsibility for any product or service advertised or offered by a third party through the services or
      any hyperlinked website or featured in any banner or other advertising, and We will not be a party to or in any
      way be responsible for monitoring any transaction between you and third-party providers of products or services.
    </div>
    <div class="d-flex mt-3">
      55. To the extent permitted by applicable law, in no event shall We or our subsidiaries and affiliates, and their
      respective officers, directors, owners, agents, employees, representatives and licensors be liable for any
      special, incidental, indirect, consequential, punitive or exemplary losses or damages whatsoever or for loss of
      profits (including, without limitation, damages for loss of revenue, loss of data, failure to realize expected
      savings, interruption of activities, or any other pecuniary or economic loss) and whether arising from breach of
      contract, damages (including negligence), strict liability or otherwise, arising out of the use of or inability to
      use these services or its content, or any product or service described or provided through the services.
    </div>
    <div class="d-flex mt-3">
      56. Save to the extent permitted by law, You agree to indemnify, defend and hold harmless MTN from and against any
      and all claims, demands, actions, liability, losses, costs and expenses (including legal fees and expenses)
      arising from or related to: (i) any use or alleged use of the Service by any other person, whether or not
      authorized by You; (ii) your breach of these Terms and Conditions, including any breach of your representations
      and warranties herein; and (iii) your violation of any law or (iv) your use of or inability to use the Service,
      the contents, any hyperlinked website, and any of the products and services made available on the Service thereof.
    </div>
    <div class="d-flex mt-3">
      <b>
        How to contact us
      </b>
    </div>
    <div class="d-flex mt-3">
      57. Your opinion matters to us – if You have any questions about the service, You can email us at:
      zmfootball-help@upstreamsystems.com
    </div>
    <div class="d-flex mt-3">
      <b>
        Personal information we collect about you
      </b>
    </div>
    <div class="d-flex px-2">
      58. In order to enable You to make use of the Service and provide the service through the application, MTN Fantasy
      Football collects the below information about yours:
    </div>
    <div class="d-flex px-3">
      a. MSISDN (Mobile Number) – This is used to manage and provide the service, to authenticate the user via OTPs (one
      time PINs), and to subscribe and bill the user as it relates to the service provision;
    </div>
    <div class="d-flex px-3">
      b. User Name - this is used to communicate with you by a name specified by you, where appropriate or required to
      do so, as well as to identify you on leader boards in the service;
    </div>
    <div class="d-flex px-3">
      b. You give us consent to provide you with various messages about / based on your interaction with the Service.
      These messages will be delivered to you in the form of text messages sent to your mobile device using the mobile
      number identifying you as a user of the Service.
    </div>
    <div class="d-flex px-3">
      c. Information on how you use the service, such as the date, time and length of your usage
    </div>
    <div class="d-flex px-3">
      d. Consent management – this is used to obtain permission for:
    </div>
    <div class="d-flex px-4">
      1. Subscription and billing for the service
    </div>
    <div class="d-flex px-4">
      2. Sending related messages to you
    </div>
    <div class="d-flex px-2">
      59. We might also use cookies (small text files stored in your browser) and other techniques such as web beacons
      (small, clear picture files used to follow your movements on our website). These collect information that tells us
      how You use our websites, web-related products and services. This, in turn, helps us make our website relevant to
      your interests and needs. We may use a persistent cookie (a cookie that stays linked to your browser) to record
      your details so we can recognise You if You visit our website again. See the next section for more details. You
      can choose to refuse cookies, or set your browser to let you know each time a website tries to set a cookie.
    </div>
    <div class="d-flex px-2">
      59. Personal information We collect about You - We collect your personal information when:
    </div>
    <div class="d-flex px-3">
      a. You subscribe to the service;
    </div>
    <div class="d-flex px-3">
      b. You use the service.
    </div>
    <div class="d-flex px-2">
      61. MTN will process your personal information based on:
    </div>
    <div class="d-flex px-3">
      a. MTN’s obligation – to provide You with the service You have asked for;
    </div>
    <div class="d-flex px-3">
      b. MTN’s legitimate business interests – when it comes to developing and improving the service to gain insights
      through app analytics;
    </div>
    <div class="d-flex px-3">
      c. Your communication preferences.
    </div>
    <div class="d-flex px-2">
      62. How we use your personal information
    </div>
    <div class="d-flex px-3">
      a. Your personal information is collected for the purpose of being used and processed in:
    </div>
    <div class="d-flex px-4">
      i. providing the Service, enabling your subscription, and billing your account;
    </div>
    <div class="d-flex px-4">
      ii. personalising the Service to your respective preferences,
    </div>
    <div class="d-flex px-4">
      iii. for the serving of appropriate, tailored advertising to you via the Service,
    </div>
    <div class="d-flex px-4">
      iv. for the purpose of tracking the Service’s performance,
    </div>
    <div class="d-flex px-4">
      v. troubleshooting, data analysis, testing, research and service improvement and/or to identify any technical
      issues that may occur from time to time,
    </div>
    <div class="d-flex px-4">
      vi. for use in devising additional enhancements or improvements to the Service,
    </div>
    <div class="d-flex px-4">
      vii. statistical, tracking, redundancy and audit purposes; and
    </div>
    <div class="d-flex px-4">
      viii. Contacting you with offers or promotions for similar, related services (you will be afforded the opportunity
      to opt out of those messages specifically should they be sent to you).
    </div>
    <div class="d-flex px-3">
      b. We may share information about You with:
    </div>
    <div class="d-flex px-4">
      i. providing the Service, enabling your subscription, and billing your account;
    </div>
    <div class="d-flex px-4">
      ii. partners or agents involved in delivering the Services;
    </div>
    <div class="d-flex px-4">
      iii. companies who are engaged to perform Service for, on behalf of MTN (including Upstream Mobile Commerce
      Limited;
    </div>
    <div class="d-flex px-4">
      iv. law enforcement agencies, regulatory organisations, courts or other public authorities if we have to, or are
      authorised to by law
    </div>
    <div class="d-flex px-2">
      63. How long we keep your personal information for:
    </div>
    <div class="d-flex px-3">
      a. The personal information processed in connection with MTN Fantasy Football will be retained for a period of 5
      years for audit purposes should You choose to cancel your account.
    </div>
    <div class="d-flex px-3">
      b. MTN may retain aggregated and statistical reports about use of the MTN Fantasy Football Application but these
      reports will not identify You as a user.
    </div>
    <div class="d-flex px-2">
      64. Keeping your personal information secure:
    </div>
    <div class="d-flex px-3">
      a. We have specialised security teams who constantly review and improve our measures to protect your personal
      information from unauthorised access, accidental loss, disclosure or destruction.
    </div>
    <div class="d-flex px-3">
      b. If We have a contract with another organisation to provide Us with services or a service on our behalf to
      process your personal information, we will make sure they have appropriate security measures and only process your
      information in the way We’ve authorised them to. These organisations will not be entitled to use your personal
      information for their own purposes. On a regular basis, our security teams will check them to make sure they meet
      the security requirements we have set.
    </div>
    <div class="d-flex justify-content-center align-items-center mt mt-3 mb-5" @click="onReturnToPreviousPageClicked">
      <b><u>Return to previous page</u></b>
    </div>
  </div>
</template>

<script>
export default {
  name: "ZambiaTerms",
  methods: {
    onReturnToPreviousPageClicked() {
      this.$router.push(`/profile`)
    }
  }
}
</script>

<style scoped>
.table-fit-screen {
  font-size: x-small !important;
}

.return-previous {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

</style>
