<template>
  <div class="d-flex flex-column body-container">
    <VodacomSouthAfricaPrizes v-if="brandConfig.brand === 'vc-za-en'"/>
    <EthiotelEthiopiaPrizes v-if="brandConfig.brand === 'ethiotel-et-en' || brandConfig.brand === 'ethiotel-et-amh'"/>
    <MtnCameroonPrizes v-if="brandConfig.brand === 'mtn-cmr-fr'"/>
    <MtnIvoryCoastPrizes v-if="brandConfig.brand === 'mtn-ci-fr'"/>
    <MtnNigeriaPrizes v-if="brandConfig.brand === 'mtn-nga-en'"/>
    <MtnZambia v-if="brandConfig.brand === 'mtn-zam-en'"/>
    <MtnNigeriaPrizes v-if="brandConfig.brand === 'air-nga-en'"/>
    <MtnCongo v-if="brandConfig.brand === 'mtn-cg-fr'"/>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import VodacomSouthAfricaPrizes from "@/views/config/prizeList/VodacomSouthAfricaPrizes";
import EthiotelEthiopiaPrizes from "@/views/config/prizeList/EthiotelEthiopiaPrizes";
import MtnCameroonPrizes from "@/views/config/prizeList/MtnCameroonPrizes";
import MtnIvoryCoastPrizes from "@/views/config/prizeList/MtnIvoryCoastPrizes";
import MtnNigeriaPrizes from "@/views/config/prizeList/MtnNigeriaPrizes";
import MtnZambia from "@/views/config/prizeList/MtnZambia";
import MtnCongo from "@/views/config/prizeList/MtnCongo";

export default {
  name: "PrizesList",
  components: {
    MtnIvoryCoastPrizes,
    MtnCameroonPrizes,
    VodacomSouthAfricaPrizes,
    EthiotelEthiopiaPrizes,
    MtnNigeriaPrizes,
    MtnZambia,
    MtnCongo
  },
  data() {
    return {
      vodacomEnglishSouthAfrica: undefined,
      mtnEnglishNigeria: undefined,
      ethiotelEthiopia: undefined
    }
  },
  computed: {
    ...mapState(['isLoading', 'brandConfig'])
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    mobileCommunications() {
      if (this.brandConfig.brand === 'vc-za-en') this.vodacomEnglishSouthAfrica = true;
      else if (this.brandConfig.brand === 'mtn-nga-en') this.mtnEnglishNigeria = true;
      else if (this.brandConfig.brand === 'ethiotel-et-en' || this.brandConfig.brand === 'ethiotel-et-amh') this.ethiotelEthiopia = true;
    },
  },
  beforeMount() {
    this.mobileCommunications()
  }
}
</script>

<style scoped>
.body-container {
  background-color: #e8e8e8;
  overflow-y: scroll;
  padding-bottom: 50px;
}

.body-container::-webkit-scrollbar {
  display: none;
}

</style>
