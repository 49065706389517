import {environmentConfig} from "@/config/enviromentConfig";
import axios from "axios";

const headers = {Authorization: `Basic UGVucm9zZV9HYW1pbmc6Qm83c2pIeVQ4MGdB`};

export default class DcbService {
    static async getUserViaProductId(msisdn, productId) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/get-user-via-product-id`, {
                    msisdn,
                    productId: productId,
                    externalRef: 12335642,
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async encryptUserMsisdn(msisdn) {
        try {
            const response = await axios.post(`${environmentConfig.dcbService}/encrypt-msisdn`, {
                msisdn,
            }, {headers});
            return response.data;
        } catch (error) {
            return {authError: true, error: error.message};
        }
    }
}
