<template>
  <div class="body-container">
    <div class="d-flex flex-row align-items-center pb-2">
      <img class="pt-3 col-10 header-navbar-logo"
           :src="require(`../../brand/${brandConfig.brand}/assets/main-fantasy-header.png`)">
      <div v-if="this.brandConfig.brand === 'ethiotel-et-amh' || this.brandConfig.brand === 'ethiotel-et-en'"
           class="d-flex justify-content-center align-items-center border-1 primary-bg-colour language">
        <span @click="onLanguageClicked" class="secondary-text"
              v-if="this.brandConfig.brand === 'ethiotel-et-amh' || this.brandConfig.brand === 'ethiotel-et-en'">{{
            getLanguage()
          }}</span>
      </div>
    </div>
    <div class="d-flex flex-column text-center justify-content-center primary-bg-colour primary-text header-container">
      {{ translationsFormatter('login_header') }}
    </div>
    <div
        class="d-flex flex-column text-center justify-content-center align-items-center secondary-bg-colour primary-text main-container">
      <img class="fantasy-logo" :src="require(`../../brand/${brandConfig.brand}/assets/fantasy-logo-icon.png`)">
      <div class="pb-1 ">{{ translationsFormatter('login_main_fantasy') }}</div>
    </div>
    <div class="pt-2 background-behind">
      <br>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center text-center pt-2 otp-input-container">
      <input v-on:keyup.enter="confirmButtonClicked" class="col-10 text-center otp-input-box" type="tel"
             v-model="userOTP" :placeholder="translationsFormatter('otp_input_placeholder')">
      <div class="col-10 mt-2 otp-info-text">{{ translationsFormatter('otp_not_received') }}</div>
      <div @click="requestOTP" class="col-10 resend-otp-text">{{ translationsFormatter('otp_resend') }}</div>
      <div v-if="invalidOtp" class="col-10 mt-2 mb-1 login-info-text text-red">{{
          translationsFormatter('invalid_otp')
        }}
      </div>
      <div v-if="isOtpExpired" class="col-10 mt-2 mb-1 login-info-text text-red">{{
          translationsFormatter('expired_otp')
        }}
      </div>
      <div v-if="otpUsed" class="col-10 mt-2 mb-1 login-info-text text-red">{{ translationsFormatter('otp_used') }}
      </div>
      <div v-if="isOtpCountExceeded" class="col-10 mt-2 mb-1 login-info-text text-red">{{
          translationsFormatter('otp_limit')
        }}
      </div>
      <button @click="confirmButtonClicked"
              class="col-10 mt-2 mb-3 text-center primary-bg-colour primary-text otp-confirm-button">
        {{ translationsFormatter('otp_button') }}
      </button>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import LoginService from "@/services/loginService";
import UserDataService from "@/services/userDataService";
import {setNewBrandConfigLanguage} from "@/functions/setBrandConfig";

export default {
  name: "SubmitOTP",
  data() {
    return {
      userOTP: undefined,
      invalidOtp: false,
      isOtpExpired: false,
      otpUsed: false,
      isOtpCountExceeded: false,
    }
  },
  computed: {
    ...mapState(['brandConfig', 'userMsisdn'])
  },
  methods: {
    ...mapMutations(['setUser', 'setUserStats', 'setIsLoading']),
    async confirmButtonClicked() {
      await this.validateOTP();
    },

    async requestOTP() {
      this.userOTP = undefined;
      this.invalidOtp = false;
      this.isOtpExpired = false;
      this.isOtpCountExceeded = false;
      this.otpUsed = false;
      const msisdn = this.userMsisdn;

      await LoginService.sendOTP(msisdn, this.brandConfig.revenuePartnerId);
    },
    async validateOTP() {
      this.invalidOtp = false;
      const otp = this.userOTP;
      const msisdn = this.userMsisdn;
      const response = await LoginService.validateOTP(msisdn, otp);
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);

        const user = await UserDataService.getUserStatus(response.data.token);
        const stats = await UserDataService.getUserStats(user.data.msisdn);
        this.setUser(user.data);
        this.setUserStats(stats.data);
        if (user.data.grantAccess) this.$router.push(`/profile`);
        else {
          if (user.data.message) this.$router.push('/login');
          else this.$router.push('/subscription');
        }
      } else {
        if (response.data.otpUsed) {
          this.otpUsed = true;
          this.setIsLoading(false);
        } else if (response.data.otpAttemptCount) {
          if (response.data.otpAttemptCount > 1) this.isOtpCountExceeded = true;
          if (response.data.otpAttemptCount > 0 && response.data.otpAttemptCount < 2) this.invalidOtp = true;
          this.setIsLoading(false);
        } else if (response.data.expiredOTP) {
          this.isOtpExpired = true;
          this.setIsLoading(false);
        } else this.invalidOtp = true;
      }
    },
    onLanguageClicked() {
      setNewBrandConfigLanguage(this.brandConfig.brand)
      if (this.brandConfig.brand === 'ethiotel-et-en') this.language = 'AMH'
      if (this.brandConfig.brand === 'ethiotel-et-amh') this.language = 'EN'
    },
    getLanguage() {
      if (this.brandConfig.brand.includes('en')) return 'EN'
      if (this.brandConfig.includes('amh')) return 'AMH'
    }
  },
  beforeMount() {
    if (this.brandConfig.brand === 'ethiotel-et-amh') this.language = 'AMH'
    if (this.brandConfig.brand === 'ethiotel-et-en') this.language = 'EN'
  },
}
</script>

<style scoped>
.language {
  padding: 5px;
  margin-top: 15px;
}

.text-red {
  color: red;
}

.body-container {
  position: fixed;
}

.header-container {
  font-weight: 500;
  height: 40px;
  font-size: 14px;
}

.main-container {
  font-weight: 500;
  height: 200px;
  font-size: 28px;
  border-radius: 0 0 18px 18px;
  z-index: 1;
}

.fantasy-logo {
  height: 120px;
}

.background-behind {
  background-color: #e8e8e8;
  margin-top: -15px;
  z-index: -1;
  position: relative;
}

.otp-input-container {
  background-color: #e8e8e8;
  padding-bottom: 200px;
}

.otp-info-text {
  font-weight: 500;
  font-size: 14px;
}

.otp-input-box {
  height: 45px;
  border-radius: 10px;
  border: 1px solid #b9b9b9;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

::placeholder {
  font-size: 13px;
  font-weight: 700;
}

.otp-confirm-button {
  height: 45px;
  border-radius: 10px;
  border: 0;
  font-size: 13px;
  font-weight: 600;
}

.resend-otp-text {
  font-weight: 500;
  font-size: 14px;
  text-decoration: underline;
}
</style>
