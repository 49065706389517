<template>
  <footer class="d-flex flex-column justify-content-center p-3 navbar-bg-colour footer-container">
    <div class="d-flex justify-content-evenly mt-4">
      <div class="navbar-bg-colour half-circle"></div>
      <div @click="routeToProfilePage" class="d-flex flex-column text-center footer-navbar-img col-2">
        <div v-if="!isIconActive(this.$route, '/profile')"><img src="../../assets/profile-nav-icon.png"></div>
        <div v-if="isIconActive(this.$route, '/profile')"><img
            :src="require(`../../brand/${this.brandConfig.brand}/assets/profile-nav-icon-active.png`)"></div>
        <div class="footer-text">{{ translationsFormatter('footer_profile_icon') }}</div>
      </div>
      <div @click="routeToTopPicksPage" class="d-flex flex-column text-center footer-navbar-img col-2">
        <div v-if="!isIconActive(this.$route, '/top-picks')"><img src="../../assets/top-picks-nav-icon.png"></div>
        <div v-if="isIconActive(this.$route, '/top-picks')"><img
            :src="require(`../../brand/${this.brandConfig.brand}/assets/top-picks-nav-icon-active.png`)"></div>
        <div class="footer-text">{{ translationsFormatter('footer_top_picks_icon') }}</div>
      </div>
      <div @click="routeToUpcomingRoundsPage" class="d-flex flex-column text-center play-icon-container col-3">
        <div v-if="!isPlayIconActive(this.$route)"><img class="play-icon" src="../../assets/play-nav-icon.png"></div>
        <div v-if="isPlayIconActive(this.$route)"><img class="play-icon"
                                                       :src="require(`../../brand/${this.brandConfig.brand}/assets/play-nav-icon-active.png`)">
        </div>
        <div class="pt-2 footer-text">{{ translationsFormatter('footer_play_icon') }}</div>
      </div>
      <div @click="routeToMyRoundsPage" class="d-flex flex-column text-center footer-navbar-img col-2">
        <div v-if="!isIconActive(this.$route, '/my-rounds')"><img src="../../assets/my-rounds-nav-icon.png"></div>
        <div v-if="isIconActive(this.$route, '/my-rounds')"><img
            :src="require(`../../brand/${this.brandConfig.brand}/assets/my-rounds-nav-icon-active.png`)"></div>
        <div class="footer-text">{{ translationsFormatter('footer_my_rounds_icon') }}</div>
        <div v-if="this.roundsEntered.length > 0" class="my-rounds-counter text-center">{{ this.roundsEntered.length }}</div>
      </div>
      <div @click="routeToResultsPage" class="d-flex flex-column text-center footer-navbar-img col-2">
        <div v-if="!isResultsIconActive(this.$route)"><img src="../../assets/results-nav-icon.png"></div>
        <div v-if="isResultsIconActive(this.$route)"><img
            :src="require(`../../brand/${this.brandConfig.brand}/assets/results-nav-icon-active.png`)"></div>
        <div class="footer-text">{{ translationsFormatter('footer_trophy_icon') }}</div>
      </div>
    </div>
  </footer>
</template>

<script>
import {mapState} from "vuex";
import GameDataService from "@/services/gameDataService";

export default {
  name: "Footer",
  computed: {
    ...mapState(['brandConfig', 'userLandingStats', 'user']),
  },
  data() {
    return {
      roundsEntered: [],
    }
  },
  methods: {
    async getRoundsEntered() {
      const activeRoundsResponse = await GameDataService.activeRounds(`${this.user.msisdn}`)
      this.roundsEntered = activeRoundsResponse.data.filter(function (round) {
        return round.entered;
      })
    },
    isIconActive(route, iconName) {
      if (route.path === iconName) return true
    },
    routeToProfilePage() {
      this.$router.push('/profile');
    },
    routeToTopPicksPage() {
      this.$router.push('/top-picks');
    },
    routeToMyRoundsPage() {
      this.$router.push('/my-rounds');
    },
    routeToUpcomingRoundsPage() {
      this.$router.push('/upcoming-rounds');
    },
    routeToResultsPage() {
      this.$router.push('/my-results');
    },
    isPlayIconActive(route) {
      if (route.path === '/upcoming-rounds') return true
      if (route.path === '/team-builder') return true
      if (route.path === '/team-selector') return true

    },
    isResultsIconActive(route) {
      if (route.path === '/my-results') return true
      if (route.name === 'RoundLeaderboard') return true
    }
  },
  async beforeMount() {
    await this.getRoundsEntered()
  },
  watch: {
    async $route(to, from) {
      await this.getRoundsEntered();
    }
  }
}
</script>

<style scoped>

.play-icon {
  width: 65px;
  height: 65px;
}

.play-icon-container {
  z-index: 1;
  position: relative;
  bottom: 20px;
}

.half-circle {
  z-index: -1;
  width: 100px;
  height: 100px;
  position: absolute;
  border-radius: 55px 55px 0 0;
  top: -48%;
}

.my-rounds-nav-icon-img-container img {
  width: 30px;
  height: 30px;
}

.my-rounds-counter {
  position: absolute;
  z-index: 1;
  right: 0%;
  top: -6%;
  background-color: #01a601;
  font-size: 10px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.footer-navbar-img {
  position: relative;
  margin-top: 3%;
  margin-bottom: 7%;
}

.footer-navbar-img img, .footer-navbar-rounds-img img {
  width: 30px;
  height: 30px;
}


@media screen and (max-width: 388px) {
  .footer-text {
    font-size: 11px;
  }
}

</style>
