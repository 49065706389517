export const brandConfigObjectMap = new Map();

brandConfigObjectMap.set('vc-za-en', {
    revenuePartnerId: 1,
    isRandomDrawAwarded: false,
    tabTitle: 'Fantasy Football',
    userAccessId: 1,
    googleTag: 'G-JGRR0HQX82'
});
brandConfigObjectMap.set('mtn-cg-fr', {
    revenuePartnerId: 15,
    isRandomDrawAwarded: false,
    tabTitle: 'Football Manager',
    userAccessId: 2
});
brandConfigObjectMap.set('mtn-ci-fr', {
    revenuePartnerId: 29,
    isRandomDrawAwarded: true,
    tabTitle: 'Football Manager',
    userAccessId: 3,
    googleTag: 'G-BJCTGMN15R'
});
brandConfigObjectMap.set('mtn-cmr-fr', {
    revenuePartnerId: 31,
    isRandomDrawAwarded: true,
    tabTitle: 'Football Manager',
    userAccessId: 4,
    googleTag: 'G-9VN79PGBLT'
});
brandConfigObjectMap.set('mtn-nga-en', {
    revenuePartnerId: 35,
    isRandomDrawAwarded: true,
    tabTitle: 'Fantasy Games',
    userAccessId: 5,
    googleTag: 'G-NVNS46BPT8'
});
brandConfigObjectMap.set('ethiotel-et-en', {
    revenuePartnerId: 37,
    isRandomDrawAwarded: true,
    tabTitle: 'Fantasy Sports',
    userAccessId: 6,
    googleTag: 'G-5FDQ5GXM95'
});
brandConfigObjectMap.set('ethiotel-et-amh', {
    revenuePartnerId: 37,
    isRandomDrawAwarded: true,
    tabTitle: 'Fantasy Sports',
    userAccessId: 6,
    googleTag: 'G-5FDQ5GXM95'
});
brandConfigObjectMap.set('demo-et-en', {
    revenuePartnerId: 1,
    isRandomDrawAwarded: false,
    tabTitle: 'Fantasy Football',
    userAccessId: 1
});
brandConfigObjectMap.set('demo-um-en', {
    revenuePartnerId: 1,
    isRandomDrawAwarded: false,
    tabTitle: 'Fantasy Football',
    userAccessId: 1
});
brandConfigObjectMap.set('ais-tai-en', {
    revenuePartnerId: 1,
    isRandomDrawAwarded: false,
    tabTitle: 'Championship Manager',
    userAccessId: 1
});
brandConfigObjectMap.set('ais-tai-tha', {
    revenuePartnerId: 1,
    isRandomDrawAwarded: false,
    tabTitle: 'Championship Manager',
    userAccessId: 1,
    productId: 34
});
brandConfigObjectMap.set('dtac-tai-tha', {
    revenuePartnerId: 1,
    isRandomDrawAwarded: false,
    tabTitle: 'Fantasy Football',
    userAccessId: 1
});
brandConfigObjectMap.set('dtac-tai-en', {
    revenuePartnerId: 1,
    isRandomDrawAwarded: false,
    tabTitle: 'Fantasy Football',
    userAccessId: 1
});
brandConfigObjectMap.set('mtn-zam-en', {
    revenuePartnerId: 38,
    isRandomDrawAwarded: true,
    tabTitle: 'Fantasy Football',
    userAccessId: 7,
    googleTag: 'G-9TZ1RTRHTY'
});
brandConfigObjectMap.set('saudi-uae-en', {
    revenuePartnerId: 1,
    isRandomDrawAwarded: false,
    tabTitle: 'Fantasy Football',
    userAccessId: 1
});
brandConfigObjectMap.set('air-nga-en', {
    revenuePartnerId: 45,
    isRandomDrawAwarded: true,
    tabTitle: 'Fantasy Games',
    userAccessId: 8,
    googleTag: 'G-NVNS46BPT8'
});
