import store from "@/store";
import CryptoJS from 'crypto-js'


export default function (uuid) {
    const keyConfig = store.state.brandConfig.encryptionKey;

    return decrypt(uuid, keyConfig.encryptionKey);
}

function decrypt(uuid, key) {
    const secretKey = CryptoJS.enc.Utf8.parse(key)
    let decrypted = CryptoJS.RC4.decrypt(uuid, secretKey)
    return CryptoJS.enc.Utf8.stringify(decrypted);
}


