<template>
  <div class="fixed-position">
    <div class="d-flex flex-column body-container" v-if="!isLoading && this.roundSelected">
      <div
          class="d-flex flex-row justify-content-center align-items-center text-center team-builder-header primary-bg-colour primary-text">
        <img class="back-button" src="../../assets/left-keyboard-arrow.png" @click="backButtonClicked">
        {{ translationsFormatter('team_builder_header') }}
      </div>
      <LeagueHeader :roundSelected="roundSelected"/>
      <MatchesDropdown :roundSelected="roundSelected"/>

      <div class="pt-4 players-positions-main-container">
        <PlayerFieldPosition :roundSelected="roundSelected" position="forward"/>
        <div class="d-flex flex-row justify-content-around team-midfield-container">
          <PlayerFieldPosition :roundSelected="roundSelected" position="midfielder1"/>
          <PlayerFieldPosition :roundSelected="roundSelected" position="midfielder2"/>
        </div>
        <PlayerFieldPosition :roundSelected="roundSelected" position="defence"/>
        <PlayerFieldPosition :roundSelected="roundSelected" position="goalkeeper"/>
        <div v-if="!hasFullTeamBeenSelected()" class="start-button col-11 primary-bg-colour primary-text"
             @click="playerPlusIconClicked('Forward')">
          {{ translationsFormatter('team_builder_start_button') }}
        </div>
        <div v-if="hasFullTeamBeenSelected() && !propTeamCaptainSelectPopUp"
             class="mt-1 start-button col-11 primary-bg-colour primary-text"
             @click="submitButtonClicked">
          {{ translationsFormatter('team_builder_submit_button') }}
        </div>
      </div>
      <div class="d-flex flex-column text-center align-items-center col-11 team-submitted-popup"
           v-if="confirmedTeamSubmissionPopUp">
        <div class="col-11 mt-3 p-1 thank-you-submission-container">
          {{ translationsFormatter('team_builder_successfully_submitted_one') }}
          {{ translationsFormatter('network_title') }}.
        </div>
        <button @click="routeToMyRoundsPage" class="col-11 mt-2 primary-bg-colour primary-text route-my-rounds-button">
          {{ translationsFormatter('team_builder_successfully_submitted_my_rounds_button') }}
        </button>
      </div>

      <CaptainSelectBox :roundSelected="roundSelected"/>

      <div class="auto-team-change-captain-container">
        <div class="auto-team-builder-button secondary-bg-colour primary-text"
             @click="() => {this.autoTeamBuilderClicked()}">
          {{ translationsFormatter('team_builder_auto_team_builder_button') }}
        </div>
        <div v-if="hasCaptainBeenSelected()" class="auto-team-builder-button secondary-bg-colour primary-text"
             @click="() => changeCaptainButtonClicked()">
          {{ translationsFormatter('change_captain_team_builder_button') }}
        </div>
      </div>

      <div @click="autoTeamBuilderCancelButton" class="popup-cross-container" v-if="autoTeamBuilderPopUp">
        <img src="../../assets/pop-up-cross.png" class="player-plus-icon">
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import UserDataService from "@/services/userDataService";
import GameDataService from "@/services/gameDataService";
import {getSelectedRound} from "@/functions/roundSelection";
import {setSelectedPosition} from "@/functions/roundSelection";
import LeagueHeader from "@/views/components/LeagueHeader";
import MatchesDropdown from "@/views/components/MatchesDropdown";
import PlayerFieldPosition from "@/views/components/PlayerFieldPosition";
import CaptainSelectBox from "@/views/components/CaptainSelectBox";

export default {
  name: "TeamBuilder",
  components: {
    LeagueHeader,
    MatchesDropdown,
    PlayerFieldPosition,
    CaptainSelectBox
  },
  data() {
    return {
      changeCaptainButtonVisible: false,
      confirmedTeamSubmissionPopUp: false,
      autoTeamBuilderPopUp: true,
      leagueNameFontSize: '15px',
      activeRoundImgLeftMargin: undefined,
      activeRoundImgTopMargin: undefined,
    }
  },
  beforeMount() {
    this.setIsLoading(true);
    this.selectedRound = JSON.parse(getSelectedRound())
    this.setSelectedRound(this.selectedRound)
    this.checkLeagueNameLength();
    this.setIsLoading(false);
  },
  computed: {
    ...mapState(['isLoading', 'roundSelected', 'user', 'userLandingStats', 'brandConfig', 'propTeamCaptainSelectPopUp'])
  },
  methods: {
    ...mapMutations(['setActiveRoundGoalkeeperPick', 'setActiveRoundForwardPick', 'updateUserLandingStatsRoundsEntered', 'setPropTeamCaptainSelectPopUp', 'setSelectedRound', 'updateActiveRound', 'clearMidfielders', 'setRoundsEntered', 'setIsLoading', 'setSelectedPositionInRound', 'setActiveRoundDefencePick', 'setActiveRoundMidfielderOnePick', 'setActiveRoundMidfielderTwoPick', 'setRoundHasFullTeam']),
    routeToMyRoundsPage() {
      this.$router.push('/my-rounds');
    },
    checkLeagueNameLength() {
      let leagueNameLength = this.roundSelected.leagueName.toString().length;
      if (leagueNameLength > 20) {
        this.leagueNameFontSize = '13px'
      }
    },
    playerPlusIconClicked(position) {
      const positionLowerCase = position.toLowerCase();
      this.roundSelected.selections[positionLowerCase];
      this.setSelectedPositionInRound(position);
      setSelectedPosition(position);
      this.$router.push('/team-selector');
    },
    hasFullTeamBeenSelected() {
      if (!this.roundSelected.selections.forward) return false;
      if (!this.roundSelected.selections.midfielder1) return false;
      if (!this.roundSelected.selections.midfielder2) return false;
      if (!this.roundSelected.selections.defence) return false;
      if (!this.roundSelected.selections.goalkeeper) return false;
      else return true;
    },

    noTeamCaptain() {
      return !(this.roundSelected.selections.forward.captain === true || this.roundSelected.selections.midfielder1.captain === true || this.roundSelected.selections.midfielder2.captain === true || this.roundSelected.selections.goalkeeper.captain === true);
    },
    async submitButtonClicked() {
      this.changeCaptainButtonVisible = false;
      this.setPropTeamCaptainSelectPopUp(this.noTeamCaptain());
      if (!this.noTeamCaptain()) {
        const selections = [];
        this.roundSelected.selections.defence.position = "Defence";
        selections.push(this.roundSelected.selections.forward);
        selections.push(this.roundSelected.selections.midfielder1);
        selections.push(this.roundSelected.selections.midfielder2);
        selections.push(this.roundSelected.selections.defence);
        selections.push(this.roundSelected.selections.goalkeeper);
        this.updateUserLandingStatsRoundsEntered();
        await UserDataService.postUserEntries(selections, this.brandConfig.userAccessId, this.user.msisdn);

        this.confirmedTeamSubmissionPopUp = true;
      }
    },
    backButtonClicked() {
      this.$router.push(`/upcoming-rounds`);
    },
    async autoTeamBuilderClicked() {
      const autoSelectedTeam = await GameDataService.getAutoSelectedTeam(this.roundSelected.roundId);
      this.setAutoSelectedTeam(autoSelectedTeam.data);
    },
    isFullTeamSelected() {
      const selections = Object.values(this.roundSelected.selections);
      this.numberOfSelections = selections.length;
      if (this.numberOfSelections === 5) this.setRoundHasFullTeam(true);
      else this.setRoundHasFullTeam(false);
    },
    setAutoSelectedTeam(team) {
      const midfielders = [];
      team.forEach((player) => {
        this.setPlayersData(player, player.position);
        if (!player.position) {
          player.teamNames = [player.name];
          this.setActiveRoundDefencePick(player);
        }
        if (player.position) player.position = player.position.toLowerCase();
        if (player.position === 'midfielder') midfielders.push(player);
        if (player.position === 'forward') this.setActiveRoundForwardPick(player);
        if (player.position === 'goalkeeper') this.setActiveRoundGoalkeeperPick(player);
      })
      this.clearMidfielders();
      midfielders[0].position = 'midfielder1';
      midfielders[1].position = 'midfielder2';
      this.setActiveRoundMidfielderOnePick(midfielders[0]);
      this.setActiveRoundMidfielderTwoPick(midfielders[1]);

      this.updateActiveRound(this.roundSelected)
      this.isFullTeamSelected();
    },
    setPlayersData(player, position) {
      player.playerRef = player.ref;
      player.position = position;
      player.roundId = this.roundSelected.roundId;
      player.teamNames = position === "defence" ? [player.name] : player.teamNames;
      player.selectedPosition = position;
    },
    autoTeamBuilderCancelButton() {
      this.autoTeamBuilderPopUp = !this.autoTeamBuilderPopUp;
    },
    initialSurnameFormat(fullName) {
      const noArrayName = Array.isArray(fullName) ? fullName[0] : fullName;
      const hasSpaces = noArrayName.includes(" ");
      if (!hasSpaces) return noArrayName;
      const firstNameLetter = noArrayName[0];
      const indexOfSpace = noArrayName.indexOf(' ');
      const surname = noArrayName.slice(indexOfSpace);
      return `${firstNameLetter}. ${surname}`;
    },
    changeCaptainButtonClicked() {
      this.changeCaptainButtonVisible = false;
      this.setPropTeamCaptainSelectPopUp(true);
    },
    hasCaptainBeenSelected() {
      if (this.roundSelected.hasFullTeam) {
        if (this.roundSelected.selections.forward.captain) return true;
        if (this.roundSelected.selections.midfielder1.captain) return true;
        if (this.roundSelected.selections.midfielder2.captain) return true;
        if (this.roundSelected.selections.goalkeeper.captain) return true;
      } else return false;
    }
  },
}
</script>

<style scoped>
.players-positions-main-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 70vh;
}

.fixed-position {
  top: 0;
  bottom: 0;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
}

.fixed-position::-webkit-scrollbar {
  display: none;
}

.thank-you-submission-container {
  background-color: #FFFFFF;
  font-weight: 700;
  border-radius: 10px;
}

.auto-team-change-captain-container {
  display: flex;
  justify-content: center;
  padding-bottom: 25px;
}

.route-my-rounds-button {
  border-radius: 10px;
  border: 0;
  font-weight: 600;
  height: 35px;
}

.team-submitted-popup {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 140px;
  background-color: #f5f2f2;
  border-radius: 10px;
  opacity: 0.95;
}


.body-container {
  position: relative;
  z-index: -2;
  background-image: url("../../assets/field-large.png");
  background-size: cover;
  background-position: center;
  padding-bottom: 75px;
}

.team-builder-header {
  position: relative;
  font-weight: bold;
  height: 40px;
}

.back-button {
  position: absolute;
  height: 20px;
  left: 20px;
}

.matches-dropdown-header-text img {
  position: absolute;
  right: 5%;
  height: 15px;
}

.player-plus-icon {
  height: 60px;
  width: 60px;
}

.auto-team-builder-button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  font-size: 12px;
  width: 150px;
  height: 25px;
  border-radius: 10px;
  border: 0px solid #3d3d3d;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 20px;
  margin-right: 2px;
  margin-left: 2px;
}

.popup-cross-container {
  z-index: 1;
  position: absolute;
  right: -136%;
  top: -10%;
}

.popup-cross-container img {
  height: 18px;
  width: 18px;
}

.team-midfield-container {
  width: 100%;
}

.start-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  height: 35px;
  border-radius: 10px;
}
</style>
