<template>
  <div v-if="propTeamCaptainSelectPopUp" class="d-flex flex-column text-center col-11 captain-select-popup">
    <div class="d-flex flex-column align-items-center">
      <div class="d-flex flex-column p-2 mt-2 col-11 popup-header-text">
        {{ translationsFormatter('team_builder_captain_popup_header') }}
      </div>
    </div>
    <div class="d-flex flex-column align-items-center">
      <div @click="captainSelect(roundSelected.selections.forward)"
           class="d-flex flex-row player-popup-row align-content-center pt-1 mt-1 col-11">
        <div class="col-2">
          <img :src="Helpers.findJerseyImageModule(roundSelected.selections.forward.teamNames[0])"
               class="jersey-icon-popup">
        </div>
        <div class="d-flex flex-row justify-content-start align-items-center col-5 player-name-popup">
          {{ this.initialSurnameFormat(roundSelected.selections.forward.name) }}
        </div>
        <div class="d-flex flex-row justify-content-end align-items-center col-5 player-club-popup">
          ({{ this.initialSurnameFormat(roundSelected.selections.forward.teamNames[0]) }})
        </div>
      </div>
      <div @click="captainSelect(roundSelected.selections.midfielder1)"
           class="d-flex flex-row player-popup-row align-content-center pt-1 mt-1 col-11">
        <div class="col-2">
          <img :src="Helpers.findJerseyImageModule(roundSelected.selections.midfielder1.teamNames[0])"
               class="jersey-icon-popup">
        </div>
        <div class="d-flex flex-row justify-content-start align-items-center col-5 player-name-popup">
          {{ this.initialSurnameFormat(roundSelected.selections.midfielder1.name) }}
        </div>
        <div class="d-flex flex-row justify-content-end align-items-center col-5 player-club-popup">
          ({{ this.initialSurnameFormat(roundSelected.selections.midfielder1.teamNames[0]) }})
        </div>
      </div>
      <div @click="captainSelect(roundSelected.selections.midfielder2)"
           class="d-flex flex-row player-popup-row align-content-center pt-1 mt-1 col-11">
        <div class="col-2">
          <img :src="Helpers.findJerseyImageModule(roundSelected.selections.midfielder2.teamNames[0])"
               class="jersey-icon-popup">
        </div>
        <div class="d-flex flex-row justify-content-start align-items-center col-5 player-name-popup">
          {{ this.initialSurnameFormat(roundSelected.selections.midfielder2.name) }}
        </div>
        <div class="d-flex flex-row justify-content-end align-items-center col-5 player-club-popup">
          ({{ this.initialSurnameFormat(roundSelected.selections.midfielder2.teamNames[0]) }})
        </div>
      </div>
      <div @click="captainSelect(roundSelected.selections.goalkeeper)"
           class="d-flex flex-row player-popup-row align-content-center pt-1 mt-1 col-11">
        <div class="col-2">
          <img :src="Helpers.findJerseyImageModule(roundSelected.selections.goalkeeper.teamNames[0])"
               class="jersey-icon-popup">
        </div>
        <div class="d-flex flex-row justify-content-start align-items-center col-5 player-name-popup">
          {{ this.initialSurnameFormat(roundSelected.selections.goalkeeper.name) }}
        </div>
        <div class="d-flex flex-row justify-content-end align-items-center col-5 player-club-popup">
          ({{ this.initialSurnameFormat(roundSelected.selections.goalkeeper.teamNames[0]) }})
        </div>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center text-center">
      <div @click="cancelButtonClicked" class="mt-2 popup-cancel-div">
        {{ translationsFormatter('team_builder_popup_cancel_button') }}
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";

export default {
  name: "CaptainSelectBox",
  computed: {...mapState(['propTeamCaptainSelectPopUp'])},
  props: {
    roundSelected: Object
  },
  methods: {
    ...mapMutations(['setPropTeamCaptainSelectPopUp']),

    captainSelect(player) {
      this.roundSelected.selections.forward.captain = false;
      this.roundSelected.selections.midfielder1.captain = false;
      this.roundSelected.selections.midfielder2.captain = false;
      this.roundSelected.selections.defence.captain = false;
      this.roundSelected.selections.goalkeeper.captain = false;
      player.captain = true;
      this.setPropTeamCaptainSelectPopUp(false)
      this.changeCaptainButtonVisible = true;
    },
    initialSurnameFormat(fullName) {
      const noArrayName = Array.isArray(fullName) ? fullName[0] : fullName
      const hasSpaces = noArrayName.includes(" ");
      if (!hasSpaces) return noArrayName;
      const firstNameLetter = noArrayName[0];
      const indexOfSpace = noArrayName.indexOf(' ');
      const surname = noArrayName.slice(indexOfSpace);
      return `${firstNameLetter}. ${surname}`;
    },
    cancelButtonClicked() {
      this.setPropTeamCaptainSelectPopUp(false);
      this.changeCaptainButtonVisible = true;
    },
  },
}
</script>

<style scoped>
.captain-select-popup {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 345px;
  background-color: #f5f2f2;
  border-radius: 26px;
}

.popup-header-text {
  font-size: 15px;
  font-weight: 700;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.player-popup-row {
  height: 50px;
  background-color: #e5e5e5;
  border-radius: 10px;
}


.player-name-popup {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
}

.player-club-popup {
  padding-right: 15px;
  color: #939393;
  font-size: 16px;
  font-weight: 600;
}

.jersey-icon-popup {
  height: 40px;
}

.popup-cancel-div {
  height: 38px;
  font-size: 18px;
  font-weight: 600;
}
</style>
