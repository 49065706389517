<template>
  <div class="d-flex flex-column body-container" v-if="!this.isLoading">
    <div class="d-flex flex-row p-2 my-results-header">
      {{ translationsFormatter('round_leaderboard_header_title') }}
    </div>
    <div class="d-flex align-items-center justify-content-center pb-2 winnings-container">
      <img :src="require(`../../brand/${brandConfig.brand}/assets/results-main-banner.png`)" class="main-banner">
      <div class="my-position-circle primary-border-colour secondary-text">
        <img class="my-position-award-icon" :src="require(`../../brand/${brandConfig.brand}/assets/badge-icon.png`)" alt="">
        <div class="my-position-text secondary-text">
          {{ translationsFormatter('round_leaderboard_my_position_circle') }}
        </div>
        <div class="d-flex">
          <div class="number-position secondary-text">{{ this.currentUser.position }}</div>
          <div class="number-position-end  secondary-text">
            {{ this.getCorrectNumberEnding(`${this.currentUser.position}`) }}
          </div>
        </div>
      </div>
    </div>
    <div class="table-headers col-12">
      <div class="header-text-first w-10">{{ translationsFormatter('round_leaderboard_table_position_column_header') }}</div>
      <div class="header-text w-50 p-l-1">{{
          translationsFormatter('round_leaderboard_table_user_name_column_header')
        }}
      </div>
      <div class="header-text w-10 p-l-1">{{
          translationsFormatter('round_leaderboard_table_highest_rank_column_header')
        }}
      </div>
      <div class="header-text w-10 p-l-1">{{
          translationsFormatter('round_leaderboard_table_total_points_column_header')
        }}
      </div>
    </div>
    <div class="user-field-container" v-for="(user, index) in this.cleanedLeaderboard" :key="user.position"
         @click="this.onUserClicked(index)">
      <div :class="`${user.isSelected ? 'table-row-selected' : 'table-row'}`">
        <div class="position-text">{{ user.position }}</div>
        <div class="d-flex flex-row align-items-center username-text">
          <img :src="require(`../../brand/${this.brandConfig.brand}/assets/user-icon.png`)" class="user-icon">
          <div v-if="user.username">{{ user.username }}</div>
          <div v-if="user.username === null">{{
              translationsFormatter('round_leaderboard_table_player_username_default')
            }}
            {{ user.msisdn.slice(-3) }}
          </div>
        </div>
        <div class="highest-rank-text"> {{ user.numberOfTopTens }}</div>
        <div class="points-text">{{ user.points }}</div>
      </div>
      <transition name="slide">
        <div class="field-container" v-if="user.isSelected">
          <div class="d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column w-50 player-container">
              <img class="captain-logo" v-if="getSelectedCaptain(user,'Forward')"
                   :src="require(`../../brand/${brandConfig.brand}/assets/captain-icon.png`)">
              <div class="d-flex justify-content-center align-items-center">
                <img :src="this.findJerseyImageModule(user, 'Forward')" class="user-icon-img">
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <div class="player-points-circle secondary-text">{{ getNonMidfielderPlayerPoints("Forward") }}
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <div class="player-name primary-text">{{ getNonMidfielderPlayerName("Forward") }}</div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-evenly -items-center">
            <div class="d-flex flex-column w-50 player-container">
              <img class="captain-logo" v-if="getSelectedCaptainMidfield(user,'Midfielder', 0)"
                   :src="require(`../../brand/${brandConfig.brand}/assets/captain-icon.png`)">
              <div class="d-flex justify-content-center align-items-center">
                <img :src="this.findMidfielderJerseyImageModule(user, 'Midfielder', 0)" class="user-icon-img">
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <div class="player-points-circle secondary-text">{{ getMidfielderPoints(true) }}</div>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <div class="player-name primary-text">{{ getMidfielderName(true) }}</div>
              </div>
            </div>
            <div class="d-flex flex-column w-50 player-container">
              <img class="captain-logo" v-if="getSelectedCaptainMidfield(user,'Midfielder', 1)"
                   :src="require(`../../brand/${brandConfig.brand}/assets/captain-icon.png`)">
              <div class="d-flex justify-content-center align-items-center">
                <img :src="this.findMidfielderJerseyImageModule(user, 'Midfielder', 1)" class="user-icon-img">
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <div class="player-points-circle secondary-text">{{ getMidfielderPoints(false) }}</div>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <div class="player-name primary-text">{{ getMidfielderName(false) }}</div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column w-50 player-container">
              <div class="d-flex justify-content-center align-items-center">
                <img :src="this.findDefenceJerseyImageModule(user, 'Defence')" class="user-icon-img">
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <div class="player-points-circle secondary-text">{{ getNonMidfielderPlayerPoints("Defence") }}</div>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <div class="player-name primary-text">{{ getNonMidfielderPlayerName("Defence") }}</div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <div class="d-flex flex-column w-50 player-container pt-3">
              <img class="captain-logo-keeper" v-if="getSelectedCaptain(user,'Goalkeeper')"
                   :src="require(`../../brand/${brandConfig.brand}/assets/captain-icon.png`)">
              <div class="d-flex justify-content-center align-items-center">
                <img :src="this.findJerseyImageModule(user, 'Goalkeeper')" class="user-icon-img">
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <div class="player-points-circle secondary-text">{{ getNonMidfielderPlayerPoints("Goalkeeper") }}</div>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <div class="player-name primary-text">{{ getNonMidfielderPlayerName("Goalkeeper") }}</div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import GameDataService from "@/services/gameDataService";
import UserDataService from "@/services/userDataService";
import Helpers from "@/helpers/helpers";

export default {
  name: "RoundLeaderboard",
  data() {
    return {
      leaderBoard: undefined,
      cleanedLeaderboard: undefined,
      currentUser: undefined,
      userTeam: [],
    }
  },
  async beforeMount() {
    this.setIsLoading(true);
    this.leaderBoard = await GameDataService.getRoundLeaderboard(this.$route.params.roundId, this.user.msisdn, this.brandConfig.userAccessId);
    this.currentUser = this.leaderBoard.find(element => element.msisdn === this.user.msisdn);
    this.cleanLeaderboard();
    this.setIsLoading(false);
  },
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'roundSelected', 'user'])
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    cleanLeaderboard() {
      this.cleanedLeaderboard = this.leaderBoard.reduce((unique, o) => {
        if (!unique.some(obj => obj.entryId === o.entryId)) {
          unique.push(o);
        }
        return unique
      }, [])
    },
    initialSurnameFormat(fullName) {
      const hasSpaces = fullName.includes(" ");
      if (!hasSpaces) return fullName;
      const firstNameLetter = fullName[0];
      const indexOfSpace = fullName.indexOf(' ');
      const surname = fullName.slice(indexOfSpace);
      return `${firstNameLetter}. ${surname}`;
    },
    getCorrectNumberEnding(number) {
      if (number[number.length - 1] === "1") return "st"
      if (number[number.length - 1] === "2") return "nd"
      if (number[number.length - 1] === "3") {
        return "rd"
      } else {
        return "th"
      }
    },
    async onUserClicked(index) {
      if (!this.leaderBoard[index].isSelected) {
        this.leaderBoard.forEach((user) => {
          user.isSelected = false;
        })
        this.leaderBoard[index].isSelected = true;
        await this.setUserTeamData(index)
      } else {
        this.leaderBoard.forEach((user) => {
          user.isSelected = false;
        })
      }
    },
    async setUserTeamData(index) {
      this.userTeam = [];
      const user = this.leaderBoard[index]
      const userRoundSelections = await UserDataService.getUserRoundEntries(user.roundId, user.entryId, user.token);
      await userRoundSelections.data.forEach((selection) => {
        this.userTeam.push(selection);
      })
    },

    findJerseyImageModule(team, position) {
      let playerSelected
      if (this.userTeam.length > 0) {
        playerSelected = this.userTeam.filter(obj => {
          return obj.position === position
        })
        const teamName = Helpers.formatTextForAssetNames(playerSelected[0].teamNames[0])
        return Helpers.findJerseyImageModule(teamName)
      }
    },
    getSelectedCaptain(team, position) {
      let playerSelected
      if (this.userTeam.length > 0) {
        playerSelected = this.userTeam.filter(obj => {
          return obj.position === position
        })
        return playerSelected[0].captain === true
      }
    },
    getSelectedCaptainMidfield(team, position, index) {
      let playerSelected
      if (this.userTeam.length > 0) {
        playerSelected = this.userTeam.filter(obj => {
          return obj.position === position
        })
        return playerSelected[index].captain === true
      }
    },
    findMidfielderJerseyImageModule(team, position, index) {
      let playerSelected
      if (this.userTeam.length > 0) {
        playerSelected = this.userTeam.filter(obj => {
          return obj.position === position
        })
        const teamName = Helpers.formatTextForAssetNames(playerSelected[index].teamNames[0])
        return Helpers.findJerseyImageModule(teamName)
      }
    },
    findDefenceJerseyImageModule(team, position) {
      let playerSelected
      if (this.userTeam.length > 0) {
        playerSelected = this.userTeam.filter(obj => {
          return obj.position === position
        })
        const teamName = Helpers.formatTextForAssetNames(playerSelected[0].name)
        return Helpers.findJerseyImageModule(teamName)
      }
    },
    getNonMidfielderPlayerPoints(position) {
      let playerPoints;
      if (this.userTeam.length > 0) {
        this.userTeam.forEach((player) => {
          if (player.position === position) playerPoints = player.points
        })
        if (playerPoints === null) {
          playerPoints = 0
        }
        return playerPoints
      }
    },
    getNonMidfielderPlayerName(position) {
      let playerName;
      if (this.userTeam.length > 0) {
        this.userTeam.forEach((player) => {
          if (player.position === position) playerName = player.name
        })
        if (position !== "Defence") return this.initialSurnameFormat(playerName)
        else return playerName;
      }
    },
    getMidfielderPoints(isMidfielderOne) {
      const midfielders = [];
      if (this.userTeam.length > 0) {
        this.userTeam.forEach((player) => {
          if (player.position === "Midfielder") midfielders.push(player);
        })
        if (isMidfielderOne) {
          if (midfielders[0].points === null) {
            return midfielders[0].points = 0
          }
          return midfielders[0].points;
        } else if (midfielders[1].points === null) {
          return midfielders[1].points = 0
        }
        return midfielders[1].points;
      }
    },
    getMidfielderName(isMidfielderOne) {
      const midfielders = [];
      if (this.userTeam.length > 0) {
        this.userTeam.forEach((player) => {
          if (player.position === "Midfielder") midfielders.push(player);
        })
        if (isMidfielderOne) return this.initialSurnameFormat(midfielders[0].name);
        else return this.initialSurnameFormat(midfielders[1].name);
      }
    },
  }
}
</script>
<style scoped>
.body-container {
  margin-bottom: 40px;
  background-size: 100%;
  z-index: -2;
  padding-bottom: 10px;
}

.captain-logo, .captain-logo-keeper {
  position: absolute;
  left: 52%;
  height: 20px;
  z-index: 1;
}

.captain-logo {
  top: 33%;
}

.captain-logo-keeper {
  top: 61%;
}

.player-points-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  z-index: 2;
  margin-top: 15px;
  background-color: white;
  font-weight: 800;
}

.player-container {
  position: relative;
  height: 60px;
}

.player-name {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  height: 10px;
}


.user-icon-img {
  height: 45px;
  position: relative;
  top: 25px;
}

.selection-forward-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.selection-midfielder-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.selection-defence-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;

}

.field-container {
  background-image: url("../../assets/field-medium-icon.png");
  border-radius: 0px 0px 15px 15px;
  height: 300px;
  background-size: cover;
  margin-top: -10px;
  z-index: -1;
  background-position: center;
  list-style-type: none;
  transform-origin: top;
  transition: transform .6s ease-in-out;
  overflow: hidden;
}

.slide-enter-from, .slide-leave-to {
  transform: scaleY(0);
}

.number-position {
  font-size: 12px;
  font-weight: 900;
}

.number-position-end {
  font-size: 10px;
  font-weight: 900;
  padding-bottom: 4px;
}

.my-position-award-icon {
  height: 30px;
}

.points-text {
  font-weight: 900;
  width: 10%;
  text-align: center;
}

.highest-rank-text {
  font-weight: 700;
  width: 10%;
}

.position-text {
  width: 10%;
  font-weight: 700;
}

.table-headers {
  display: flex;
  justify-content: space-around;
}

.header-text {
  font-size: 8px;
  padding: 2px;
  margin-left: 4px;

}

.header-text-first {
  font-size: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.user-field-container {
  display: flex;
  flex-direction: column;
  width: auto;
}

.field-img {
  width: 100%;
}

.user-icon {
  height: 20px;
  margin-right: 5px;
}

.username-text {
  font-size: x-small !important;
  width: 50%;
}

.my-results-header {
  font-weight: bold;
}

.winnings-container {
  position: relative;
}

.main-banner {
  width: 100%;
}

.my-position-circle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: white;
  border: solid 4px;
}

.my-position-text {
  font-weight: 700;
  font-size: 12px;
}

table {
  border-collapse: separate;
  border-spacing: 0 3px;
}

thead {
  font-size: 10px;
  font-weight: lighter;
  color: #676060;
}

.table-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #e7e7e7;
  font-size: small;
  font-weight: 600;
  width: 100% !important;
  border-radius: 10px;
  margin-top: 5px;
  height: 40px;
}

.table-row-selected {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #afafaf;
  font-size: small;
  font-weight: 600;
  width: 100% !important;
  border-radius: 10px;
  margin-top: 5px;
  height: 40px;
}

td:first-child {
  border-right-style: solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.border-default {
  border: .5px solid #e7e7e7;
}


</style>
