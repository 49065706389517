<template>
  <div class="d-flex flex-column text-center col-11 matches-dropdown-container">
    <div @click="matchesDropDownClicked" class="d-flex flex-row justify-content-center pt-3 pb-1 matches-header">
      <strong class="px-1">{{ translationsFormatter('team_builder_matches_dropdown_header_matches') }}:</strong>
      <div class="light-round-text">{{ translationsFormatter('team_builder_matches_dropdown_header_round') }}
        {{ roundSelected.roundId }}
      </div>
      <img class="matches-dropdown-arrow" :src="require(`../../assets/${arrowType}-arrow.png`)">
    </div>
    <Transition name="slide">
      <div v-if="matchesDropDown" class="pt-1 pb-1 match-list-dropdown">
        <div class="match-row secondary-bg-colour" v-for="(match) in roundSelected.matches">
          <div class="team-name-text primary-text">
            <img class="team-logo-icon" :src="Helpers.findTeamImageModule(match.homeTeam.homeTeamName)"
                 alt="">{{
              match.homeTeam.homeTeamName.charAt(0).toUpperCase() + match.homeTeam.homeTeamName.slice(1)
            }}
          </div>
          <div class="vs-circle secondary-text">
            vs
          </div>
          <div class="team-name-text primary-text">
            <img class="team-logo-icon" :src="Helpers.findTeamImageModule(match.awayTeam.awayTeamName)"
                 alt="">{{
              match.awayTeam.awayTeamName.charAt(0).toUpperCase() + match.awayTeam.awayTeamName.slice(1)
            }}
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: "MatchesDropdown",
  data() {
    return {
      matchesDropDown: false,
      arrowType: 'down',
    }
  },
  props: {
    roundSelected: Object
  },
  methods: {
    matchesDropDownClicked() {
      this.switchArrowDirection();
      this.matchesDropDownShow();
    },
    matchesDropDownShow() {
      this.matchesDropDown = !this.matchesDropDown;
    },
    switchArrowDirection() {
      if (this.arrowType === 'down') {
        this.arrowType = 'up';
      } else this.arrowType = 'down';
    },
  }
}
</script>

<style scoped>
.matches-dropdown-container {
  position: absolute;
  width: 85%;
  top: 100px;
  z-index: 2;
  display: flex;
  align-self: center;
  max-height: fit-content;
  border-radius: 0 0 16px 16px;
  background-color: #F3F3F3;
}

.matches-header {
  font-size: 13px;
}

.light-round-text {
  color: #9d9d9d;
}

.matches-dropdown-arrow {
  position: absolute;
  left: 84%;
  height: 17px;
}

.match-list-dropdown {
  list-style-type: none;
  transform-origin: top;
  transition: transform .4s ease-in-out;
  overflow: hidden;
}

.match-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  border-radius: 25px 25px 25px 25px;
  height: 30px;
}

.team-name-text {
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0;
  font-size: xx-small;
  width: 33%;
}

.team-logo-icon {
  height: 15px;
  margin-right: 5px;
}

.vs-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: white;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 700;
}

.slide-enter-from {
  transform: scaleY(0);
}

.slide-leave-to {
  transform: scaleY(0);
}

.matches-dropdown-menu-container {
  position: fixed;
  bottom: 75px;
  background-color: #F3F3F3;
  border-radius: 10px 10px 0px 0px;
  width: 80%;
}

@media screen and (min-width: 450px) {
  .matches-dropdown-menu-container {
    width: 40%;
  }
}

.matches-dropdown-header-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  font-size: 10px;
  font-weight: bold;
  margin: 0;
}

</style>
