import IvoryCoastTerms from "@/views/config/termsAndConditions/IvoryCoastTerms";
import NigeriaTerms from "@/views/config/termsAndConditions/NigeriaTerms";
import EthiotelEnglishTerms from "@/views/config/termsAndConditions/EthiotelEnglishTerms";
import CameroonTerms from "@/views/config/termsAndConditions/CameroonTerms";
import DemoTerms from "@/views/config/termsAndConditions/DemoTerms";
import ZambiaTerms from "@/views/config/termsAndConditions/ZambiaTerms";
import AirtelNgTerms from "@/views/config/termsAndConditions/AirtelNgTerms";
import MtnCongoTerms from "@/views/config/termsAndConditions/MtnCongoTerms";

export const termsAndConditionsMap = new Map();

termsAndConditionsMap.set('mtn-ci-fr', IvoryCoastTerms);
termsAndConditionsMap.set('mtn-cg-fr', MtnCongoTerms);
termsAndConditionsMap.set('mtn-nga-en', NigeriaTerms);
termsAndConditionsMap.set('mtn-zam-en', ZambiaTerms);
termsAndConditionsMap.set('ethiotel-et-amh', EthiotelEnglishTerms);
termsAndConditionsMap.set('ethiotel-et-en', EthiotelEnglishTerms);
termsAndConditionsMap.set('mtn-cmr-fr', CameroonTerms);
termsAndConditionsMap.set('demo-um-en', DemoTerms);
termsAndConditionsMap.set('ais-tai-en', DemoTerms);
termsAndConditionsMap.set('dtac-tai-en', DemoTerms);
termsAndConditionsMap.set('air-nga-en', AirtelNgTerms)
termsAndConditionsMap.set('default', null);
